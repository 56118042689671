import axios from 'axios';
import configData from '../conf.json';

export async function getRssFeedNews(conference) {
    
    if(!conference) {
      return [];
    }
    var rssFeedUrl = configData.UMBRACO_SERVER_URL  + '/rssfeed/';
    // return axios.get(`https://localhost:44377/rssfeed/` + conference.replaceAll('/', '&'), {
    return axios.get(rssFeedUrl + conference.replaceAll('/', '&'), {
        headers: {
          accept: 'application/json'
        }
      })
        .then((response) => { 
            localStorage.setItem("activeConfNews", response.data);
        });

    
}
