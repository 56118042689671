import { getValueFromArrayElement } from '../dataManager/findAliasValue';
import { getConferenceValue, getImagePath } from '../dataManager/findAliasValue';
import HeaderNavComponent from './HeaderNavComponent';

var headerData = {
    title: '',
    year: '',
    subtitle: '',
    place: '',
    date: '',
    partner: '',
    image: ''
}

const CampaignPageHeader = ({page, activePageEntrepreneurStars}) => {

    var hasPartner = false;
    if(page === "Stars") {

        // var year = activePageEntrepreneurStars.entrepreneurStars;
        var year = (JSON.parse(localStorage.getItem("activePageEntrepreneurStars"))).entrepreneurStars;

        headerData.title = getValueFromArrayElement(year, "title");
        headerData.year = getValueFromArrayElement(year, "year");
        headerData.subtitle = getValueFromArrayElement(year, "subtitle");
        headerData.place = getValueFromArrayElement(year, "place");
        headerData.date = getValueFromArrayElement(year, "date");
        headerData.partner = getValueFromArrayElement(year, "partner");
        headerData.image = getValueFromArrayElement(year, "image");

        if(headerData.partner)
            hasPartner = true;
    }
    else {
        headerData = {
            title: '',
            year: '',
            subtitle: '',
            place: '',
            date: '',
            partner: '',
            image: ''
        }
        
        var activeYear = page.year;
        var activeCampaign = page.campaign[0].name;
        // var activeCampaign = JSON.parse(localStorage.getItem("activePageCampaignAndYear"))
        // var activeYear = activeCampaign.year;
        // var activeCampaign = activeCampaign.campaign.name;
 
        headerData.title = getConferenceValue(activeCampaign, activeYear, "conferenceTitle")
        headerData.year = getConferenceValue(activeCampaign, activeYear, "conferenceYear")
        headerData.subtitle = getConferenceValue(activeCampaign, activeYear, "conferenceSubtitle")
        headerData.place = getConferenceValue(activeCampaign, activeYear, "conferencePlace")
        headerData.date = getConferenceValue(activeCampaign, activeYear, "conferenceDate")
        headerData.partner = getConferenceValue(activeCampaign, activeYear, "conferencePartner")
        headerData.image = getConferenceValue(activeCampaign, activeYear, "conferenceImage")

        if(headerData.partner)
            hasPartner = true;
    }

    return (
        <>
            <header id="entrepreneurStarsHeader" className="dpc-header">
                <HeaderNavComponent page={page}/>

                <section className="dpc-section dpc-entrepreneur-stars pb-0">
                    <div className="container-fluid">
                        <div className="row dpc-brd-b-1-primary-variant text-center pb-2 mb-3">
                        <div className="col-12 dpc-text-holder px-0">
                            <p className="dpc-subtitle-1">{headerData.title}</p>
                        </div>
                        <div className="col-12 dpc-title-holder px-0">
                            <h4 className="dpc-lg-max-w-65pc-mx-auto">{headerData.subtitle}</h4>
                        </div>
                        </div>
                        <div className="row dpc-col-lg-fl-p0 mb-1 dpc-text-24-sm-19">
                        <div className="col-lg-7 dpc-c-primary">         
                                <span className="d-inline-block dpc-ico-l-arrow-r me-3 mb-2 mb-lg-3"><svg className="dpc-svg-icon"><use xlinkHref="#arrow-right"/></svg>{headerData.place}</span>                     
                                <span className="d-inline-block dpc-ico-l-arrow-r mb-2 mb-lg-3"><svg className="dpc-svg-icon"><use xlinkHref="#arrow-right"/></svg>{headerData.date}</span>
                        </div>
                        <div className="col-lg-5 text-lg-end">
                            { hasPartner &&
                                <span className="d-inline-block dpc-ico-l-arrow-r mb-3"><svg className="dpc-svg-icon dpc-c-neutral"><use xlinkHref="#arrow-right"/></svg>v partnerstvu {headerData.partner}</span>
                            }   
                        </div>
                        </div>
                        <div className="row dpc-brd-b-1-primary-variant pb-4 pb-sm-5 pb-lg-6">
                            <div className="col px-0 dpc-fit-crop-image" title="DPC Campaign Hero Image">
                            <img src={getImagePath(headerData.image)} className="img-fluid" alt="..."/>
                            </div>
                        </div>
                    </div>
                </section>
            </header>
        </>
    );
};

export default CampaignPageHeader;