import { getAliasValue, getValueFromArrayElement } from '../dataManager/findAliasValue';
// import Swiper core and required modules
import { A11y, Scrollbar } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const $ = window.$;

const OurValuesComponent = () => {

    // Get about us values
    var values = JSON.parse(localStorage.getItem("valuesAboutUs"));
    values = values.filter(val => val.contentType.alias !== "ourTeam");

    const openAdditionalInfo = (id) =>{ 
        $('#' + id).addClass('dpc-show');
    };

    const closeAdditionalInfo = (id) =>{ 
        $('#' + id).removeClass('dpc-show');
        
    };
    
    return (
        <>
            {/* <!-- Section Our Values --> */}
            <section id="ourValues" className="dpc-section dpc-section-our-values dpc-pt-64px-sm-80px-lg-150px">
                <div className="container-fluid mb-2 mb-sm-4 mb-lg-5">
                    <h2 className="mb-4 mb-sm-5 mb-lg-6 dpc-brd-b-1-primary-variant">{getAliasValue("aboutUsPage", "valuesTitle")}</h2>


                <Swiper
                    modules={[Scrollbar, A11y]}
                    slidesPerView={4}
                    spaceBetween={30}
                    className="dpc-our-values-slider dpc-min-h-200px"
                    navigation={false}
                    breakpoints={{
                        1: {
                        slidesPerView: 1.35,
                        spaceBetween: 20,
                        },
                        576: {
                        slidesPerView: 2.5,
                        },
                        768: {
                        slidesPerView: 2.5,
                        },
                        992: {
                        slidesPerView: 4,
                        },
                    }}
                >
                {values && values.map((value) => (
                    <SwiperSlide key={value.id} id={value.id}>
                        <h4>{getValueFromArrayElement(value, "valueTitle")}</h4>
                        <div className="dpc-icon-holder"><svg className="dpc-svg-icon"><use xlinkHref="#star"/></svg></div>
                        <p>{getValueFromArrayElement(value, "valuedescription")}</p>
                        <button type="button" className="btn btn-transparent dpc-btn-info-see-more" onClick={() => {openAdditionalInfo(value.id);}}>{getAliasValue("aboutUsPage", "valueButtonViewMore")}</button>
                        <button type="button" className="btn btn-transparent dpc-btn-info-back" onClick={() => {closeAdditionalInfo(value.id);}} >{getAliasValue("aboutUsPage", "valueButtonBack")}</button>

                    </SwiperSlide>
                ))} 
            </Swiper>
                </div>
                </section>
        </>
    );

};

export default OurValuesComponent;