import { useEffect, useState } from 'react';
import { getValueFromArrayElement } from '../dataManager/findAliasValue';
import { getConstellationGallery } from '../dataManager/getGalleryImages';

// import Swiper core and required modules
import { A11y, Autoplay, Navigation, Pagination, Scrollbar } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import axios from 'axios';
import { useLocation } from 'react-router';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import configData from '../conf.json';

const ConstellationComponent = () => {

    const location = useLocation();

    const [activeGalleryImage, setActiveGalleryImage] = useState([]);
    const [activeGalleryImageSwiper, setActiveGalleryImageSwiper] = useState(0);
    var constell = localStorage.getItem("activeConstellation");
    var activeYear = JSON.parse(localStorage.getItem("activePageEntrepreneurStars")).year;
    var actConst;
    if(constell === "undefined"){
        actConst = [];
    }
    else {
        var constellationList = JSON.parse(localStorage.getItem("constellation"));

        const active = constellationList.filter(obj =>
            obj.properties.some(property =>
                property.alias === 'year' && property.value.value === activeYear
            )
        );

        if(active.length !== 0) {
            actConst = active[0];
        }
        else {
            actConst = constellationList[0];
        }
    }
    const [activeConstellation, setActiveConstellation] = useState(actConst);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setActiveGalleryImage(activeGalleryImage);
        if(activeGalleryImage && activeGalleryImage.length != 0) {
            setLoading(false);
        }
    }, [activeGalleryImage]);

    useEffect(() => {
        setActiveGalleryImageSwiper(activeGalleryImageSwiper);
    }, [activeGalleryImageSwiper]);

    useEffect(() => {

        var constell = localStorage.getItem("activeConstellation");
        var activeYear = JSON.parse(localStorage.getItem("activePageEntrepreneurStars")).year;
        var actConst;
        if(constell === "undefined"){
            actConst = [];
        }
        else {
            var constellationList = JSON.parse(localStorage.getItem("constellation"));

            const active = constellationList.filter(obj =>
                obj.properties.some(property =>
                    property.alias === 'year' && property.value.value === activeYear
                )
            );

            if(active.length !== 0) {
                actConst = active[0];
            }
            else {
                actConst = constellationList[0];
            }
        }

        setActiveConstellation(actConst);
        localStorage.setItem("activeConstellation", JSON.stringify(actConst));

        if(constelationYearsList.length > 0) {
            var activeGalleryUrl = configData.REACT_URL + "/umbraco/delivery/api/v1/content/item/" 
                    + JSON.parse(localStorage.getItem("activeConstellation")).children[0].url;

            if(activeGalleryUrl) {
                axios.get(activeGalleryUrl, {
                    headers: {
                    accept: 'application/json'
                    }
                }).then((response) => { 

                    var res = response.data.properties.galleryImages;
                    setActiveGalleryImage(res);

                });
            }
        }
    }, [location]); 


    var constelationYearsList = JSON.parse(localStorage.getItem("constellation"));
    
    var activeGallery = null;
    if(constelationYearsList.length > 0)
        activeGallery = getConstellationGallery(activeConstellation);
    else 
        activeGallery = null;


    const changeActiveConstellation = (constellation) => {

        localStorage.setItem("activeConstellation", JSON.stringify(constellation));
        setActiveConstellation(constellation);
        activeGallery = getConstellationGallery(constellation);

        var activeGalleryUrl = configData.REACT_URL + "/umbraco/delivery/api/v1/content/item/" 
                + constellation.children[0].url;

        if(activeGalleryUrl) {
            axios.get(activeGalleryUrl, {
                headers: {
                accept: 'application/json'
                }
            }).then((response) => { 
                var res = response.data.properties.galleryImages;
                setActiveGalleryImage(res);
            });
        }

      };

    return (
        <>{constelationYearsList.length > 0 && 
            JSON.parse(localStorage.getItem("activePageEntrepreneurStars")).year === new Date().getFullYear().toString() && 
        (
        <> 
            {/* <!-- Section Work Constellation --> */}
            <section id="workConstellation" className="dpc-section dpc-section-work-constellation">
            <div className="container-fluid">
                <h2 className="mb-lg-4 dpc-brd-b-1-primary-variant">{activeGallery && getValueFromArrayElement(activeGallery[0], "galleryTitle")}</h2>
                <div className="row dpc-col-lg-auto-30-fl-p0 mb-4 mb-lg-5 pb-4 pb-lg-5 dpc-brd-b-1-primary-variant">
                    <div className="col dpc-col-lg-30 dpc-brd-lg-r mt-2 mt-lg-0 mb-4 mb-lg-5">
                    <div>
                    <span className="dpc-c-primary d-inline-block align-bottom me-1">Izberite leto:</span>
                    <div className="dropdown dpc-dropdown d-inline-block">
                        <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        {getValueFromArrayElement(activeConstellation, "year")}
                        </button>
                        <ul className="dropdown-menu">
                        { constelationYearsList && constelationYearsList.map((year) => (
                                <li><a className="dropdown-item" onClick={() => { changeActiveConstellation(year); }}>{getValueFromArrayElement(year, "year")}</a></li>
                        ))}
                        </ul>
                    </div>
                    </div>
                    </div>
                    <div className="col ps-lg-3 mb-0 mb-md-4 mb-lg-5">
                    <h4 className="pe-lg-25pc">{getValueFromArrayElement(activeConstellation, "title")}</h4>
                    <p className="mb-1 pe-lg-25pc dpc-text-22-sm-19">{getValueFromArrayElement(activeConstellation, "description")}</p>
                    </div>
                </div>
                { loading ? (<p></p>) : (
                <> { activeGalleryImage &&  (
                    <div className="row pt-2 pt-md-3">
                        <div className="col p-0">
                        <Swiper
                            modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
                            slidesPerView={1.7}
                            spaceBetween={40}
                            className="dpc-constellation-slider dpc-min-h-200px"
                            autoplay={{
                                delay: 4000
                            }}
                            centeredSlides = {true}
                            roundLengths = {true}
                            loop = {true}
                            loopAdditionalSlides = {10}
                            navigation={{
                                clickable: true,
                            }}
                            onSlideChange={(swiper) => {setActiveGalleryImageSwiper(swiper.realIndex + 1); }
                        }
                            breakpoints={{
                                1: {
                                slidesPerView: 1.14,
                                spaceBetween: -14,
                                },
                                576: {
                                slidesPerView: 1
                                },
                                768: {
                                    slidesPerView: 1,
                                    //spaceBetween: 40,
                                },
                                992: {
                                    slidesPerView: 1.8,
                                    spaceBetween: 140,
                                },
                            }}
                            >
                            {activeGalleryImage && activeGalleryImage.map((image) => (
                                <SwiperSlide key={image.id}>
                                        
                                    <div className="dpc-constellation-content">
                                        <img src={configData.REACT_URL + image.url} className="img-fluid" alt="..."/>
                                    </div>  

                                </SwiperSlide>
                            ))} 
                        </Swiper>

                        <div className="dpc-constellation-pager mt-lg-1">
                            <span className="dpc-active">{activeGalleryImageSwiper}</span> - {activeGalleryImage.length}   
                        </div>
                        </div>
                    </div>
                    )}
                </> )
                }
                
                        
                
            </div>
            </section>
        </>
        )}</>
            
    );

};

export default ConstellationComponent;