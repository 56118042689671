import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAliasValue, getConferenceValue } from '../dataManager/findAliasValue';
const RegisterForConferenceComponent = ({page}) => {

    var activeYear = page.year;
    var activeCampaign = page.campaign[0].name;
    var isActiveCampaign = getConferenceValue(activeCampaign, activeYear, "conferenceIsActual");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    let navigate = useNavigate(); 
    const navigateTo = (path) =>{ 
        navigate(path);
    }

    return (
        <>
            {/* <!-- Section Register For Conference" --> */}
        <section id="registerForConference" className="dpc-section dpc-section-register-for-confrence">
            <div className="container-fluid">
                <div className="row dpc-col-lg-auto-30-fl-p0">
                <div className="col dpc-col-lg-30 dpc-brd-lg-r mt-4 mt-lg-0 dpc-btn-w-fix">
                    <div className="d-flex flex-column flex-lg-column align-items-baseline align-items-sm-start align-items-lg-baseline gap-3">
                    { getConferenceValue(activeCampaign, activeYear, "isActiveLiveStream") === true && 
                      getConferenceValue(activeCampaign, activeYear, "liveStreamLink") !== "" &&
                      getConferenceValue(activeCampaign, activeYear, "liveStreamTitle") !== "" && 
                        <a target="_blank" type="button" className="btn btn-outline-primary dpc-btn-wrap-fix" href={getConferenceValue(activeCampaign, activeYear, "liveStreamLink")}>{getConferenceValue(activeCampaign, activeYear, "liveStreamTitle")}</a>
                    }
                    { isActiveCampaign && 
                        // <button type="button" className="btn btn-primary text-wrap text-start" onClick={() => {openRegisterForConferenceMenu();}}>{getAliasValue("conferenceList", "registerForConferenceButton")}</button>
                        <button type="button" className="btn btn-primary text-start dpc-btn-wrap-fix" onClick={() => {navigateTo('/kontakt/prijava')}}>{getAliasValue("conferenceList", "registerForConferenceButton")}</button>
                    }
                    {/* <button type="button" className="btn btn-outline-primary" onClick={() => {openContactMenu();}}>{getAliasValue("homePage", "actualBecomeASponsor")}</button> */}
                    <button type="button" className="btn btn-outline-primary dpc-btn-wrap-fix" onClick={() => {navigateTo('/kontakt/postanite-sponzor');}}>{getAliasValue("homePage", "actualBecomeASponsor")}</button>
                    </div>
                </div>
                <div className="col ps-lg-3 order-first order-lg-0">
                    <h4 className="pe-lg-20pc">{getConferenceValue(activeCampaign, activeYear, "conferenceDescriptionTitle")}</h4>
                    <p className="mb-1 pe-lg-20pc dpc-text-22-sm-19">{getConferenceValue(activeCampaign, activeYear, "conferenceDescription")}</p>

                </div>
                </div>
            </div>
        </section>
        </>
    );
}

export default RegisterForConferenceComponent;