import axios from 'axios';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import configData from '../conf.json';
import { getImagePath, getValueFromArrayElement } from '../dataManager/findAliasValue';
import { getCorrectUrl } from '../dataManager/getCorrectUrlString';

const TalkersComponent = () => {

    var numberOfTalkersToShow = 8;
    var talkers = JSON.parse(localStorage.getItem("talkersList"));
    const [peopleTalkers, setPeopleTalkers] = useState();
    const [showAllTalkers, setShowAllTalkers] = useState(0);
  
    const location = useLocation();
    var temp;
    
    useEffect(() => {
        setShowAllTalkers(showAllTalkers);
    }, [showAllTalkers]);

    useEffect(() => {
        setPeopleTalkers(peopleTalkers);
    }, [peopleTalkers]);

    useEffect(() => {
        setPeopleTalkers();

        // var url = window.location.href;
        // var reversedUrl = url.split('').reverse().join('');
        // var campaignAndYear = reversedUrl.substring(0, reversedUrl.indexOf('/', reversedUrl.indexOf('/') + 1));
        // campaignAndYear = campaignAndYear.split('').reverse().join('');

        var activeConference = JSON.parse(localStorage.getItem("activePageCampaignAndYear"));
        var getSponsorsImagesURL = configData.REACT_URL 
                + "/umbraco/delivery/api/v1/content/item/" 
                + getCorrectUrl(activeConference.campaign[0].name)
                + '/'
                + getCorrectUrl((activeConference.campaign[0].children.filter(elem => elem.name.includes(activeConference.year)))[0].name);

        axios.get(getSponsorsImagesURL, {
            headers: {
            accept: 'application/json'
            }
        }).then((response) => { 
                
            setPeopleTalkers(response.data.properties.talkers);
            if(response.data.properties.talkers !== null) {
                setShowAllTalkers(response.data.properties.talkers.length > numberOfTalkersToShow ? false : true)
            
                localStorage.setItem("actualTalkers", JSON.stringify(response.data.properties.talkers));
                
                var activeT = JSON.parse(localStorage.getItem("actualTalkers"));

                const namesFromSencodList = activeT.map(osoba => osoba.name);

                temp = talkers.filter(osoba => namesFromSencodList.includes(osoba.name));
                setPeopleTalkers(temp);

            }
        });
        
    }, [location]);

    let navigate = useNavigate(); 
    const navigateTo = (path) =>{ 
      navigate(path);
    };

    const showMore = () =>{ 
        setShowAllTalkers(true);
    };

    return (
        <> { peopleTalkers && (
        <>
            {/* <!-- Section Speakers --> */}
            <section id="speakers" className="dpc-section dpc-section-speakers">
                <div className="container-fluid">
                    <h2 className="mb-lg-4">Govorniki</h2>
                    {showAllTalkers ?
                        (<div className="dpc-speakers-grid">

                        {peopleTalkers && peopleTalkers.map(talker => (
                            <div className="card">
                                <img src={getImagePath(getValueFromArrayElement(talker, "talkerImage"))} className="card-img-top" alt="..."/>
                                <div className="card-body">
                                    <h4>{getValueFromArrayElement(talker, "talkerName")}</h4>
                                    <p className="card-text">{getValueFromArrayElement(talker, "talkerRole")}</p>
                                    <button type="button" target="_blank" className="btn btn-tertiary dpc-ico-r ms-n175" onClick={() => {navigateTo(talker.url.toString());}}>Preberi Bio <svg className="dpc-svg-icon"><use xlinkHref="#arrow-right"/></svg></button>
                                </div>
                            </div>
                        ))}

                        </div>)
                        :
                        (<div className="dpc-speakers-grid">

                        {peopleTalkers && peopleTalkers.slice(0,numberOfTalkersToShow).map(talker => (
                            <div className="card">
                                <img src={getImagePath(getValueFromArrayElement(talker, "talkerImage"))} className="card-img-top" alt="..."/>
                                <div className="card-body">
                                    <h4>{getValueFromArrayElement(talker, "talkerName")}</h4>
                                    <p className="card-text">{getValueFromArrayElement(talker, "talkerRole")}</p>
                                    <button type="button" target="_blank" className="btn btn-tertiary dpc-ico-r ms-n175" onClick={() => {navigateTo(talker.url.toString());}}>Preberi Bio <svg className="dpc-svg-icon"><use xlinkHref="#arrow-right"/></svg></button>
                                </div>
                            </div>
                        ))}

                        </div>)
                    }

                </div>
                {(peopleTalkers.length > numberOfTalkersToShow) && !showAllTalkers &&
                    <div className="container-fluid">
                        <div className="row mt-2 mt-sm-3 mt-lg-4">
                            <div className="col text-center">
                            <button type="button" className="btn btn-outline-primary" onClick={() => {showMore();}}>naloži še {peopleTalkers.length}</button>
                            </div>
                        </div>
                    </div>
                }
            </section>
        </>
        )} </>
    );
}

export default TalkersComponent;