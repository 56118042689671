import { useNavigate } from 'react-router-dom';
import { getImagePath, getValueFromArrayElement } from '../dataManager/findAliasValue';
import { getOurNomineesGallery } from '../dataManager/getGalleryImages';

const LastEventsEntrereneurStars = () => {

    var activePageEntrepreneurStars = JSON.parse(localStorage.getItem("activePageEntrepreneurStars"));
    var starsYearList = JSON.parse(localStorage.getItem("entrepreneurStars"));
    // var starYear = starsYearList.find(year => year.name === activePageEntrepreneurStars.year);
    var filteredListWithoutCurrentYear = starsYearList.filter(obj => !obj.properties.some(prop => prop.alias === "year" && prop.value.value === new Date().getFullYear().toString()));

    const changeActivePageEntrepreneurStars = (listElement, year) => {

        localStorage.setItem("activePageEntrepreneurStars", JSON.stringify({entrepreneurStars: listElement, year: year}));
        // setActivePageEntrepreneurStars({entrepreneurStars: listElement, year: year})
    };

    let navigate = useNavigate(); 
    const navigateTo = (path) =>{ 
      navigate(path);
    }

    const navigateToGalerija = (path, year) =>{ 
        // var activeConference = JSON.parse(localStorage.getItem("activePageCampaignAndYear"));
        var activeGallery = getOurNomineesGallery(year);
        
        if(activeGallery.length > 0) {
            localStorage.setItem("linkGallery", JSON.stringify(true));
        }
        
        navigate(path);
      }

      var canShow  = filteredListWithoutCurrentYear.length === 1 && filteredListWithoutCurrentYear[0].properties.find(elem => elem.alias === "year").value.value === activePageEntrepreneurStars.year;

    return (
        <>{filteredListWithoutCurrentYear && filteredListWithoutCurrentYear.length > 0 && !canShow && (
        <>
            {/* <!-- Section Past Events --> */}
                <section id="pastEvents" className="dpc-section dpc-section-past-events">
                <div className="container-fluid">
                    {filteredListWithoutCurrentYear && filteredListWithoutCurrentYear.length > 0 && <h2 className="mb-lg-4 dpc-brd-b-1-primary-variant">Pretekli dogodki</h2>}
                    <div className="dpc-past-events-grid">

                        {filteredListWithoutCurrentYear && filteredListWithoutCurrentYear.map((year) => (
                            !getValueFromArrayElement(year, "year").includes(activePageEntrepreneurStars.year) && (
                            <div className="card">
                                <div className="dpc-bg-image-holder cursor-pointer" style={{"backgroundImage": `url(${getImagePath(getValueFromArrayElement(year, "image"))})`}} title="...">
                                    <img src={getImagePath(getValueFromArrayElement(year, "image"))} className="img-fluid" alt="..."
                                     onClick={() => {var newYear = getValueFromArrayElement(year, "year");
                                                        navigateTo('/podjetniske-zvezde/' + newYear); 
                                                        changeActivePageEntrepreneurStars(year, newYear);
                                     }}/>
                                </div>
                                <div className="card-body">
                                    <h4 className="dpc-pointer dpc-c-accent-hover mb-2" onClick={() => {var newYear = getValueFromArrayElement(year, "year");
                                                        navigateTo('/podjetniske-zvezde/' + newYear); 
                                                        changeActivePageEntrepreneurStars(year, newYear);
                                                        }}
                                    >
                                        DPZ {getValueFromArrayElement(year, "year")}
                                    </h4>
                                    <h4 className="dpc-pointer dpc-c-accent-hover" onClick={() => {var newYear = getValueFromArrayElement(year, "year");
                                                        navigateToGalerija('/podjetniske-zvezde/' + newYear, year); 
                                                        changeActivePageEntrepreneurStars(year, newYear);
                                                        }}
                                    >
                                        Galerija
                                    </h4>
                                    <a target="_blank" rel="noreferrer" href={getValueFromArrayElement(year, "youTubeLink")} className="btn btn-tertiary dpc-ico-r ms-n175">YouTube <svg className="dpc-svg-icon"><use xlinkHref="#arrow-right"/></svg></a>
                                </div>
                            </div>
                            )
                        ))}

                    </div>
                </div>
                </section>
        </>
        )}</>
    );
};

export default LastEventsEntrereneurStars;