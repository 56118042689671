
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ActualComponent from "./ActualComponent";
import CampaignGalleryComponent from "./CampaignGalleryComponent";
import CampaignPageHeader from "./CampaignPageHeader";
import DownloadDocsComponent from "./DownloadsDocsComponent";
import FooterComponent from "./FooterComponent";
import LastComferencesComponent from "./LastConferencesComponent";
import OurSponsorsComponent from "./OurSponsorsComponent";
import ProgramComponent from "./ProgramComponent";
import RegisterForConferenceComponent from "./RegisterForConferenceComponent";
import TalkersComponent from "./TalkersComponent";

import { Helmet } from "react-helmet";
import { getConferenceAliasValue, getConferenceValue, getValueFromArrayElement } from "../dataManager/findAliasValue";
import { getRssFeedNews } from "../dataManager/rssFeedNewsDataManager";
import VideoGalleryComponent from "./VideoGalleryComponent";
import SvgIconsComponent from "./SvgIconsComponent";

const CampaignPageComponent = ({shouldLoad}) => {

    let {campaign, year} = useParams();
    const {location} = useLocation();

    const [activePageCampaignAndYear, setActivePageCampaignAndYear] = useState(JSON.parse(localStorage.getItem("activePageCampaignAndYear")));
    const [urlLoaded, setUrlLoaded] = useState(false);
    const [correctData, setCorrectData] = useState(false);
    const [getRssFeedDataDone, setGetRssFeedDataDone] = useState(false);

    const [activeNewsList, setActiveNewsList] = useState();

    const [isActualConfAndYear, setIsActualConfAndYear] = useState(false);

    const galleryRef = useRef(null);

    useEffect(() => {
        setActiveNewsList(activeNewsList);
        setGetRssFeedDataDone(true);
        document.title = "Poslovna kampanja";
    }, [activeNewsList]);

    let navigate = useNavigate(); 
    const navigateTo = (path) =>{ 
        navigate(path);
    }

    useEffect(() => {
        setIsActualConfAndYear(isActualConfAndYear);
    }, [isActualConfAndYear]);

    useEffect(() => {

        if(shouldLoad){
            var conferenceList = JSON.parse(localStorage.getItem("conferences"));
            // Find data for desired conference
            var desiredConf = conferenceList.filter(conf => conf.url.toString() === ('/poslovna-kampanja/' + campaign + '/'));
            // localStorage.setItem("activePageCampaignAndYear", JSON.stringify({campaign: desiredConf, year: year}));
            if(desiredConf.length === 0) {
                //When campaign from url doesn't exist, redirect to home page
                localStorage.setItem("activePageCampaignAndYear", JSON.stringify({campaign: [conferenceList[0]], year: new Date().getFullYear().toString()}));
                navigateTo('/');
                
            } else {

                //When year from url doesn't exist, redirect to home page
                if(desiredConf[0].children.filter(confYear => getValueFromArrayElement(confYear, "conferenceYear") === year).length === 0) {
                    localStorage.setItem("activePageCampaignAndYear", JSON.stringify({campaign: [conferenceList[0]], year: new Date().getFullYear().toString()}));
                    navigateTo('/');
                }
                else {
                    localStorage.setItem("activePageCampaignAndYear", JSON.stringify({campaign: desiredConf, year: year}));
                    setActivePageCampaignAndYear(JSON.parse(localStorage.getItem("activePageCampaignAndYear")));
                    fetchNews();
                    setUrlLoaded(true);
                }
            }
        }
        setIsActualConfAndYear(false);

        window.scrollTo(0, 0);

    }, [campaign]);

    useEffect(() => {

        if(shouldLoad){
            var conferenceList = JSON.parse(localStorage.getItem("conferences"));
            // Find data for desired conference
            var desiredConf = conferenceList.filter(conf => conf.url.toString() === ('/poslovna-kampanja/' + campaign + '/'));
            if(desiredConf.length === 0) {
                //When campaign from url doesn't exist, redirect to home page
                localStorage.setItem("activePageCampaignAndYear", JSON.stringify({campaign:  [conferenceList[0]], year: new Date().getFullYear().toString()}));
                navigateTo('/');
                
            }
            else {
                //When year from url doesn't exist, redirect to home page
                if(desiredConf[0].children.filter(confYear => getValueFromArrayElement(confYear, "conferenceYear") === year).length === 0) {
                    localStorage.setItem("activePageCampaignAndYear", JSON.stringify({campaign: [conferenceList[0]], year: new Date().getFullYear().toString()}));
                    navigateTo('/');
                } else {
                    localStorage.setItem("activePageCampaignAndYear", JSON.stringify({campaign: desiredConf, year: year}));
                    setActivePageCampaignAndYear(JSON.parse(localStorage.getItem("activePageCampaignAndYear")));
                    fetchNews();
                    setUrlLoaded(true);
                }
            }
        }
        
    }, [shouldLoad]);

    const fetchNews = async () => {

        var conferenceList = JSON.parse(localStorage.getItem("conferences"));
        // Find data for desired conference
        var desiredConf = conferenceList.filter(conf => conf.url === ('/poslovna-kampanja/' + campaign + '/'));

        setGetRssFeedDataDone(false);
        var newsList = await getRssFeedNews(getConferenceAliasValue(desiredConf[0].name, "rssFeedUrl"));
        setActiveNewsList(JSON.parse(localStorage.getItem("activeConfNews")));
    }

    return (
            <> 
                {shouldLoad && urlLoaded && (
                <>

                <SvgIconsComponent/>

                <CampaignPageHeader page={activePageCampaignAndYear}/>

                <Helmet>
                    <meta name="description" content={getConferenceValue(activePageCampaignAndYear.campaign[0].name, activePageCampaignAndYear.year, "conferenceDescription")} />
                    <meta property="og:title" content={activePageCampaignAndYear.campaign[0].name} />
                    <meta property="og:description" content={getConferenceValue(activePageCampaignAndYear.campaign[0].name, activePageCampaignAndYear.year, "conferenceDescription")} />
                </Helmet>

                <main id="main" className="dpc-main fade-in-1s">

                    <h1 className="dpc-visually-sleep">DPC</h1>

                    <RegisterForConferenceComponent page={activePageCampaignAndYear}/>

                    <ProgramComponent year={year}/>
                    
                    <ActualComponent page={campaign} newsList={activeNewsList} getRssFeedDataDone={getRssFeedDataDone} isActualConfAndYear={isActualConfAndYear} setIsActualConfAndYear={setIsActualConfAndYear}/>

                    <TalkersComponent/>

                    {/* <TestimonialsComponent page={"Campaign"}/> */}

                    <OurSponsorsComponent page={"Campaign"}/>

                    <CampaignGalleryComponent ref={galleryRef}/>

                    <VideoGalleryComponent page={"Campaign"}/>


                    <LastComferencesComponent setActivePageCampaignAndYear={setActivePageCampaignAndYear}/>

                    <DownloadDocsComponent/>

                </main>

                <FooterComponent/>
                </>)}
        </>
    );

};

export default CampaignPageComponent;