import { getAliasValue, getImagePath } from '../dataManager/findAliasValue';
import configData from '../conf.json';
import axios from 'axios';
import { useState } from 'react';

const AchievementsComponent = () => {

  const [description, setDescription] = useState();

  var getDescriptionURL = configData.REACT_URL + "/umbraco/delivery/api/v1/content/item/";
              
    axios.get(getDescriptionURL, {
        headers: {
        accept: 'application/json'
        }
    }).then((response) => { 
        setDescription(response.data.properties.achievementsInfo.markup);
    });

    var classNameToAdd = 'dpc-text-25-lg-19-sm-17';
    var htmlString = description;

    var renderedHtml;
    if(htmlString) {

      var tempElement = document.createElement('div');
      tempElement.innerHTML = htmlString;

      if (tempElement.firstChild.classList.length > 0) {
          tempElement.firstChild.classList.remove(tempElement.firstChild.classList.item(0));
      }

      tempElement.firstChild.classList.add(classNameToAdd);
      var modifiedHtmlString = tempElement.innerHTML;
      renderedHtml = <div dangerouslySetInnerHTML={{ __html: modifiedHtmlString }} />;
    }


    return (
        <>
            {/* <!-- Section Difference --> */}
            <section id="difference" className="dpc-section-alt dpc-section-difference">
              <div className="container-fluid">
                  <div className="row dpc-col-lg-auto-46-fl-p0 mt-4 dpc-brd-up-lg-b-1-primary-variant pb-3 pb-sm-4 mb-3 mb-sm-4">
                    <div className="col dpc-col-lg-46">
                      <h2 className="mb-lg-4 dpc-brd-down-lg-b-1-primary-variant">{getAliasValue("homePage", "achievementsTitle")}</h2>
                    </div>
                    <div className="col ps-0 ps-sm-3 ps-lg-5 d-lg-flex dpc-brd-up-sm-l-1-primary-variant align-items-lg-center offset-sm-3 offset-lg-0">
                      {/* <p className="dpc-text-25-lg-19-sm-17">{getAliasValue("homePage", "achievementsInfo")}</p> */}
                      {renderedHtml}
                    </div>
                  </div>
                  <div className="row dpc-counter pb-3">
                    <div className="col-6 col-lg-3 px-0 pe-lg-1 pb-3">
                      <span className="h2 d-block text-nowrap">{getAliasValue("homePage", "achievementsYears")}</span>
                      <span className="dpc-text">{getAliasValue("homePage", "achievementsYearsTitle")}</span>
                    </div>
                    <div className="col-6 col-lg-3 px-0 ps-lg-1 pb-3">
                      <span className="h2 d-block text-nowrap">{getAliasValue("homePage", "successfulCampaigns")}</span>
                      <span className="dpc-text">{getAliasValue("homePage", "successfulCampaignsTitle")}</span>
                    </div>
                    <div className="col-6 col-lg-3 px-0 pb-3">
                      <span className="h2 d-block text-nowrap">{getAliasValue("homePage", "articleViews")}</span>
                      <span className="dpc-text">{getAliasValue("homePage", "articleViewsTitle")}</span>
                    </div>
                    <div className="col-6 col-lg-3 px-0 pb-3">
                      <span className="h2 d-block text-nowrap">{getAliasValue("homePage", "participants")}</span>
                      <span className="dpc-text">{getAliasValue("homePage", "participansTitle")}</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 px-0">
                      <div className="dpc-bg-image-holder" style={{"backgroundImage": `url(${getImagePath(getAliasValue("homePage", "achievementsImage"))})`}} title="DPC Incoming Image">
                        <img src={getImagePath(getAliasValue("homePage", "achievementsImage"))} className="img-fluid" alt="..."/>
                        </div>
                    </div>
                  </div>
              </div>
            </section>
        </>
    );
}

export default AchievementsComponent;