import { useNavigate } from 'react-router-dom';
import { getAliasValue, getImagePath, getIncomingCampaign, getIncomingConferenceValue } from '../dataManager/findAliasValue';


const IncomingConferenceComponent = () => {

  var incomingConference = JSON.parse(localStorage.getItem("incomingConference"));
  var incomingConferenceWithConferenceNameAndIncomingYear = JSON.parse(localStorage.getItem("incomingConferenceWithConfNameAndObject"));

  let navigate = useNavigate(); 
    const navigateTo = (path) =>{ 
      navigate(path);
    }

  const changeActiveConference = (conf) =>{ 
    localStorage.setItem("activePageCampaignAndYear", JSON.stringify({campaign: conf, year: new Date().getFullYear().toString()}));
  };

    return (
      <>
      { incomingConference && (
        <>
            {/* <!-- Section Incoming --> */}
            <section id="incoming" className="dpc-section dpc-section-incoming pt-5">
              <div className="container-fluid">
                <h2 className="mb-4 text-lg-center dpc-brd-down-sm-b-1-primary-variant">{getAliasValue("homePage", "nextConferenceTitle")}</h2>
                  <div className="row dpc-col-lg-auto-46-fl-p0 dpc-brd-up-lg-b-1-primary-variant pb-sm-4">
                    <div className="col-12 px-0 dpc-brd-up-lg-b-1-primary-variant pb-3 pb-lg-5 mb-2 mb-lg-4 order-2 order-lg-0">
                      <div className="dpc-fit-crop-image" title="DPC Incoming Image">
                        <img src={getImagePath(getIncomingConferenceValue("conferenceImage"))} className="img-fluid" alt="..." loading="lazy" />
                        </div>
                    </div>
                    <div className="col dpc-brd-lg-r dpc-col-lg-46 order-1 order-lg-0 ps-0">
                      <div className="mt-lg-n25 mb-down-lg-45 mb-lg-5">
                        <h3 className="mb-2 pt-lg-1 ">{getIncomingConferenceValue("conferenceTitle") }</h3>
                        <p className="pt-lg-1 dpc-text-24-sm-20">{getIncomingConferenceValue("conferenceDate") }, {getIncomingConferenceValue("conferencePlace") }</p>
                      </div>
                    </div>
                    <div className="col ps-lg-5 py-2 pe-0 order-4 order-lg-0">
                      <p className="dpc-text-25-sm-20 mb-0">{getIncomingConferenceValue("conferenceDescription") }</p>
                    </div>
                    <div className="col-12 order-3 order-lg-0 dpc-brd-down-lg-b-1-primary-variant pb-4 pb-lg-0 mb-3 mb-lg-0 px-0">
                      <div className="d-flex justify-content-start justify-content-sm-end flex-wrap d-lg-block mt-lg-n45">
                        <button type="button" className="btn btn-primary me-2 mt-3 mt-sm-0" onClick={() => {navigateTo(incomingConferenceWithConferenceNameAndIncomingYear.conference.url.toString() +  incomingConferenceWithConferenceNameAndIncomingYear.incoming.name); changeActiveConference(getIncomingCampaign())}}>{getAliasValue("homePage", "aboutConferenceButton")}</button>
                        {/* <button type="button" className="btn btn-outline-primary mt-3 mt-sm-0" onClick={() => {openContactMenu();}}>{getAliasValue("homePage", "becomeASponsor")}</button> */}
                        <button type="button" className="btn btn-outline-primary mt-3 mt-sm-0" onClick={() => {navigateTo('/kontakt/postanite-sponzor');}}>{getAliasValue("homePage", "becomeASponsor")}</button>
                      </div>
                    </div>
                  </div>
              </div>
            </section>
            </>
        )}
        </>
    );
}

export default IncomingConferenceComponent;