import { useEffect } from 'react';

const GeneralQuestionsComponent = () => {

  useEffect(() => {

    // Embed HubSpot Form Script
    const script = document.createElement('script');
    script.src = '//js-eu1.hsforms.net/forms/embed/v2.js';
    document.head.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "eu1",
          portalId: "25280231",
          formId: "82fa1c70-36e5-45b8-a405-86ea878a35a9",
          target: ".nav-contact-tab"
        });
        
      }
    });

    return () => {
        const formElement = document.getElementById('hubspot-form-questions');
        if (formElement) {
          formElement.innerHTML = ''; // Clear the content of the form element
        }
    
        document.head.removeChild(script);
      };
  }, []);

  // const [message, setMessage] = useState("");
  // const [email, setEmail] = useState("");

  // const [show, setShow] = useState(false);
  // const [emailMessageAlert, setEmailMessageAlert] = useState("");
  // const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  // useEffect(() => {
  //     $("#textareaquestions").on("keyup", function () {
  //         $(this).height("38px");
  //         $(this).height(this.scrollHeight);
  //     });
  // }, []);

  // const checkRequired = () => {

  //     if(message === "" || email === ""){
  //       setIsButtonDisabled(true);
  //     } 
  //     else{
  //       var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  //       if(email.match(mailformat) === null) {
  //         setIsButtonDisabled(true);
  //       }
  //       else {
  //         setIsButtonDisabled(false);
  //       }
  //     }
  // };

  // useEffect(() => {
  //     checkRequired();
  // }, [message, email]);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  // const sendEmail = (emailType) => {

  //     const data = {
  //         message: message,
  //         email: email,
  //         emailType: emailType
  //     }
  //     const apiEndpointUrl = configData.UMBRACO_SERVER_URL  + '/emailQuestions/';

  //     axios.post(apiEndpointUrl, data, {
  //         headers: {
  //           accept: 'application/json'
  //         }
  //       })
  //     .then((response) => { 
  //         setEmailMessageAlert(getAliasValue("contact", "sendingEmailSuccessfully"));
  //         setShow(true);
  //     })
  //     .catch((err) => {
  //         console.log('error', err);
  //         setEmailMessageAlert(getAliasValue("contact", "unsuccessfullySendingEmail"));
  //         setShow(true);
  //     })
  // };
    
  //   const setErrorClass = (value) => {

  //     var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  //     if(value.match(mailformat)) {
  //         $('.email').removeClass('dpc-error');
  //     }
  //     else {
  //         $('.email').addClass('dpc-error');
  //     }
  // };

  return (
    <>
      <div className="tab-pane nav-contact-tab fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
      <div id="hubspot-form-questions"></div>
      </div>
    </>
  );


    // return (
    //     <>
    //         <div className="tab-pane nav-contact-tab fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                      
    //                   <p>{getAliasValue("contact", "generalQuestionsDescription")}</p>
                    
    //                    <div className="row mt-3 mb-4 mt-md-2 mb-md-5">
    //                     <div className="col-12 pb-3">
    //                     <div className="mb-5">
    //                           <input type="email" className="email form-control" id="email" placeholder={getAliasValue("contact", "registerEmail")} onChange={(e) => {setEmail(e.target.value); setErrorClass(e.target.value);}}/>
    //                       </div>  
    //                     </div>
    //                     <div className="col-12">
    //                       <div className="dpc-textarea-autoresize">
    //                         <textarea spellcheck="false" type="text" id="textareaquestions" className="form-control" placeholder={getAliasValue("contact", "writeAMessage")} required onChange={(e) => setMessage(e.target.value)}></textarea>
    //                       </div>
    //                     </div>
    //                   </div>
  
    //                   <div className="row dpc-tab-pane-footer">
    //                       <Modal show={show} onHide={handleClose}>
    //                           <Modal.Header closeButton>
    //                           <Modal.Title>{emailMessageAlert}</Modal.Title>
    //                           </Modal.Header>
    //                       </Modal>

    //                       {getAliasValue("contact", "generalQuestionsImage") && 
    //                       <div className="col-md-4 offset-md-8 order-2 order-md-1 mb-3 mb-md-0 d-md-flex justify-content-md-end">
    //                         <img src={getImagePath(getAliasValue("contact", "generalQuestionsImage"))} className="become-sponsor-image img-fluid" alt="..."/>
    //                       </div>}


    //                       <div className="col-md-3 order-1 order-md-2 text-end text-md-start">
    //                         <button type="button" className="btn btn-tertiary dpc-ico-r d-btn-submit" disabled={isButtonDisabled} onClick={() => {sendEmail("questions");}}>
    //                           {getAliasValue("contact", "sendButton")} 
    //                           <svg className="dpc-svg-icon"><use xlinkHref="#arrow-right"/></svg>
    //                         </button>
    //                       </div>
    //                       <div className="col-md-9 text-md-end order-3">
    //                         <span className="dpc-item"><svg className="dpc-svg-icon"><use xlinkHref="#email"/></svg> 
    //                           {getAliasValue("contact", "generalQuestionsEmail")}
    //                         </span>
    //                         {/* <span className="dpc-item"><svg className="dpc-svg-icon"><use xlinkHref="#telephone"/></svg> {getAliasValue("contact", "contactPhoneNumber")}</span> */}
    //                         {getAliasValue("contact", "contactLinkedin") != '' && (
    //                           <a rel="noreferrer" className="dpc-item v-al-unset dpc-icon" href={getAliasValue("contact", "generalQuestionsLinkedin")} target="_blank" title="Linked In">
    //                             <svg className="dpc-svg-icon"><use xlinkHref="#linked-in"/></svg>
    //                           </a>
    //                         )}
    //                       </div>
    //                   </div>
    //             </div>
    //     </>
    // );
};

export default GeneralQuestionsComponent;