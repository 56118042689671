import { getOurNomineesGallery } from '../dataManager/getGalleryImages';

// import Swiper core and required modules
import { A11y, Autoplay, Navigation, Pagination, Scrollbar } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';
import configData from '../conf.json';

// Import Swiper styles
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { getCorrectUrl } from "../dataManager/getCorrectUrlString";

const OurNomineesGalleryComponent = () => {

    const location = useLocation();

    const [activeGalleryImage, setActiveGalleryImage] = useState(0);
    const [gallery, setGallery] = useState([]);
    const [galleryInfo, setGalleryInfo] = useState();

    var galerryProperties;
    var galleryTemp;

    useEffect(() => {

        const galleryElement = document.getElementById('ourNominees');
        if (galleryElement && JSON.parse(localStorage.getItem("linkGallery"))) {

        var distanceFromTop = 0;

        if (galleryElement) {
            // Loop through each parent element and add its offsetTop to the distanceFromTop variable
            var currentElement = galleryElement;
            while (currentElement.offsetParent) {
                distanceFromTop += currentElement.offsetTop;
                currentElement = currentElement.offsetParent;
            }
        }
            window.scrollTo(distanceFromTop, distanceFromTop);
            localStorage.setItem("linkGallery", JSON.stringify(false));
        }
        else if(galleryElement){
            window.scrollTo(0,0);
        }
    }, [gallery]);

    useEffect(() => {
    
        setGallery([]);
        setGalleryInfo();
        var activePageEntrepreneurStars = JSON.parse(localStorage.getItem("activePageEntrepreneurStars"));
        var year = activePageEntrepreneurStars.entrepreneurStars;

        var activeGallery = getOurNomineesGallery(year);
        if(activeGallery && activeGallery.length !== 0) {
        

            var activeGalleryUrl = configData.REACT_URL + "/umbraco/delivery/api/v1/content/item/" 
                + getCorrectUrl(year.name)
                +  '/' + getCorrectUrl(activeGallery[0].name);
            activeGalleryUrl = activeGalleryUrl.replace('%20', '-').replace(' ', '-');
            
            if(activeGalleryUrl) {
                axios.get(activeGalleryUrl, {
                    headers: {
                    accept: 'application/json'
                    }
                }).then((response) => { 
                    galerryProperties = response.data;
                    galleryTemp = response.data.properties.galleryImages;

                    setGallery(galleryTemp);
                    setGalleryInfo(galerryProperties);
                });
            }
        }
    }, [location]);

    return (
        <>
            { gallery && galleryInfo &&  
              JSON.parse(localStorage.getItem("activePageEntrepreneurStars")).year !== new Date().getFullYear().toString() && 
        (
        <>
          {/* <!-- Section Gallery --> */}
            <section id="ourNominees" className="dpc-section dpc-section-our-nominees">
            <div className="container-fluid">
                <h2 className="dpc-brd-b-gray">{galleryInfo.properties.galleryTitle}</h2>
                <div className="row dpc-col-lg-auto-24-fl-p0 mb-2 mb-sm-3 mb-lg-4">
                    <div className="col col-sm-8 dpc-col-lg-24 dpc-brd-lg-r dpc-text-22-sm-19">
                    {/* <!-- <p className="text-uppercase">GREGA JERKIČ</p> --> */}
                    <p>{galleryInfo.properties.galleryDescription}</p>
                    </div>
                    <div className="col dpc-col-lg-76 dpc-brd-down-lg-t-1-primary-variant ps-lg-3 pt-4 pt-lg-0 mt-lg-n011pc">
                    <Swiper
                        modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
                        slidesPerView={1}
                        spaceBetween={30}
                        className="dpc-nominees-slider dpc-min-h-200px"
                        autoplay={{
                            delay: 4000
                        }}
                        centeredSlides = {true}
                        roundLengths = {true}
                        loop = {true}
                        loopAdditionalSlides = {10}
                        navigation={true}
                        onSlideChange={(swiper) => {setActiveGalleryImage(swiper.realIndex + 1); }}
                        breakpoints={{
                            1: {
                            slidesPerView: 1.21,
                            spaceBetween: -14,
                            },
                            576: {
                            slidesPerView: 1
                            },
                            768: {
                                //slidesPerView: 1.1,
                                //spaceBetween: 40,
                            },
                            992: {
                                //slidesPerView: 1,
                                //spaceBetween: 40,
                            },
                        }}
                        >
                        {gallery && gallery.map((image) => (
                        <SwiperSlide key={image.id}>
                                
                            <div className="dpc-nominees-content">
                                <img src={configData.REACT_URL + image.url} className="img-fluid" alt="..."/>
                            </div>  

                        </SwiperSlide>
                    ))} 
                    </Swiper>

                    <div className="dpc-nominees-pager mt-2">
                        <span className="dpc-active">{activeGalleryImage}</span> - {gallery.length}   
                    </div>
                    
                    </div>
                </div>
            </div>
            </section>
        </>
    )}
    </>
    );
};

export default OurNomineesGalleryComponent;