import axios from "axios";
import { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import configData from '../conf.json';
import { getAliasValue, getConferenceAliasValue, getConferenceValue, getImagePath } from '../dataManager/findAliasValue';
import LastYearsCampaignReview from './LastYearsCampaignReview';

const $ = window.$;

const ActualComponent = ({page, newsList, getRssFeedDataDone, isActualConfAndYear, setIsActualConfAndYear}) => {

  const [done, setDone] = useState(false);

  var activePageEntrepreneurStars = JSON.parse(localStorage.getItem("activePageEntrepreneurStars"));
  var starsYear = activePageEntrepreneurStars.year;
  var year = activePageEntrepreneurStars.entrepreneurStars;
  var newsList = JSON.parse(localStorage.getItem("activeConfNews"));

  if(page !== "Stars") {
    var activePageCampaign = JSON.parse(localStorage.getItem("activePageCampaignAndYear"));
    var activeConference = activePageCampaign.campaign[0];
    var conferenceYear = activePageCampaign.year;
  }

  var conferenceList = JSON.parse(localStorage.getItem("conferences"));

  var activeconferenceList = [];
  var activeConferenceAndYearList = [];
  useEffect(() => {

    var result;
    if(conferenceList.length !== 0) {
        conferenceList.map(conf => {
            conf.children.map(confYear => {
                var isActive = confYear.properties.find(({ alias }) => alias === "conferenceIsActual");
                if(isActive.value.value === true) {
                    result = confYear;
                    if(result != null) {
                      if(!activeconferenceList.includes(conf)) {
                        // activeconferenceList.push(conf);
                        const url = configData.REACT_URL + "/umbraco/delivery/api/v1/content/item/" + result.url;
                        return axios.get(url, {
                          headers: {
                            accept: 'application/json'
                          }
                        }).then((response) => {

                          if(response.data.properties.startEndDate !== null) {

                            // Current date
                            let newDate = new Date().toISOString().slice(0, 10);

                            // Check if conference start date is in the past or today
                            if(newDate >= response.data.properties.startEndDate.slice(0, 10)) {

                              if(response.data.properties.endDate !== null) {

                                // Current date
                                let newDate = new Date().toISOString().slice(0, 10);
    
                                // Check if conference and date is not in the past
                                if(newDate < response.data.properties.endDate.slice(0, 10)) {
                                  activeconferenceList.push(conf);
                                  activeConferenceAndYearList.push({conference: conf, year: confYear});
                                  localStorage.setItem("actualConferenceList", JSON.stringify(activeconferenceList));
                                  localStorage.setItem("activeConferenceAndYearList", JSON.stringify(activeConferenceAndYearList));

                                  findActual(activeConferenceAndYearList);
                                  setDone(true);
                                }
                              }
                              else {
                                activeconferenceList.push(conf);
                                activeConferenceAndYearList.push({conference: conf, year: confYear});
                                localStorage.setItem("actualConferenceList", JSON.stringify(activeconferenceList));
                                localStorage.setItem("activeConferenceAndYearList", JSON.stringify(activeConferenceAndYearList));

                                findActual(activeConferenceAndYearList);
                                setDone(true);
                              }
                            }
                            
                          } else if(response.data.properties.endDate !== null) {

                            // Current date
                            let newDate = new Date().toISOString().slice(0, 10);

                            // Check if conference and date is not in the past
                            if(newDate < response.data.properties.endDate.slice(0, 10)) {
                              activeconferenceList.push(conf);
                              activeConferenceAndYearList.push({conference: conf, year: confYear});
                              localStorage.setItem("actualConferenceList", JSON.stringify(activeconferenceList));
                              localStorage.setItem("activeConferenceAndYearList", JSON.stringify(activeConferenceAndYearList));

                              findActual(activeConferenceAndYearList);
                              setDone(true);
                            }
                          }
                          else {
                            activeconferenceList.push(conf);
                            activeConferenceAndYearList.push({conference: conf, year: confYear});
                            localStorage.setItem("actualConferenceList", JSON.stringify(activeconferenceList));
                            localStorage.setItem("activeConferenceAndYearList", JSON.stringify(activeConferenceAndYearList));

                            findActual(activeConferenceAndYearList);
                            setDone(true);
                          }
                        });
                      }
                    }
                }
                
            });
        });
    }
  });

  useEffect(() => {
      setIsActualConfAndYear(isActualConfAndYear);
  }, [isActualConfAndYear]);


  const openAdditionalInfo = () => { 
      $('.dpc-bg-image-holder').toggleClass('dpc-show');
  };

  const findActual = (list) => {
    
    var active = JSON.parse(localStorage.getItem("activePageCampaignAndYear"));
    list.forEach(element => {
      if((element.conference.name === active.campaign[0].name) && (element.year.name === active.year)) {
        setIsActualConfAndYear(true);
        return;
      }
    });
  };

  const handleScroll = (event) => {
    if(event.target.scrollTop !== 0) {
      $('.dpc-thin-scroll').addClass('dpc-tb-soft-shad');
    }
    else {
      $('.dpc-thin-scroll').removeClass('dpc-tb-soft-shad');
    }
  };

    return (
      <>
          {getConferenceAliasValue(activeConference.name, "rssFeedUrl") !== '' && 
           conferenceYear >= new Date().getFullYear() && (<>
            {done && isActualConfAndYear ? (
            <>
              <section id="entrepreneurActual" className="dpc-section dpc-section-entrepreneur-actual">
                <div className="container-fluid">
                  <h2 className="dpc-brd-b-gray mb-lg-4">{getAliasValue("homePage", "actualTitle")}</h2>
                    <div className="row dpc-col-lg-auto-46-fl-p0">
                      <div className="col pb-4 pb-lg-0 ps-lg-0 pe-lg-45 order-2 order-lg-0">
                        <div className="dpc-bg-image-holder" style={{"backgroundImage": `url(${getImagePath(getConferenceValue(activeConference.name, conferenceYear, "actualConferenceImage"))})`}} title="DPC Actual Image">
                          <img src={getImagePath(getConferenceValue(activeConference.name, conferenceYear, "actualConferenceImage"))} className="img-fluid" alt="..."/>
                          <div className="dpc-img-hover-info">
                            <div className="dpc-info">
                              {getConferenceValue(activeConference.name, conferenceYear, "actualConferenceText")}
                            </div>
                            <button type="button" className="btn btn-transparent-white dpc-btn-info-back" onClick={() => {openAdditionalInfo();}}>Nazaj</button>
                          </div>
                          <button type="button" className="btn btn-transparent-white dpc-btn-info-see-more" onClick={() => {openAdditionalInfo();}}>Poglej več</button>
                        </div>
                      </div>
                      <div className="col dpc-brd-lg-l dpc-col-lg-46 order-4 ps-lg-45 order-lg-0 dpc-thin-scroll" onScroll={handleScroll}>
                        {getRssFeedDataDone ? (
                            <ul className="nav flex-column dpc-v-links dpc-hov-underline">
                                {/* {newsList && newsList.slice(0, 5).map((news) => ( */}
                                  {newsList && newsList.map((news) => (
                                        <li className="nav-item">
                                          <a className="nav-link" href={news.link} target="_blank">
                                            {/* <span className="dpc-bg-image-holder" style={{"backgroundImage": `url(${news.imageUrl})`}} title="DPC Actual Image"></span> */}
                                            <span className="dpc-bg-image-holder dpc-item-image-holder" title="...">
                                              <img src={news.imageUrl} alt="..." loading="lazy" />
                                            </span>
                                            <span className="dpc-text">{news.title}</span> <svg className="dpc-svg-icon"><use xlinkHref="#arrow-right"/></svg>
                                          </a>
                                        </li>
                                ))}
                            </ul>  
                          ): (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
                              <Spinner animation="border" />
                            </div>
                          )}
                    </div>
                    </div>
                </div>
              </section>
            </>
          ) : ( (conferenceYear !== new Date().getFullYear().toString()) && 
              <LastYearsCampaignReview activeConference={activeConference} conferenceYear={conferenceYear}/>
          )
          }
        </>)}
          
        </>
    );
}

export default ActualComponent;