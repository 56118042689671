import configData from '../conf.json';
import axios from "axios";

export function getAliasValue(page, desiredAlias) {

    var dataPages = JSON.parse(localStorage.getItem("pagesData"));

    // Find data for desired page and alias
    var filteredPageData = dataPages.nodes.filter(node => node.contentType.alias === page);
    if(!filteredPageData[0]) {
        return "Can't find page..."
    }
    var alias = filteredPageData[0].properties.find(({ alias }) => alias === desiredAlias);

    if(!alias) {
        return "Can't find data..."
    }
    else {
        var aliasValue = alias.value.value;
    }

    return aliasValue;
}

export function getIncomingConferenceValue(desiredAlias) {

    var incomingConference = JSON.parse(localStorage.getItem("incomingConference"));

    
    // var name = (incomingConference.properties.find(({ alias }) => alias === "conferenceTitle")).value.value;
    // var year = incomingConference.name;

    // var resultValue = getConferenceValue(name, year, desiredAlias);
    var resultValue = (incomingConference.properties.find(({ alias }) => alias === desiredAlias)).value.value;
    
    return resultValue;
}

export function getConferenceAliasValue(conference, desiredAlias) {

    var conferenceList = JSON.parse(localStorage.getItem("conferences"));

    // Find data for desired conference
    var filteredPageData = conferenceList.filter(conf => conf.name === conference);

    if(filteredPageData.length === 0) {
        return "Can't find conference...";
    }

    var alias = filteredPageData[0].properties.find(({ alias }) => alias === desiredAlias);

    if(!alias) {
        // return "Can't find conference's data..."
        return "";
    }
    else {
        var aliasValue = alias.value.value;
    }

    return aliasValue;
}

export function getConferenceValue(conference, conferenceYear, desiredAlias) {

    var conferenceList = JSON.parse(localStorage.getItem("conferences"));
    // Find data for desired conference
    var filteredPageData = conferenceList.filter(conf => conf.name === conference);

    if(filteredPageData.length === 0) {
        return "Can't find conference...";
    }

    //Find desired conference year
    var yearConference = filteredPageData[0].children.filter(year => year.name.includes(conferenceYear));

    if(yearConference.length === 0) {
        return "Can't find conference year...";
    }

    var alias = yearConference[0].properties.find(({ alias }) => alias === desiredAlias);

    if(!alias) {
        // return "Can't find conference's data..."
        return "";
    }
    else {
        var aliasValue = alias.value.value;
    }

    return aliasValue;
}

export function getActiveConferenceNews(activeConference, conferenceYear) {

    var conferenceList = JSON.parse(localStorage.getItem("conferences"));

    // // Find data for desired conference
    var filteredPageData = conferenceList.filter(conf => conf.name === activeConference.name);

    if(filteredPageData.length === 0) {
        return null;
    }

    // Find desired conference year
    var yearConference = filteredPageData[0].children.filter(year => year.name === conferenceYear);

    if(yearConference.length === 0) {
        return null;
    }

    var news = yearConference[0].children.filter(child => (child.contentType.alias !== "program" && child.contentType.alias !== "gallery"));

    return news;
}

export function getStarseNews(year) {

    var conferenceList = JSON.parse(localStorage.getItem("entrepreneurStars"));
    var filteredPageData = conferenceList.filter(star => star.name === year);
    if(filteredPageData.length === 0) {
        return null;
    }
    
    var news = filteredPageData[0].children.filter(child => (child.contentType.alias !== "gallery"));

    return news;
}

async function getIncomingDateFromAPI(url) {
    try {
      const response = await axios.get(url);
      
      if(response.data.properties.startEndDate)
        return response.data.properties.startEndDate;
      else
        return null;
    } catch (error) {
        console.error(`Error fetching date from ${url}: ${error.message}`);
      return null;
    }
  }

function findNearestDate(items) {

    const currentDate = new Date();

    let minTimeDifference = Infinity;
    let nearestItem = null;

    items.forEach(item => {
        const itemDate = new Date(item.incomingDate);

        // Skip items with dates in the past
        if (itemDate <= currentDate) {
            return;
        }

        // Calculate the time difference in milliseconds
        const timeDifference = Math.abs(itemDate - currentDate);

        if (timeDifference < minTimeDifference) {
            minTimeDifference = timeDifference;
            nearestItem = item;
        }
    });

    return nearestItem;
}

export async function getIncomingConference() {

    var conferenceList = JSON.parse(localStorage.getItem("conferences"));
    var listOfIncomingConferences = [];
    var listOfIncomingConferencesWithCOnfNames = [];

    var result;
    if(conferenceList.length !== 0) {
        conferenceList.map(conf => {
            conf.children.map(confYear => {
                var isActive = confYear.properties.find(({ alias }) => alias === "conferenceIsIncomming");
                if(isActive.value.value === true) {
                    result = confYear;
                    listOfIncomingConferences.push(result);
                    listOfIncomingConferencesWithCOnfNames.push({conference: conf, incoming: result});
                }
            });
        });
    }

    const dates = [];
    
    // Find incoming conference with the nearest date
    for (const incoming of listOfIncomingConferences) {
        const date = await getIncomingDateFromAPI(configData.REACT_URL 
                                                  + "/umbraco/delivery/api/v1/content/item" 
                                                  + incoming.url);
        if (date) {
            dates.push({incomingConf: incoming, incomingDate: date});
        }
    }

    var incoming;
    if(dates.length !== 0) {
        var incomingConfObject = findNearestDate(dates);
        if(!incomingConfObject){
            return null;
        }
        else 
            incoming = incomingConfObject.incomingConf;
    }
    else if(dates.length === 1) {
        incoming = listOfIncomingConferences[0];
    }
    else if(listOfIncomingConferences !== 0){
        incoming = listOfIncomingConferences[0];
    }
    else {
        incoming = null;
    }
    
    const foundElementIncomingConference = listOfIncomingConferencesWithCOnfNames.find(obj => obj.incoming === incoming);
    localStorage.setItem("incomingConferenceWithConfNameAndObject", JSON.stringify(foundElementIncomingConference));
    localStorage.setItem("incomingConference", JSON.stringify(incoming));
    return result;
}

export function getIncomingCampaign()  {
    var conferenceList = JSON.parse(localStorage.getItem("conferences"));

    var result;
    if(conferenceList.length !== 0) {
        conferenceList.map(conf => {
            conf.children.map(confYear => {
                var isActive = confYear.properties.find(({ alias }) => alias === "conferenceIsIncomming");
                if(isActive.value.value === true) {
                    result = conf;
                }
            });
        });
    }

    return result;
}


export function getValueFromArrayElement(arrayElement, desiredAlias) {

    if(arrayElement){
        var value = (arrayElement.properties.find(({ alias }) => alias === desiredAlias)).value.value;
        return value;
    }
    else{
        return "";
    }
}

export function getValueFromArrayElementRichText(arrayElement, desiredAlias) {

    if(arrayElement){
        var value = (arrayElement.properties.find(({ alias }) => alias === desiredAlias)).value.value2;
        return value;
    }
    else{
        return "";
    }
}

export function getValueFromArrayElementOurTeam(arrayElement, desiredAlias) {

    if(arrayElement){
        var elem = (arrayElement.properties.find((elem) => elem.alias === desiredAlias));
        if(elem) {
            return elem.value.value;
        }
        else{
            return "";
        }
    }
    else {
        return "";
    }
}

export function getProgram(activeConference, conferenceYear) {

    var conferenceList = JSON.parse(localStorage.getItem("conferences"));

    // Find data for desired conference
    var filteredPageData = conferenceList.filter(conf => conf.name === activeConference.name);

    if(filteredPageData.length === 0) {
        return null;
    }

    // Find desired conference year
    var yearConference = filteredPageData[0].children.filter(year => year.name.includes(conferenceYear));

    if(yearConference.length === 0) {
        return null;
    }

    var program = yearConference[0].children.filter(child => child.contentType.alias === "program");
    if(program)
        localStorage.setItem("program", JSON.stringify(program));
    else 
        localStorage.setItem("program", JSON.stringify([]));
    return program;
}

export function getProgramEvents() {

    var program = JSON.parse(localStorage.getItem("program"));
    if(program.length !== 0){
        if(program[0].children.length > 0) {
        var programEvents = program[0].children.filter(child => child.contentType.alias !== "panelList");

        localStorage.setItem("programEvents", JSON.stringify(programEvents));
        return programEvents;
        }
        else 
            return [];
    } else {
        return [];
    }
    
    
}

export function getProgramPanels() {

    var program = JSON.parse(localStorage.getItem("program"));
    if(program.length !== 0){
        if(program[0].children.length > 0) {
            var programPanels = program[0].children.filter(child => child.contentType.alias === "panelList");

            if(programPanels.length > 0) {
                var panels = programPanels[0].children;
                localStorage.setItem("programPanels", JSON.stringify(panels));
                return panels;
            }
            else {
                return [];
            }
        }else 
            return [];
    }else {
        return [];
    }
}

//PROVERI
export function getProgramPanelsEvents(desiredPanel) {

    var panels = JSON.parse(localStorage.getItem("programPanels"));
    var panel = panels.filter(child => child.name !== desiredPanel);

    var events = panel.children;
    return events;
}

export function getList(page) {

    var dataPages = JSON.parse(localStorage.getItem("pagesData"));

    // Find data for desired page and alias
    var resultList = dataPages.nodes.filter(node => node.contentType.alias === page);

    var localStorageAlias;
    if(page === "aboutUsPage"){
        localStorageAlias = "valuesAboutUs";
    }
    else {
        localStorageAlias = page;
    }

    localStorage.setItem(localStorageAlias, JSON.stringify(resultList[0].children));

    return resultList;
}

export function getImagePath(imgValue) {

    if(imgValue)
        // return "http://localhost:49988" + imgValue.src;
        return configData.UMBRACO_SERVER_URL + imgValue.src;
    else 
        return "";
}

export function getImagePathDeliveryApi(imgValue) {

    if(imgValue)
        // return "http://localhost:49988" + imgValue.src;
        return configData.UMBRACO_SERVER_URL + imgValue;
    else 
        return "";
}

export function getOurTeam() {

    var dataPages = JSON.parse(localStorage.getItem("pagesData"));

    var filteredPageData = dataPages.nodes.filter(node => node.contentType.alias === "aboutUsPage");

    var ourTeam;
    if(filteredPageData[0].children.filter(node => node.contentType.alias === "ourTeam").length !== 0) {
        ourTeam = filteredPageData[0].children.filter(node => node.contentType.alias === "ourTeam")[0].children;
    }
    
    // localStorage.setItem("ourTeam", JSON.stringify(ourTeam));
    if(!ourTeam || ourTeam === null) {
        localStorage.setItem("ourTeam", null);
        return null;
    }
    else if(ourTeam.length === 0) {
        localStorage.setItem("ourTeam", null);
        return null;
    }
    else {
        localStorage.setItem("ourTeam", JSON.stringify(ourTeam));
        return ourTeam;
    }
        
}

