import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getAliasValue, getValueFromArrayElement } from '../dataManager/findAliasValue';
import CampaignPageHeader from "./CampaignPageHeader";
import CommisionComponent from "./CommissionComponent";
import ConstellationComponent from "./ConstellationComponent";
import DownloadDocsComponent from "./DownloadsDocsComponent";
import FooterComponent from "./FooterComponent";
import LastEventsEntrereneurStars from "./LastEventsEntrereneurStars";
import OurNomineesGalleryComponent from "./OurNomineesGalleryComponent";
import OurSponsorsComponent from "./OurSponsorsComponent";

import { Helmet } from "react-helmet";
import { getRssFeedNews } from "../dataManager/rssFeedNewsDataManager";
import EntrepreneurStarsActualComponent from "./EntrepreneurStarsActualComponent";
import VideoGalleryComponent from "./VideoGalleryComponent";
import SvgIconsComponent from "./SvgIconsComponent";

const EntrepreneurStarsComponent = ({shouldLoad}) => {

    const [urlLoaded, setUrlLoaded] = useState(false);
    const [starYear, setStarYear] = useState();
    const [activeNewsList, setActiveNewsList] = useState();
    const [getRssFeedDataDone, setGetRssFeedDataDone] = useState(false);

    const location = useLocation();
    let {year} = useParams();

    let navigate = useNavigate(); 
    const navigateTo = (path) =>{ 
        navigate(path);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Podjetniške zvezde";
    }, []);

    useEffect(() => {
        setActiveNewsList(activeNewsList);
        setGetRssFeedDataDone(true);
    }, [activeNewsList]);

    useEffect(() => {
        if(shouldLoad){
            var dataList = JSON.parse(localStorage.getItem("entrepreneurStars"));
            var dataStars = dataList.find(y => y.name.includes(year));
            // When stars year doesn't exist, redirect to home page
            if(!dataStars){
                localStorage.setItem("activePageEntrepreneurStars", JSON.stringify({entrepreneurStars: dataList[0], year: new Date().getFullYear().toString()}));
                navigateTo('/');
            } else {
                localStorage.setItem("activePageEntrepreneurStars", JSON.stringify({entrepreneurStars: dataStars, year: year}));
                setStarYear(dataStars);
                fetchNews();
                setUrlLoaded(true);
            }
            
        }
        window.scrollTo(0, 0);
        
    }, [location]);

    useEffect(() => {

        if(shouldLoad){
            var dataList = JSON.parse(localStorage.getItem("entrepreneurStars"));
            var dataStars = dataList.find(y => y.name.includes(year));
            // When stars year doesn't exist, redirect to home page
            if(!dataStars) {
                localStorage.setItem("activePageEntrepreneurStars", JSON.stringify({entrepreneurStars: dataList[0], year: new Date().getFullYear().toString()}));
                navigateTo('/');
            }
            else {
                localStorage.setItem("activePageEntrepreneurStars", JSON.stringify({entrepreneurStars: dataStars, year: year}));
                setStarYear(dataStars);
                fetchNews();
                setUrlLoaded(true);
            }
            
        }
        
    }, [shouldLoad]);

    const fetchNews = async () => {

        setGetRssFeedDataDone(false);
        var newsList = await getRssFeedNews(getAliasValue("entrepreneurStars", "rssFeedUrl"));
        setActiveNewsList(JSON.parse(localStorage.getItem("activeConfNews")));
    }

    return (
        <> 
            {shouldLoad && urlLoaded && (
        <>
            <SvgIconsComponent/>
            
            <CampaignPageHeader page="Stars"/>

            <Helmet>
                <meta name="description" content={getValueFromArrayElement(starYear, "registerDescription")} />
                <meta property="og:title" content={getValueFromArrayElement(starYear, "title")} />
                <meta property="og:description" content={getValueFromArrayElement(starYear, "registerDescription")} />
            </Helmet>

            <main id="main" className="dpc-main fade-in-1s">

                <h1 className="dpc-visually-sleep">DPC</h1>

            {/* <!-- Section Register For Conference" --> */}
            <section id="registerForConference" className="dpc-section dpc-section-register-for-confrence">
                <div className="container-fluid">
                <div className="row dpc-counter pb-3 mb-3 mb-lg-5">
                    <div className="col-6 col-lg-3 px-0 pe-lg-1 pb-3">
                    <span className="h2 d-block text-nowrap">{getValueFromArrayElement(starYear, "achievementNumber1")}</span>
                    <span className="dpc-text">{getValueFromArrayElement(starYear, "achievement1")}</span>
                    </div>
                    <div className="col-6 col-lg-3 px-0 ps-lg-1 pb-3">
                    <span className="h2 d-block text-nowrap">{getValueFromArrayElement(starYear, "achievementNumber2")}</span>
                    <span className="dpc-text">{getValueFromArrayElement(starYear, "achievement2")}</span>
                    </div>
                    <div className="col-6 col-lg-3 px-0 pb-3">
                    <span className="h2 d-block text-nowrap">{getValueFromArrayElement(starYear, "achievementNumber3")}</span>
                    <span className="dpc-text">{getValueFromArrayElement(starYear, "achievement3")}</span>
                    </div>
                    <div className="col-6 col-lg-3 px-0 pb-3">
                    <span className="h2 d-block text-nowrap">{getValueFromArrayElement(starYear, "achievementNumber4")}</span>
                    <span className="dpc-text">{getValueFromArrayElement(starYear, "achievement4")}</span>
                    </div>
                </div>
                    <div className="row dpc-col-lg-auto-30-fl-p0 pt-2 pt-sm-3 pt-lg-4">
                    <div className="col dpc-col-lg-30 dpc-brd-lg-r mt-4 mt-lg-0">
                        <div className="d-flex flex-column flex-sm-row flex-lg-column align-items-baseline align-items-sm-start align-items-lg-baseline gap-3 column-gap-22">
                        {/* <button type="button" className="btn btn-primary dpc-accent-hover text-wrap text-start" onClick={() => {openRegisterForConferenceMenu();}}>prijavi se za konferenco</button> */}
                        { getValueFromArrayElement(starYear, "isActiveLiveStreamStars") === true && 
                          getValueFromArrayElement(starYear, "liveStreamStarsLink") !== "" &&
                          getValueFromArrayElement(starYear, "liveStreamStarsTitle") !== "" && 
                          <a target="_blank" type="button" className="btn btn-outline-primary" href={getValueFromArrayElement(starYear, "liveStreamStarsLink")}>{getValueFromArrayElement(starYear, "liveStreamStarsTitle")}</a>
                        }
                        <button type="button" className="btn btn-primary dpc-accent-hover text-wrap text-start" onClick={() => {navigateTo('/kontakt/prijava');}}>prijavi se za konferenco</button>
                        {/* <button type="button" className="btn btn-outline-primary" onClick={() => {openContactMenu();}}>{getAliasValue("homePage", "becomeASponsor")}</button> */}
                        <button type="button" className="btn btn-outline-primary" onClick={() => {navigateTo('/kontakt/postanite-sponzor')}}>{getAliasValue("homePage", "becomeASponsor")}</button>
                        </div>
                    </div>
                    <div className="col ps-lg-3 order-first order-lg-0">
                        <h4 className="pe-lg-20pc">{getValueFromArrayElement(starYear, "registerTitle")}</h4>
                        <p className="mb-1 pe-lg-20pc dpc-text-22-sm-19">{getValueFromArrayElement(starYear, "registerDescription")}</p>
                    </div>
                    </div>
                </div>
            </section>

            <OurNomineesGalleryComponent/>

            {/* <ActualComponent page="Stars" newsList={activeNewsList} getRssFeedDataDone={getRssFeedDataDone}/> */}
            <EntrepreneurStarsActualComponent newsList={activeNewsList} getRssFeedDataDone={getRssFeedDataDone}/>

            <CommisionComponent/>

            <ConstellationComponent/>

            <VideoGalleryComponent page="Stars"/>

            <LastEventsEntrereneurStars />

            <OurSponsorsComponent page="Stars"/>

            <DownloadDocsComponent page="Stars"/>



            </main>

                <FooterComponent/>
        </>
        )}
    </>
    );

};

export default EntrepreneurStarsComponent;