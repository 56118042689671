import { getImagePath } from '../dataManager/findAliasValue';
import { getSponsors, getSponsorsProperty, getSponsorsStars, getSponsorValue } from '../helperFunctions/getSponsors';

const OurSponsorsComponent = ({page}) => {

    //Campaign
    if(page !== 'Stars') {
        var activeCamp = JSON.parse(localStorage.getItem("activePageCampaignAndYear")).campaign[0];
        var activeY = JSON.parse(localStorage.getItem("activePageCampaignAndYear")).year;
        getSponsors(activeCamp, activeY);
    }
    //Stars
    else {
        var starsActivePage = JSON.parse(localStorage.getItem("activePageEntrepreneurStars")).entrepreneurStars;
        var starsYear = JSON.parse(localStorage.getItem("activePageEntrepreneurStars")).year;
        getSponsorsStars(starsActivePage);
    }

    var sponsors = JSON.parse(localStorage.getItem("campaignSponsors"));
    var sponsorsImagesList = null;
    if(sponsors && sponsors.length > 0) {
        sponsorsImagesList = sponsors[0].children;
    }

    return (
        <>
            { sponsors &&  sponsorsImagesList && 
            ((page === "Stars" && starsYear === new Date().getFullYear().toString()) || (page === "Campaign" && activeY === new Date().getFullYear().toString())) &&
            (
                <>
                    {/* <!-- Section Our Sponsors --> */}
                    <section id="ourSponsors" className="dpc-section dpc-section-our-sponsors">
                    <div className="container-fluid">

                        {getImagePath(getSponsorsProperty(sponsors, "mainSponsorImage")) ? 
                            (<>
                                <h2 className="mb-lg-4 dpc-brd-b-1-primary-variant dpc-sponsors-title dpc-has-title-image">
                                    <span className="dpc-text me-sm-3 me-md-4">{getSponsorsProperty(sponsors, "sponsorsTitle")}</span> 
                                    { getImagePath(getSponsorsProperty(sponsors, "mainSponsorImage")) &&
                                        <div className="dpc-main-sponsor-image">
                                            <span className="dpc-bg-image-holder" style={{"backgroundImage": `url(${getImagePath(getSponsorsProperty(sponsors, "mainSponsorImage"))})`}} title="...">
                                                <img src={getImagePath(getSponsorsProperty(sponsors, "mainSponsorImage"))} className="img-fluid" alt="..." />
                                            </span>
                                        <div className="dpc-main-sponsor-title">{getSponsorsProperty(sponsors, "mainSponsorTitle")}</div>
                                        </div>
                                    }
                                </h2>
                            </>)
                            :
                            (<>
                                <h2 className="mb-lg-4 dpc-brd-b-1-primary-variant dpc-sponsors-title">
                                    <span className="dpc-text me-sm-3 me-md-4">{getSponsorsProperty(sponsors, "sponsorsTitle")}</span> 
                                    { getImagePath(getSponsorsProperty(sponsors, "mainSponsorImage")) &&
                                        <div className="dpc-main-sponsor-image">
                                            <span className="dpc-bg-image-holder" style={{"backgroundImage": `url(${getImagePath(getSponsorsProperty(sponsors, "mainSponsorImage"))})`}} title="...">
                                                <img src={getImagePath(getSponsorsProperty(sponsors, "mainSponsorImage"))} className="img-fluid" alt="..." />
                                            </span>
                                        <div className="dpc-main-sponsor-title">{getSponsorsProperty(sponsors, "mainSponsorTitle")}</div>
                                        </div>
                                    }
                                </h2>
                            </>)
                        }

                        <div className="dpc-our-sponsors-grid">

                            {sponsorsImagesList && sponsorsImagesList.map((sponsor) => (
                                <div className="dpc-sponsor-item">
                                    <span className="dpc-sponsor-item-title">{getSponsorValue(sponsor, "sponsorTitle")}</span>
                                <div className="dpc-bg-image-holder" style={{"backgroundImage": `url(${getImagePath(getSponsorValue(sponsor, "sponsorLogo"))})`}} title="...">
                                    <img src={getImagePath(getSponsorValue(sponsor, "sponsorLogo"))} className="img-fluid" alt="..."/>
                                </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    </section>
                </>
            )}
            </>
    );
};

export default OurSponsorsComponent;