import { getCampaignGallery } from '../dataManager/getGalleryImages';

// import Swiper core and required modules
import { A11y, Autoplay, Navigation, Pagination, Scrollbar } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';
import configData from '../conf.json';

// Import Swiper styles
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom/dist";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { getCorrectUrl } from "../dataManager/getCorrectUrlString";

const CampaignGalleryComponent = () => {

    let {campaign, year} = useParams();
    const [activeGalleryImage, setActiveGalleryImage] = useState(0);
    const [gallery, setGallery] = useState([]);
    const [galleryInfo, setGalleryInfo] = useState();

    
    var galleryTemp = [];
    var galerryProperties;
    var activeGalleryInfo;

    useEffect(() => {

        const galleryElement = document.getElementById('gallery');
        if (galleryElement && JSON.parse(localStorage.getItem("linkGallery"))) {

            // if (galleryElement ) {
            galleryElement.scrollIntoView({ behavior: 'smooth' });
            localStorage.setItem("linkGallery", JSON.stringify(false));
        }
        else {
            window.scrollTo(0,0);
        }
    }, [gallery]);

    useEffect(() => {

        var conferenceList = JSON.parse(localStorage.getItem("conferences"));
        var desiredConf = conferenceList.filter(conf => conf.url.toString() === ('/poslovna-kampanja/' + campaign + '/'))[0];
        activeGalleryInfo = getCampaignGallery(desiredConf, year);

        if(activeGalleryInfo.length !== 0) {
            var activeGalleryUrl = configData.REACT_URL + "/umbraco/delivery/api/v1/content/item/" 
                + getCorrectUrl(desiredConf.name)
                + '/' + getCorrectUrl((desiredConf.children.filter(elem => elem.name.includes(year)))[0].name)
                +  '/' + getCorrectUrl(activeGalleryInfo[0].name);
            
                if(activeGalleryUrl) {
                    axios.get(activeGalleryUrl, {
                        headers: {
                        accept: 'application/json'
                        }
                    }).then((response) => { 
                        galerryProperties = response.data;
                        galleryTemp = response.data.properties.galleryImages;

                        setGallery(galleryTemp);
                        setGalleryInfo(galerryProperties);
                    });
                }
        }
        else {
            setGallery([]);
            setGalleryInfo();
        }

    }, [year]);

    useEffect(() => {

        var conferenceList = JSON.parse(localStorage.getItem("conferences"));
        var desiredConf = conferenceList.filter(conf => conf.url.toString() === ('/poslovna-kampanja/' + campaign + '/'))[0];
        activeGalleryInfo = getCampaignGallery(desiredConf, year);

        if(activeGalleryInfo.length !== 0) {
            var activeGalleryUrl = configData.REACT_URL + "/umbraco/delivery/api/v1/content/item/" 
                + getCorrectUrl(desiredConf.name)
                + '/' + getCorrectUrl((desiredConf.children.filter(elem => elem.name.includes(year)))[0].name)
                +  '/' + getCorrectUrl(activeGalleryInfo[0].name);
            
                if(activeGalleryUrl) {
                    axios.get(activeGalleryUrl, {
                        headers: {
                        accept: 'application/json'
                        }
                    }).then((response) => { 
                        galerryProperties = response.data;
                        galleryTemp = response.data.properties.galleryImages;

                        setGallery(galleryTemp);
                        setGalleryInfo(galerryProperties);
                    });
                }
        }
        else {
            setGallery([]);
            setGalleryInfo();
        }

    }, [campaign]);

    useEffect(() => {
        
        var activeConference = JSON.parse(localStorage.getItem("activePageCampaignAndYear"));
        activeGalleryInfo = getCampaignGallery(activeConference.campaign[0], activeConference.year);
        if(activeGalleryInfo.length !== 0) {
            var activeGalleryUrl = configData.REACT_URL + "/umbraco/delivery/api/v1/content/item/" 
                + getCorrectUrl(activeConference.campaign[0].name)
                + '/' + getCorrectUrl((activeConference.campaign[0].children.filter(elem => elem.name.includes(activeConference.year)))[0].name)
                +  '/' + getCorrectUrl(activeGalleryInfo[0].name);
            
            
                if(activeGalleryUrl) {
                    axios.get(activeGalleryUrl, {
                        headers: {
                        accept: 'application/json'
                        }
                    }).then((response) => { 
                        galerryProperties = response.data;
                        galleryTemp = response.data.properties.galleryImages;

                        setGallery(galleryTemp);
                        setGalleryInfo(galerryProperties);
                    });
                }
        }
        else {
            setGallery([]);
            setGalleryInfo();
        }
    }, []);

    return (
        <>
            { gallery && galleryInfo && (
                <>
                
                {/* <!-- Section Gallery --> */}
                    <section id="gallery" className="dpc-section dpc-section-gallery">
                    <div className="container-fluid">
                        <h2 className="dpc-brd-b-gray">{galleryInfo.properties.galleryTitle}</h2>
                        {/* <h2 className="dpc-brd-b-gray">titlee</h2> */}
                        <div className="row dpc-col-lg-auto-24-fl-p0 mb-2 mb-sm-3 mb-lg-4">
                            <div className="col col-sm-8 dpc-col-lg-24 dpc-brd-lg-r dpc-text-22-sm-19">
                            <p>{galleryInfo.properties.galleryDescription}</p>
                            {/* <p>descrrr</p> */}
                            </div>
                            <div className="col dpc-col-lg-76 dpc-brd-down-lg-t-1-primary-variant pt-4 pt-lg-0 mt-lg-n011pc">
                            <Swiper
                                modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
                                slidesPerView={1}
                                spaceBetween={30}
                                className="dpc-nominees-slider dpc-min-h-200px"
                                autoplay={{
                                    delay: 4000
                                  }}
                                centeredSlides = {true}
                                roundLengths = {true}
                                loop = {true}
                                loopAdditionalSlides = {10}
                                navigation={true}
                                onSlideChange={(swiper) => {setActiveGalleryImage(swiper.realIndex + 1); }}
                                breakpoints={{
                                    1: {
                                    slidesPerView: 1.21,
                                    spaceBetween: -14,
                                    },
                                    576: {
                                    slidesPerView: 1
                                    },
                                    768: {
                                        //slidesPerView: 1.1,
                                        //spaceBetween: 40,
                                    },
                                    992: {
                                        //slidesPerView: 1,
                                        //spaceBetween: 40,
                                    },
                                }}
                                >
                                {gallery && gallery.map((image) => (
                                    <SwiperSlide key={image.id}>
                                            
                                        <div className="dpc-nominees-content">
                                            <img src={configData.REACT_URL + image.url} className="img-fluid" alt="..."/>
                                        </div>  

                                    </SwiperSlide>
                                ))} 
                            </Swiper>

                            <div className="dpc-nominees-pager mt-2">
                                <span className="dpc-active">{activeGalleryImage}</span> - {gallery.length}             
                            </div>
                            
                            </div>
                        </div>
                    </div>
                    </section>
                </> 
            )}
        </>

    );
};

export default CampaignGalleryComponent;