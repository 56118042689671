import { useLocation, useNavigate, useParams } from "react-router-dom";
import FooterComponent from "./FooterComponent"
import HeaderNavComponent from "./HeaderNavComponent";
import { getValueFromArrayElement, getImagePath, getValueFromArrayElementRichText } from '../dataManager/findAliasValue';
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { getConstellationGallery } from "../dataManager/getGalleryImages";
import SvgIconsComponent from "./SvgIconsComponent";


var bioData = {
    name: '',
    role: '',
    bio: '',
    image: ''
}
const BioComponent = ({shouldLoad}) => {

    let {type, name} = useParams();
    const location = useLocation();

    const [activeBio, setActiveBio] = useState();

    const [urlLoaded, setUrlLoaded] = useState(false);
    var activePerson;
    var dataList;
    const [dataaList, setDataaList] = useState();
    const [index, setIndex] = useState();
    const [hasNext, setHasNext] = useState();
    const [hasPrevious, setHasPrevious] = useState();

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Življenjepis";
    }, []);
    
    useEffect(() => {

        if(shouldLoad){

            if(type === "govornik"){

                dataList = JSON.parse(localStorage.getItem("talkersList"));
                setDataaList(dataList);
                var desiredName = name.replaceAll('-', ' ');
                
                // Find person by name
                dataList.forEach((person) => {
                    // if(getValueFromArrayElement(person, "talkerName") === desiredName) {
                        if(person.url.toString() === ('/zivljenjepis/govornik/' + name + '/')) {
                            activePerson = person;
                            if(dataList.length-1  === dataList.indexOf(person)){
                                setHasNext(false);
                                setHasPrevious(true);
                            }
                            else if(dataList.indexOf(person) === 0){
                                setHasNext(true);
                                setHasPrevious(false);
                            }
                            else {
                                setHasNext(true);
                                setHasPrevious(true);
                            }
                            setIndex(dataList.indexOf(person));
                    }
                })
                
                bioData.name = getValueFromArrayElement(activePerson, "talkerName");
                bioData.role = getValueFromArrayElement(activePerson, "talkerRole");
                bioData.bio = getValueFromArrayElementRichText(activePerson, "talkerBio");
                bioData.image = getImagePath(getValueFromArrayElement(activePerson, "talkerImage"));

                setActiveBio({type: "speaker", data: bioData});
        
            }
            else if(type === "clan-komisije") {
                dataList = JSON.parse(localStorage.getItem("commissionList"));
                setDataaList(dataList);
                var desiredName = name.replaceAll('-', ' ');
        
                // Find person by name
                dataList.forEach((person) => {
                    // if(getValueFromArrayElement(person, "commisionName") === desiredName) {
                        if(person.url.toString() === ('/zivljenjepis/clan-komisije/' + name + '/')) {
                            activePerson = person;
                            if(dataList.length-1  === dataList.indexOf(person)){
                                setHasNext(false);
                                setHasPrevious(true);
                            }
                            else if(dataList.indexOf(person) === 0){
                                setHasNext(true);
                                setHasPrevious(false);
                            }
                            else {
                                setHasNext(true);
                                setHasPrevious(true);
                            }
                            setIndex(dataList.indexOf(person));
                    }
                })
                bioData.name = getValueFromArrayElement(activePerson, "commisionName");
                bioData.role = getValueFromArrayElement(activePerson, "role");
                bioData.bio = getValueFromArrayElementRichText(activePerson, "commissionBio");
                bioData.image = getImagePath(getValueFromArrayElement(activePerson, "image"));

                setActiveBio({type: "comission", data: bioData});
            }
            setUrlLoaded(true);
        }

        window.scrollTo(0, 0);
        
    }, [location]);

    useEffect(() => {

        if(shouldLoad){

            if(type === "govornik"){

                dataList = JSON.parse(localStorage.getItem("talkersList"));
                setDataaList(dataList);
                var desiredName = name.replaceAll('-', ' ');
                
                // Find person by name
                dataList.forEach((person) => {
                    // if(getValueFromArrayElement(person, "talkerName") === desiredName) {
                        if(person.url.toString() === ('/zivljenjepis/govornik/' + name + '/')) {
                            activePerson = person;
                            if(dataList.length-1  === dataList.indexOf(person)){
                                setHasNext(false);
                                setHasPrevious(true);
                            }
                            else if(dataList.indexOf(person) === 0){
                                setHasNext(true);
                                setHasPrevious(false);
                            }
                            else {
                                setHasNext(true);
                                setHasPrevious(true);
                            }
                            setIndex(dataList.indexOf(person));
                    }
                })
                bioData.name = getValueFromArrayElement(activePerson, "talkerName");
                bioData.role = getValueFromArrayElement(activePerson, "talkerRole");
                bioData.bio = getValueFromArrayElementRichText(activePerson, "talkerBio");
                bioData.image = getImagePath(getValueFromArrayElement(activePerson, "talkerImage"));
                // bioData.bio.split('\n').map((line, index) => (
                //     bioData.lines.push(line)
                // ));
                setActiveBio({type: "govornik", data: bioData});
        
            }
            else if(type === "clan-komisije") {
                dataList = JSON.parse(localStorage.getItem("commissionList"));
                setDataaList(dataList);
                var desiredName = name.replaceAll('-', ' ');
        
                // Find person by name
                dataList.forEach((person) => {
                    // if(getValueFromArrayElement(person, "commisionName") === desiredName) {
                        if(person.url.toString() === ('/zivljenjepis/clan-komisije/' + name + '/')) {
                            activePerson = person;
                            if(dataList.length-1  === dataList.indexOf(person)){
                                setHasNext(false);
                                setHasPrevious(true);
                            }
                            else if(dataList.indexOf(person) === 0){
                                setHasNext(true);
                                setHasPrevious(false);
                            }
                            else {
                                setHasNext(true);
                                setHasPrevious(true);
                            }
                            setIndex(dataList.indexOf(person));
                    }
                })
                bioData.name = getValueFromArrayElement(activePerson, "commisionName");
                bioData.role = getValueFromArrayElement(activePerson, "role");
                bioData.bio = getValueFromArrayElementRichText(activePerson, "commissionBio");
                bioData.image = getImagePath(getValueFromArrayElement(activePerson, "image"));
                // bioData.bio.split('\n').map((line, index) => (
                //     bioData.lines.push(line)
                // ));
                setActiveBio({type: "clan-komisije", data: bioData});
            }
            setUrlLoaded(true);
        }
        
    }, [shouldLoad]);

    let navigate = useNavigate();

    const navigateToTheNextPerson = () =>{ 
        navigate(dataaList[index+1].url.toString());
    }

    const navigateToThePreviousPerson = () =>{ 
        navigate(dataaList[index-1].url.toString());
    }

    const getNextPerson = () =>{ 
        return dataaList[index+1].name;
    }

    const getPreviousPerson = () =>{ 
        return dataaList[index-1].name;
    }

 return (
    <> 
            {shouldLoad && urlLoaded && (
    <>
        <SvgIconsComponent/>
        
        <header id="entrepreneurStarsHeader" className="dpc-header">
            <HeaderNavComponent page={"Bio"}/>
        </header>

        <Helmet>
            <meta name="description" content={bioData.bio} />
            <meta property="og:title" content={bioData.name} />
            <meta property="og:description" content={bioData.bio} />
        </Helmet>

        <main id="main" className="dpc-main">

        <h1 className="dpc-visually-sleep">DPC</h1>

            {/* <!-- Section Bio --> */}
            <section id="bio" className="dpc-section dpc-section-bio">
            <div className="container-fluid">
                <div className="row mb-4">
                    <div className="col dpc-bio-content dpc-max-w-688px mx-auto">
                    <div className="row dpc-bio-info gx-0">
                        <div className="col dpc-bio-image" style={{"backgroundImage": `url(${bioData.image})`, "background-position": "top left"}}>
                            <img src={bioData.image} className="img-fluid" alt="..."/>
                        </div>
                        <div className="col dpc-bio-name-title">
                        {/* <button onClick={() => navigate(-1)}>Nazad</button> */}
                        <h3>{bioData.name}</h3>
                        <p>{bioData.role}</p>
                        </div>
                    </div>
                    {/* {bioData.lines.map((line, index) => (
                        line=='' ? <br/> : <p className="mb-1 dpc-text-22-sm-19">{line}</p>
                    ))} */}
                    {/* {bioData.bio} */}
                    <div dangerouslySetInnerHTML={{ __html: bioData.bio} }></div>
                    </div>
                </div> 
                <div className="dpc-brd-t-1-primary-variant dpc-max-w-894px mx-auto gx-0 dpc-bio-pager">
                    <div className="dpc-pager-btn">
                        {hasPrevious ? (
                            <>
                                <button type="button" className="dpc-btn-bio" onClick={() => { navigateToThePreviousPerson(); }}>
                                    {/* <button type="button" className="btn btn-tertiary ms-n175" onClick={() => { navigateToThePreviousPerson(); }}>Prejšnji</button> */}
                                    <button type="button" className="btn btn-tertiary ms-n175">Prejšnji</button>
                                    <p>{getPreviousPerson()}</p>
                                </button>
                            </>
                        ) : (
                            <>
                                <button type="button" className="btn btn-tertiary ms-n175 disabled">Prejšnji</button>
                            </>
                        )}
                    </div>
                    <div className="dpc-pager-btn text-end">
                        {hasNext ? (
                            <>  <button type="button" className="dpc-btn-bio text-end" onClick={() => { navigateToTheNextPerson(); }}>
                                    {/* <button type="button" className="btn btn-tertiary me-n175" onClick={() => { navigateToTheNextPerson(); }}>Naslednji</button> */}
                                    <button type="button" className="btn btn-tertiary me-n175">Naslednji</button>
                                    <p>{getNextPerson()}</p>
                                </button>
                            </>
                        ) : (
                            <>  
                                <button type="button" className="btn btn-tertiary me-n175 disabled">Naslednji</button>
                            </>
                        )}
                    </div>
                </div>  
            </div>
            </section>

        </main>

        <FooterComponent/>
        </>)}
    </>
    
 )
};

export default BioComponent;