export function getVideos(activeConference, conferenceYear) {
    var conferenceList = JSON.parse(localStorage.getItem("conferences"));

    // Find data for desired conference
    var filteredPageData = conferenceList.filter(conf => conf.name === activeConference.name);

    if(filteredPageData.length === 0) {
        return null;
    }

    // Find desired conference year
    var yearConference = filteredPageData[0].children.filter(year => year.name.includes(conferenceYear));

    if(yearConference.length === 0) {
        return null;
    }

    var sponsors = yearConference[0].children.filter(child => child.contentType.alias === "galleryVideos");
    if(sponsors)
        localStorage.setItem("campaignVideos", JSON.stringify(sponsors));
    else 
        localStorage.setItem("campaignVideos", JSON.stringify([]));
    return sponsors;
}

export function getVideosStars(stars) {
    
    var sponsors = stars.children.filter(child => child.contentType.alias === "galleryVideos");
    if(sponsors)
        localStorage.setItem("campaignVideos", JSON.stringify(sponsors));
    else 
        localStorage.setItem("campaignVideos", JSON.stringify([]));
    return sponsors;
}

export function getVideoListProperty(sponsors, alias) {
    
    var result = sponsors[0].properties.find((elem) => elem.alias === alias).value.value;

    return result;
}

export function getVideoValue(sponsor, alias) {
    
    var result = sponsor.properties.find((elem) => elem.alias === alias).value.value;

    return result;
}

