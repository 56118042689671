import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";

import configData from './conf.json';

const client = new ApolloClient({
  // uri: "https://localhost:44377/graphql",
  uri: configData.UMBRACO_SERVER_URL + "/graphql",
  cache: new InMemoryCache()
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <ApolloProvider client={client}>
    <BrowserRouter basename={configData.BASENAME}>
      <App />
    </BrowserRouter>
  </ApolloProvider>
  // </React.StrictMode> 
);

reportWebVitals();
