import { useEffect } from 'react';

const $ = window.$;

const ContantFormComponent = () => {

    // const  [name, setName] = useState("");
    // const  [surname, setSurname] = useState("");
    // const  [email, setEmail] = useState("");
    // const  [company, setCompany] = useState("");
    // const  [phone, setPhone] = useState("");
    // const  [job, setJob] = useState("");
    // const  [selectedConference, setSelectedConference] = useState("");

    // const [show, setShow] = useState(false);
    // const [emailMessageAlert, setEmailMessageAlert] = useState("");
    // const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    // var conferenceList = JSON.parse(localStorage.getItem("conferences"));

    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    useEffect(() => {

        // Embed HubSpot Form Script
        const script = document.createElement('script');
        script.src = '//js-eu1.hsforms.net/forms/embed/v2.js';
        document.head.appendChild(script);
    
        script.addEventListener('load', () => {
          if (window.hbspt) {
            window.hbspt.forms.create({
              region: "eu1",
              portalId: "25280231",
              formId: "63a9764b-c1c8-4391-bbb3-335c390e3e37",
              target: ".nav-conference-tab"
            });
          }
        });
    
        return () => {
            // Clean up when the component unmounts
            const formElement = document.getElementById('hubspot-form');
            if (formElement) {
              formElement.innerHTML = ''; // Clear the content of the form element
            }
        
            document.head.removeChild(script);
          };
      }, []);

    // const checkRequired = () => {

    //     if(name === "" || surname === "" || email === "" || company === "" || job === "" || selectedConference === "")
    //         setIsButtonDisabled(true);
    //     else
    //         setIsButtonDisabled(false);
    // };

    // useEffect(() => {
    //    checkRequired();
    // }, [name, surname, email, company, job, selectedConference]);

    // const sendEmail = () => {

    //         const data = {
    //             name: name,
    //             surname: surname,
    //             email: email,
    //             company: company,
    //             phone: phone,
    //             job: job,
    //             selectedConference: selectedConference,
    //         }
    //         const apiEndpointUrl = configData.UMBRACO_SERVER_URL  + '/email/';
    
    //         axios.post(apiEndpointUrl, data, {
    //             headers: {
    //               accept: 'application/json'
    //             }
    //           })
    //         .then((response) => { 
    //             // console.log('response', response);
    //             setEmailMessageAlert(getAliasValue("contact", "sendingEmailSuccessfully"));
    //             setShow(true);
    //         })
    //         .catch((err) => {
    //             console.log('error', err);
    //             setEmailMessageAlert(getAliasValue("contact", "unsuccessfullySendingEmail"));
    //             setShow(true);
    //         })
    // };

    // const setClassNameConference = (conf) => {

    //     if(conf.name === selectedConference){
    //         return 'btn btn-outline-primary active';
    //     }
    //     else {
    //         return 'btn btn-outline-primary';
    //     }
    // };

    // const setErrorClass = (value) => {

    //     var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    //     if(value.match(mailformat)) {
    //         $('.email').removeClass('dpc-error');
    //     }
    //     else {
    //         $('.email').addClass('dpc-error');
    //     }
    // };

    return (

        <>
            <div className="tab-pane nav-conference-tab pt-2 fade show active" id="nav-conference" role="tabpanel" aria-labelledby="nav-conference-tab">
                <div id="hubspot-form"></div>
            </div>
        </>
        

        // <>
        //     <div className="tab-pane nav-conference-tab fade show active" id="nav-conference" role="tabpanel" aria-labelledby="nav-conference-tab">
  
        //         {/* <p className="mb-4">{getAliasValue("contact", "registerDescription")}</p>

        //         <div className="row mb-3 pe-lg-9pc">
        //             <div className="col-md-6 pe-lg-9pc">
        //             <div className="mb-3">
        //             <input type="text" className="form-control" id="name" placeholder={getAliasValue("contact", "registerName")} onChange={(e) => setName(e.target.value)}/>
        //             </div>
        //             <div className="mb-3">
        //             <input type="email" className="email form-control" id="email" placeholder={getAliasValue("contact", "registerEmail")} onChange={(e) => {setEmail(e.target.value); setErrorClass(e.target.value);}}/>
        //             </div>
        //             <div className="mb-3">
        //             <input type="email" className="form-control" id="company" placeholder={getAliasValue("contact", "registerCompany")} onChange={(e) => setCompany(e.target.value)}/>
        //             </div>
        //         </div>
        //         <div className="col-md-6 ps-lg-9pc">
        //             <div className="mb-3">
        //             <input type="text" className="form-control" id="surname" placeholder={getAliasValue("contact", "registerSurname")} onChange={(e) => setSurname(e.target.value)}/>
        //             </div>
        //             <div className="mb-3">
        //             <input type="email" className="form-control" id="category" placeholder={getAliasValue("contact", "registerMobilePhone")} onChange={(e) => setPhone(e.target.value)}/>
        //             </div>
        //             <div className="mb-3">
        //             <input type="email" className="form-control" id="location" placeholder={getAliasValue("contact", "registerJob")} onChange={(e) => setJob(e.target.value)}/>
        //             </div>
        //         </div>
        //         </div>

        //         <p>{getAliasValue("contact", "selectAConference")}</p>

        //         <div className="dpc-conference-option">
        //             {conferenceList && conferenceList.map((conf) => (
        //                 <button type="button" className={setClassNameConference(conf)} onClick={() => setSelectedConference(conf.name)}>{conf.name}</button>
        //             ))}
        //         </div>

        //         <Modal show={show} onHide={handleClose}>
        //             <Modal.Header closeButton>
        //             <Modal.Title>{emailMessageAlert}</Modal.Title>
        //             </Modal.Header>
        //         </Modal>
        //         <div className="dpc-tab-pane-footer text-center">
        //             <button type="button" className="btn btn-tertiary dpc-ico-r d-btn-submit" disabled={isButtonDisabled} onClick={() => {sendEmail();}}>{getAliasValue("contact", "sendButton")} <svg className="dpc-svg-icon"><use xlinkHref="#arrow-right"/></svg></button>
        //         </div> */}

        //         </div>
        // </>
    );
}

export default ContantFormComponent;
