import React, { useEffect } from 'react';
import configData from '../conf.json';

function RedirectionComponent() {
  useEffect(() => {
    window.location.href = configData.REACT_URL + '/robots.txt';
    
  }, []);

  return null;
}

export default RedirectionComponent;