import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { getAliasValue } from '../dataManager/findAliasValue';
import { openContactMenu, openGeneralQuestionsMenu, openRegisterForConferenceMenu } from "../helperFunctions/openMenu";
import BecomeASponsorComponent from "./BecomeASponsorComponent";
import ContantFormComponent from "./ContactFormComponent";
import FooterComponent from "./FooterComponent";
import GeneralQuestionsComponent from "./GeneralQuestionsComponent";
import HeaderNavComponent from "./HeaderNavComponent";
import SvgIconsComponent from "./SvgIconsComponent";

const $ = window.$;

const ContactPageComponent = ({shouldLoad}) => {

  let {activeTab} = useParams();

  let navigate = useNavigate(); 
  const navigateTo = (path) =>{ 
    navigate(path);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Kontakt";
  }, []);

  useEffect(() => {
    if(activeTab === "postanite-sponzor"){
      openContactMenu();
    }
    else if(activeTab === "prijava") {
      openRegisterForConferenceMenu();
    }
    else if(activeTab === "vprasanja"){
      openGeneralQuestionsMenu();
    }
    else {
      openRegisterForConferenceMenu();
    }
}, []);

  

    const handleScroll = (event) => {
      
        if(event.target.scrollTop !== 0) {
          $('#contactOffCanvas').addClass('dpc-submenu-scrolling');
        }
        else {
          $('#contactOffCanvas').removeClass('dpc-submenu-scrolling');
        }
      };
  
      const handleScrollHorizontal = (event) => {
  
        if(event.target.id === 'nav-conference-tab') {
          $('#nav-tab').scrollLeft(0);
        }
        else if(event.target.id === 'nav-become-sponsor-tab') {
          var scrollPosition = $('#nav-tab').width()/2;
          $('#nav-tab').scrollLeft(scrollPosition);
        }
        else if (event.target.id === 'nav-contact-tab') {
          var scrollPosition = $('#nav-tab').width();
          $('#nav-tab').scrollLeft(scrollPosition);
        }
      };

    return (
        <>
        {shouldLoad && (
          <>

            <SvgIconsComponent/>

            <HeaderNavComponent page="ContactPage"/>

            <Helmet>
                <meta name="description" content="Kontakt" />
                <meta property="og:title" content="Kontakt" />
                <meta property="og:description" content="Kontakt" />
            </Helmet>

            <main id="main" className="dpc-main">

            <section id="" className="dpc-section dpc-section-contact-page dpc-pt-nav-distance min-h-85-screen-h">
                    <div className="container-fluid">
                        <h2 className="text-center dpc-h2-smaller pb-0">{getAliasValue("contact", "conferenceSubmenuTitle")}</h2>
            
            <div className="dpc-contact-submenu">
            <div className="d-contact-submenu-content overflow-y-initial pt-0">
                <div className="dpc-fixed-header">
                      {/* <h3 className="text-sm-center">{getAliasValue("contact", "conferenceSubmenuTitle")}</h3> */}
                      <div className="nav-scroller">
                        <menu className="nav" aria-label="Secondary navigation" id="nav-tab" role="tablist">
                          <button className="nav-link active" id="nav-conference-tab" data-bs-toggle="tab" data-bs-target="#nav-conference" role="tab" type="button" aria-controls="nav-conference" aria-selected="" onClick={(e) => {navigateTo('/kontakt/prijava'); handleScrollHorizontal(e);}}>
                            <svg className="dpc-svg-icon"><use xlinkHref="#arrow-right"/></svg>{getAliasValue("contact", "registerForTheConference")}</button>
                          <button className="nav-link" id="nav-become-sponsor-tab" data-bs-toggle="tab" data-bs-target="#nav-become-sponsor" role="tab" type="button" aria-controls="nav-become-sponsor" aria-selected="" onClick={(e) => {navigateTo('/kontakt/postanite-sponzor'); handleScrollHorizontal(e);}}>
                            <svg className="dpc-svg-icon"><use xlinkHref="#arrow-right"/></svg>{getAliasValue("contact", "becomeASponsorTitle")}</button>
                          <button className="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" role="tab" type="button" aria-controls="nav-contact" aria-selected="" onClick={(e) => {navigateTo('/kontakt/vprasanja'); handleScrollHorizontal(e);}} >
                            <svg className="dpc-svg-icon"><use xlinkHref="#arrow-right"/></svg>{getAliasValue("contact", "generalQuestionsTitle")}</button>
                        </menu>
                      </div>
                    </div>
      
                      <div className="tab-content dpc-thin-scroll" id="nav-tabContent" onScroll={handleScroll}>
                        
                        <ContantFormComponent/>
      
                        <BecomeASponsorComponent/>
      
                        <GeneralQuestionsComponent/>
      
                      </div>
                      </div>
                </div>

                </div>
                </section>

                </main>

                <FooterComponent/>
            </>
            )}
        </>
    );
};

export default ContactPageComponent;