const SvgIconsComponent = () => {
    return (
        <>
        <svg style={{visibility:'hidden'}} className="dpc-svg-defs">
                    <defs>
                    <symbol id="play" viewBox="0 0 512 512">
                        <polygon points="446,255.99 158.04,422.25 126,440.74 126,71.26 "/>
                    </symbol>
                    <symbol id="arrow-right" viewBox="0 0 15 13">
                    <path d="M15 6.10248L8.0146 0L6.81022 1.41818C8.9562 3.13719 11.1241 4.66281 12.7445 5.73719L12.6788 5.86612C10.7299 5.69422 7.86131 5.60826 5.36496 5.60826H0V7.37025H5.36496C7.86131 7.37025 10.7299 7.2843 12.7007 7.13388L12.7445 7.26281C11.146 8.3157 8.93431 9.8843 6.81022 11.5818L8.0146 13L15 6.89752V6.10248Z" />
                    </symbol>
                    <symbol id="arrow-left" viewBox="0 0 15 13">
                    <path d="M0 6.10248L6.9854 0L8.18978 1.41818C6.0438 3.13719 3.87591 4.66281 2.25547 5.73719L2.32117 5.86612C4.27007 5.69422 7.13869 5.60826 9.63504 5.60826H15V7.37025H9.63504C7.13869 7.37025 4.27007 7.2843 2.29927 7.13388L2.25547 7.26281C3.85401 8.3157 6.06569 9.8843 8.18978 11.5818L6.9854 13L0 6.89752V6.10248Z" />
                    </symbol>
                    <symbol id="linked-in" viewBox="0 0 24 24">
                    <path d="M19,19h-3v-4.6c0-1.1,0-2.5-1.5-2.5c-1.5,0-1.8,1.2-1.8,2.5V19h-3V9.5h2.8v1.3h0c0.3-0.5,0.7-0.9,1.2-1.2
                    c0.5-0.3,1.1-0.4,1.6-0.4c3,0,3.6,2,3.6,4.5L19,19z M6.4,8.2c-0.3,0-0.7-0.1-1-0.3C5.2,7.7,5,7.4,4.9,7.1c-0.1-0.3-0.2-0.7-0.1-1
                    C4.8,5.8,5,5.5,5.2,5.3C5.5,5,5.8,4.9,6.1,4.8c0.3-0.1,0.7,0,1,0.1C7.4,5,7.7,5.2,7.9,5.5c0.2,0.3,0.3,0.6,0.3,1
                    c0,0.2,0,0.4-0.1,0.7C8,7.3,7.8,7.5,7.7,7.7C7.5,7.8,7.3,8,7.1,8.1C6.9,8.1,6.7,8.2,6.4,8.2z M7.9,19H5V9.5h3V19z M20.5,2h-17
                    c-0.4,0-0.8,0.1-1,0.4C2.2,2.7,2,3.1,2,3.4v17.1c0,0.4,0.2,0.8,0.4,1s0.6,0.4,1,0.4h17c0.4,0,0.8-0.1,1-0.4c0.3-0.3,0.4-0.6,0.4-1
                    V3.4c0-0.4-0.2-0.8-0.4-1C21.3,2.1,20.9,2,20.5,2" />
                    </symbol>
                    <symbol id="email" viewBox="0 0 24 24">
                    <path d="M18.4,5L6.3,8.9L5,9.3l1.3,0.5l5.6,2.3l2.3,5.6l0.5,1.3l0.4-1.3L19,5.6l-0.4-0.2L18.4,5z M12.1,11.2L7.9,9.4
                    l8.8-2.8L12.1,11.2z M14.6,16.1l-1.8-4.2l4.6-4.6L14.6,16.1z" />
                    </symbol>
                    <symbol id="telephone" viewBox="0 0 24 24">
                    <path d="M16.4,21.1c-3.5,0-6.9-1.4-9.5-3.9C4.4,14.7,3,11.4,3,7.7c0-1.2,0.4-2.3,1.1-3.1c0.8-0.9,1.8-1.5,3-1.6h0.2
                    c0.1,0,0.3,0.1,0.4,0.1c0.1,0.1,0.2,0.3,0.2,0.4l1.9,4.3C9.9,8,10,8.2,10,8.4c0,0.1-0.1,0.3-0.1,0.4H9.8l-1.6,2.5
                    c0,0.1-0.1,0.2-0.1,0.3s0,0.1,0,0.2c0,0.1,0,0.1,0.1,0.3l0,0c0.8,1.6,2.2,3,3.9,3.8c0.1,0.1,0.2,0.1,0.4,0.1c0.1,0,0.2-0.1,0.4-0.1
                    l2.4-1.4c0.2-0.2,0.6-0.3,0.9-0.1l4.5,1.9c0.1,0,0.2,0.1,0.2,0.1c0.2,0.1,0.3,0.3,0.3,0.5V17c-0.1,1.2-0.7,2.2-1.6,3
                    C18.6,20.7,17.5,21.1,16.4,21.1z M7,4C6.2,4.1,5.5,4.5,4.9,5.2C4.2,6.1,4,7,4,7.7c0,3.4,1.3,6.5,3.6,8.7c2.3,2.3,5.5,3.7,8.8,3.7
                    c0.8,0,1.7-0.3,2.5-0.9c0.6-0.6,1.1-1.3,1.2-2.1l-4.4-1.9l-2.5,1.4c-0.3,0.1-0.5,0.3-0.9,0.3c-0.2,0-0.5,0-0.8-0.2
                    c-1.8-0.8-3.5-2.4-4.3-4.2c-0.1-0.2-0.2-0.5-0.1-1c0-0.3,0.1-0.5,0.2-0.7l1.5-2.4L7,4z M16.1,14.7L16.1,14.7L16.1,14.7z M6.9,3.7
                    L6.9,3.7L6.9,3.7z M6.9,3.7L6.9,3.7L6.9,3.7z" />
                    </symbol>
                    <symbol id="arrow-download" viewBox="0 0 25 24">
                    <path d="M12.8975 18L19 11.0146L17.5818 9.81022C15.8628 11.9562 14.3372 14.1241 13.2628 15.7445L13.1339 15.6788C13.3058 13.7299 13.3917 10.8613 13.3917 8.36496L13.3917 3L11.6298 3L11.6298 8.36496C11.6298 10.8613 11.7157 13.7299 11.8661 15.7007L11.7372 15.7445C10.6843 14.146 9.1157 11.9343 7.41818 9.81022L6 11.0146L12.1025 18L12.8975 18Z"/>
                    <rect x="3.5" y="20" width="18" height="1"/>
                    </symbol>
                    <symbol id="star" viewBox="0 0 24 24">
                    <path d="M21,11.9c-4.4-1.3-7.5-4.7-8.9-8.9h-0.3c-1.3,4.2-4.5,7.5-8.9,8.9v0.3c4.4,1.3,7.5,4.7,8.9,8.9h0.3
                    c1.3-4.2,4.5-7.5,8.9-8.9V11.9z"/>
                    </symbol>
                    <symbol id="x-logo" viewBox="0 0 1200 1227">
                    <path d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"/>
                    </symbol>
                    <symbol id="x-logo-bold" viewBox="0 0 1322 1286">
                    <path d="M817.1,551.19L1291.27,0h-184.95L734.96,431.67L438.37,0h-430l487.55,709.57L0,1286h184.96l393.08-456.92L892,1286h430 L817.1,551.19z M635.16,715.86v-0.03l-47.47-67.89L210.01,107.7h162.6l304.8,435.99l47.47,67.89l396.2,566.72h-162.6 L635.16,715.86z"/>
                    </symbol>
                    <symbol id="x-logo-solid" viewBox="0 0 1322 1286">
                    <polygon points="892,1286 578.04,829.08 184.96,1286 0,1286 495.92,709.57 8.37,0 438.37,0 734.96,431.67 1106.32,0 1291.27,0 817.1,551.19 1322,1286 "/>
                    </symbol>

                    <symbol id="linkedin" viewBox="0 0 448 512">
                    <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/>
                    </symbol>

                    <symbol id="youtube" viewBox="0 0 576 512">
                    <path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"/>
                    </symbol>
                </defs>
            </svg>
        </>
    );
};

export default SvgIconsComponent;