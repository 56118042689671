import { useLocation, useNavigate } from "react-router-dom";
import { getAliasValue, getValueFromArrayElement } from '../dataManager/findAliasValue';

const FooterComponent = () => {

    var activePageEntrepreneurStars = JSON.parse(localStorage.getItem("activePageEntrepreneurStars"));

    var location = useLocation();
    var conferenceList = JSON.parse(localStorage.getItem("conferences"));

    let navigate = useNavigate(); 
        const navigateTo = (path) =>{ 
        navigate(path);
    }

    return (
        <>
            <footer id="footer" className="dpc-footer">
                <div className="container-fluid">
                    <div className="row h-100">
                        <div className="col-md-6 col-lg-5 col-xl-4 d-flex order-2 order-md-0 flex-column justify-content-end" data-aos="fade-left">
                            <div className="dpc-social-links mb-auto">
                                <a href={getAliasValue("homePage", "linkedinFooter")} target="_blank" rel="noreferrer" title="LinkedIn"><svg className="dpc-svg-icon"><use xlinkHref="#linkedin"/></svg></a>
                                <a href={getAliasValue("homePage", "youTubeFooter")} target="_blank" rel="noreferrer" title="Youtube"><svg className="dpc-svg-icon w-125"><use xlinkHref="#youtube"/></svg></a>
                                <a href={getAliasValue("homePage", "twitterFooter")} target="_blank" rel="noreferrer" title="X (ex-Twitter)"><svg className="dpc-svg-icon"><use xlinkHref="#x-logo-bold"/></svg></a>
                            </div>
                            <div className="dpc-contact-info">
                                <p>{getAliasValue("homePage", "address")}</p>
                                <p>{getAliasValue("homePage", "phone")}</p>
                                <p>{getAliasValue("homePage", "email")}</p>
                                <p>{getAliasValue("homePage", "businessCentre")}</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-7 col-xl-8 d-flex align-items-md-end text-md-end" data-aos="fade-left">
                            <nav id="footerlinks" className="dpc-footer-links mb-4 mb-md-0">
                                {conferenceList && conferenceList.map((conf) => (
                                //     <a onClick={() => {navigateTo(conf.url.toString() + new Date().getFullYear().toString()); }}
                                //     title={conf.name}>
                                //       {conf.name}
                                //  </a>
                                <a href={conf.url.toString() + new Date().getFullYear().toString()}
                                    onClick={(event) => { 
                                        event.preventDefault();
                                        navigateTo(conf.url.toString() + new Date().getFullYear().toString()); 
                                    }}
                                    title={conf.name}>
                                        {conf.name}
                                </a>
                             
                                ))}
                                {/* <a onClick={() => {navigateTo('/podjetniske-zvezde/' + getValueFromArrayElement(activePageEntrepreneurStars.entrepreneurStars, "year"))}}
                                   title={getValueFromArrayElement(activePageEntrepreneurStars.entrepreneurStars, "title")}>
                                    {getValueFromArrayElement(activePageEntrepreneurStars.entrepreneurStars, "title")}
                                </a> */}
                                <a href={'/podjetniske-zvezde/' + getValueFromArrayElement(activePageEntrepreneurStars.entrepreneurStars, "year")}
                                    onClick={() => {navigateTo('/podjetniske-zvezde/' + getValueFromArrayElement(activePageEntrepreneurStars.entrepreneurStars, "year"))}}
                                    title={getValueFromArrayElement(activePageEntrepreneurStars.entrepreneurStars, "title")}>
                                        {getValueFromArrayElement(activePageEntrepreneurStars.entrepreneurStars, "title")}
                                </a>
                            </nav>
                        </div>
                    </div>

                </div>
            </footer>
        </>
    );
}

export default FooterComponent;