import { useLocation, useNavigate } from 'react-router-dom';
import {getAliasValue, getValueFromArrayElement,getImagePath } from '../dataManager/findAliasValue';
import { useEffect, useState } from 'react';
import axios from 'axios';
import configData from '../conf.json';

const CommissionComponent = () => {

    var commission = JSON.parse(localStorage.getItem("commissionList"));
    const [peopleCommission, setPeopleCommission] = useState();
    var temp;

    let navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        setPeopleCommission(peopleCommission);
    }, [peopleCommission]);

    useEffect(() => {
        setPeopleCommission();

        var activeEntrepreneurStars = JSON.parse(localStorage.getItem("activePageEntrepreneurStars"));
        var getSponsorsImagesURL = configData.REACT_URL 
                + "/umbraco/delivery/api/v1/content/item/" 
                + activeEntrepreneurStars.entrepreneurStars.url;
        
        axios.get(getSponsorsImagesURL, {
            headers: {
            accept: 'application/json'
            }
        }).then((response) => { 
                
            setPeopleCommission(response.data.properties.commission);
            if(response.data.properties.commission != null) {
            
                localStorage.setItem("actualCommission", JSON.stringify(response.data.properties.commission));
                
                var activeC = JSON.parse(localStorage.getItem("actualCommission"));

                const namesFromSencodList = activeC.map(person => person.name);

                temp = commission.filter(person => namesFromSencodList.includes(person.name));
                setPeopleCommission(temp);

            }
        });
        
    }, [location]);
 
    const navigateTo = (path) =>{ 
      navigate(path);
    }

    return (

        <>
        {peopleCommission && (
        <>

            {/* <!-- Section Commission --> */}
            <section id="commission" className="dpc-section dpc-section-commission">
            <div className="container-fluid">
                <h2 className="mb-lg-4">{getAliasValue("commissionList", "title")}</h2>
                <div className="dpc-commision-grid">
                    {peopleCommission && peopleCommission.map(elem => (
                        <div className="card">
                            <img src={getImagePath(getValueFromArrayElement(elem, "image"))} className="card-img-top" alt="..."/>
                            <div className="card-body">
                                <h4>{getValueFromArrayElement(elem, "commisionName")}</h4>
                                <p className="card-text">{getValueFromArrayElement(elem, "role")}</p>
                                <button type="button" className="btn btn-tertiary dpc-ico-r ms-n175" onClick={() => {navigateTo(elem.url.toString())}}>Preberi Bio <svg className="dpc-svg-icon"><use xlinkHref="#arrow-right"/></svg></button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            </section>
        </>
        )}
        </>
    );
}

export default CommissionComponent;