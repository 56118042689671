import axios from "axios";
import configData from '../conf.json';

var activeconferenceList = [];
    var activeConferenceAndYearList = [];
    var result;
    var urls = [];


const isBetween = (start_date, end_date) => {
    var current_time = new Date().toISOString().slice(0, 10);
    
    if (start_date && end_date) {
        var result =  start_date.slice(0, 10) <= current_time && current_time <= end_date.slice(0, 10);
        return result;
    } else if (start_date) {
        return start_date.slice(0, 10) <= current_time;
    } else if (end_date) {
        return current_time <= end_date.slice(0, 10);
    } else {
        return false;
    }
}

const fetchData = async (conf, confYear) => {
    try {
        {
            var isActive = confYear.properties.find(({ alias }) => alias === "conferenceIsActual");
            var isCurrentYear = confYear.properties.find(({ alias }) => alias === "conferenceYear");
            if(isCurrentYear.value.value.toString() == new Date().getFullYear().toString()) {
            if(isActive.value.value === true) {
                result = confYear;
                if(result != null) {
                  if(!activeconferenceList.includes(conf)) {
                    const url = configData.REACT_URL + "/umbraco/delivery/api/v1/content/item/" + result.url;
                    urls.push(url);
                  }
                }
            }
          }
            
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

export async function getConferencesActive(conferenceList) {

    
        if(conferenceList.length !== 0) {
           var axiosRequests = conferenceList.map(conf => {
                conf.children.map(confYear => {
                    
                    fetchData(conf, confYear);
                });
                
            });

            axios.all(urls.map((url) => axios.get(url, {
                              headers: {
                                accept: 'application/json'
                              }
                            }))).then((response) => {

                                response.map((conf) => {
                                    if(conf.data.properties.conferenceIsActual === true && 
                                        conf.data.properties.conferenceYear >= new Date().getFullYear()) {
                                        if (isBetween(conf.data.properties.startEndDate, conf.data.properties.endDate)) {

                                            for (let item1 of conferenceList) {
                                                let urlToCheck = item1.url;
                                                    let pathToCheck = conf.data.route.path;
                                                    if (pathToCheck.includes(urlToCheck)) {
                                                        activeconferenceList.push(item1);
                                                        break;
                                                    }
                                                }
                                        }
                                        else if(conf.data.properties.startEndDate === null && conf.data.properties.endDate === null){
                                                for (let item1 of conferenceList) {
                                                    let urlToCheck = item1.url;
                                                        let pathToCheck = conf.data.route.path;
                                                        if (pathToCheck.includes(urlToCheck)) {
                                                            activeconferenceList.push(item1);
                                                            break;
                                                        }
                                                    }
                                        }
                                        
                                    }
                                });

                                            localStorage.setItem("conferencesActiveConferencesPage", JSON.stringify(activeconferenceList));
                                            return true;
                                            });
    
        }
}