import { getImagePath, getValueFromArrayElementOurTeam } from "../dataManager/findAliasValue";

const OurTeamComponent = () => {

    var ourTeamMembers = JSON.parse(localStorage.getItem("ourTeam"));

    return (
        <> 
        {ourTeamMembers && (
        <>

            {/* <!-- Section Our Team --> */}
            <section className="dpc-section dpc-section-past-events">
            <div className="container-fluid">
                <h2 className="mb-lg-4 dpc-brd-b-1-primary-variant">Vodstvo</h2>
                <div className="dpc-past-events-grid dpc-our-team-grid">
                    {ourTeamMembers && ourTeamMembers.length !== 0 && ourTeamMembers.map(elem => (
                        <div className="card">
                            <div className="dpc-bg-image-holder" style={{"backgroundImage": `url(${getImagePath(getValueFromArrayElementOurTeam(elem, "ourTeamMemberPhoto"))})`}} title="...">
                                <img src={getImagePath(getValueFromArrayElementOurTeam(elem, "ourTeamMemberPhoto"))} className="img-fluid" alt="..."/>
                            </div>
                            {/* <img src={getImagePath(getValueFromArrayElementOurTeam(elem, "ourTeamMemberPhoto"))} className="card-img-top" alt="..."/> */}
                            <div className="card-body">
                                <h4>{getValueFromArrayElementOurTeam(elem, "ourTeamMemberName")}</h4>
                                <p className="card-text-sm">{getValueFromArrayElementOurTeam(elem, "ourTeamMemberShortBio")}</p>
                                <p className="card-text dpc-c-primary">{getValueFromArrayElementOurTeam(elem, "ourTeamMemberPhoneNumber")}</p>
                                <a href={`mailto:${getValueFromArrayElementOurTeam(elem, "ourTeamMemberEmail")}`} className="card-text dpc-c-primary dpc-link-hover-underline-accent dpc-lh-120">{getValueFromArrayElementOurTeam(elem, "ourTeamMemberEmail")}</a>
                                {getValueFromArrayElementOurTeam(elem, "ourTeamMemberLinkedin") !== '' && <a href={getValueFromArrayElementOurTeam(elem, "ourTeamMemberLinkedin")} target="_blank" rel="noreferrer" className="dpc-soc-net-link"><svg className="dpc-svg-icon"><use xlinkHref="#linked-in"/></svg></a>}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            </section>
        </>
        )}
        </>
    );
};

export default OurTeamComponent;