export function getCampaignGallery(activeConference, conferenceYear) {

    var conferenceList = JSON.parse(localStorage.getItem("conferences"));

    // Find data for desired conference
    var filteredPageData = conferenceList.filter(conf => conf.name === activeConference.name);

    if(filteredPageData.length === 0) {
        return null;
    }

    // Find desired conference year
    var yearConference = filteredPageData[0].children.filter(year => year.name.includes(conferenceYear));

    if(yearConference.length === 0) {
        return null;
    }

    var gallery = yearConference[0].children.filter(child => child.contentType.alias === "gallery");
    if(gallery)
        localStorage.setItem("campaignGallery", JSON.stringify(gallery));
    else 
        localStorage.setItem("campaignGallery", JSON.stringify([]));
    return gallery;
}

export function getOurNomineesGallery(year) {

    var starsList = JSON.parse(localStorage.getItem("entrepreneurStars"));
    var filteredPageData = starsList.filter(star => star.properties[1].value.value == year.properties[1].value.value);
    if(filteredPageData.length === 0) {
        return null;
    }
    
    var gallery = filteredPageData[0].children.filter(child => (child.contentType.alias === "gallery"));

    if(gallery)
        localStorage.setItem("ourNomineesGallery", JSON.stringify(gallery));
    else 
        localStorage.setItem("ourNomineesGallery", JSON.stringify([]));

    return gallery;
}

export function getConstellationGallery(activeConstellation) {

    var gallery = activeConstellation.children.filter(child => child.contentType.alias === "gallery");
    if(gallery)
        localStorage.setItem("constellationGallery", JSON.stringify(gallery));
    else 
        localStorage.setItem("constellationGallery", JSON.stringify([]));
    return gallery;
}