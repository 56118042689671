import { useEffect } from 'react';


const $ = window.$;

const BecomeASponsorComponent = () => {

    useEffect(() => {

        // Embed HubSpot Form Script
        const script = document.createElement('script');
        script.src = '//js-eu1.hsforms.net/forms/embed/v2.js';
        document.head.appendChild(script);
    
        script.addEventListener('load', () => {
          if (window.hbspt) {
            window.hbspt.forms.create({
            region: "eu1",
            portalId: "25280231",
            formId: "459e3765-e9e4-4826-bd6c-5a85c903a48f",
            target: ".nav-become-sponsor-tab"
            });   
          }
        });
    
        return () => {
            const formElement = document.getElementById('hubspot-form-sponsor');
            if (formElement) {
              formElement.innerHTML = ''; // Clear the content of the form element
            }
        
            document.head.removeChild(script);
          };
      }, []);

    // const [message, setMessage] = useState("");
    // const [email, setEmail] = useState("");

    // const [show, setShow] = useState(false);
    // const [emailMessageAlert, setEmailMessageAlert] = useState("");
    // const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    // useEffect(() => {

    //     $("#textareasponsor").on("keyup", function () {
    //         $(this).height("38px");
    //         $(this).height(this.scrollHeight);
    //     });
    // }, []);

    // const checkRequired = () => {

    //     if(message === "" || email === ""){
    //         setIsButtonDisabled(true);
    //     } 
    //     else{
    //         var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    //         if(email.match(mailformat) === null) {
    //           setIsButtonDisabled(true);
    //         }
    //         else {
    //           setIsButtonDisabled(false);
    //         }
    //       }
    // };

    // useEffect(() => {
    //     checkRequired();
    //  }, [message, email]);

    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);

    // const sendEmail = (emailType) => {

    //     const data = {
    //         message: message,
    //         email: email,
    //         emailType: emailType
    //     }
    //     const apiEndpointUrl = configData.UMBRACO_SERVER_URL  + '/emailBecomeASponsorAndQuestions/';

    //     axios.post(apiEndpointUrl, data, {
    //         headers: {
    //           accept: 'application/json'
    //         }
    //       })
    //     .then((response) => {
    //         setEmailMessageAlert(getAliasValue("contact", "sendingEmailSuccessfully"));
    //         setShow(true);
    //     })
    //     .catch((err) => {
    //         console.log('error', err);
    //         setEmailMessageAlert(getAliasValue("contact", "unsuccessfullySendingEmail"));
    //         setShow(true);
    //     })
    // };

    // const setErrorClass = (value) => {

    //     var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    //     if(value.match(mailformat)) {
    //         $('.email').removeClass('dpc-error');
    //     }
    //     else {
    //         $('.email').addClass('dpc-error');
    //     }
    // };

    return (
        <>
            <div className="tab-pane nav-become-sponsor-tab fade" id="nav-become-sponsor" role="tabpanel" aria-labelledby="nav-become-sponsor-tab">
                <div id="hubspot-form-sponsor"></div>
            </div>
        </>
    );

    // return (
    //     <>
    //         <div className="tab-pane nav-become-sponsor-tab fade" id="nav-become-sponsor" role="tabpanel" aria-labelledby="nav-become-sponsor-tab">
  
    //             <p>{getAliasValue("contact", "becomeASponsorDescription")}</p>

    //             <div className="row mt-3 mb-4 mt-md-2 mb-md-5">
    //             <div className="col-12 pb-3">
    //             <div className="mb-5">
    //                         <input type="email" className="email form-control" id="email" placeholder={getAliasValue("contact", "registerEmail")} onChange={(e) => {setEmail(e.target.value); setErrorClass(e.target.value);}}/>
    //                     </div> 
    //             </div>
    //                 <div className="col-12"> 
    //                     <div className="dpc-textarea-autoresize">
    //                         <textarea spellcheck="false" type="text" id="textareasponsor" className="form-control" placeholder={getAliasValue("contact", "writeAMessage")} required onChange={(e) => setMessage(e.target.value)}></textarea>
    //                     </div>                  
    //                 </div>
    //             </div>

    //             {/* <div className="row dpc-tab-pane-footer"> */}
    //             <div className="row dpc-tab-pane-footer">
    //                 <Modal show={show} onHide={handleClose}>
    //                     <Modal.Header closeButton>
    //                     <Modal.Title>{emailMessageAlert}</Modal.Title>
    //                     </Modal.Header>
    //                 </Modal>
                    
    //             <div className="col-md-4 offset-md-8 order-2 order-md-1 mb-3 mb-md-0 d-md-flex justify-content-md-end">
    //                 <img src={getImagePath(getAliasValue("contact", "contactImage"))} className="become-sponsor-image img-fluid" alt="..."/>
    //             </div>

    //                 <div className="col-md-3 order-1 order-md-2 text-end text-md-start">
    //                     <button type="button" className="btn btn-tertiary dpc-ico-r d-btn-submit" disabled={isButtonDisabled} onClick={() => {sendEmail("sponsor");}}>
    //                         {getAliasValue("contact", "sendButton")}  
    //                         <svg className="dpc-svg-icon"><use xlinkHref="#arrow-right"/></svg>
    //                     </button>
    //                 </div>
    //                 <div className="col-md-9 text-md-end order-3">
    //                     <span className="dpc-item"><svg className="dpc-svg-icon"><use xlinkHref="#email"/></svg>
    //                         {getAliasValue("contact", "contactEmail")}
    //                     </span>
    //                     <span className="dpc-item"><svg className="dpc-svg-icon"><use xlinkHref="#telephone"/></svg>
    //                         {getAliasValue("contact", "contactPhoneNumber")} 
    //                     </span>
    //                     {getAliasValue("contact", "contactLinkedin") != '' && (
    //                         <a rel="noreferrer" className="dpc-item v-al-unset dpc-icon" href={getAliasValue("contact", "contactLinkedin")} target="_blank" title="Linked In">
    //                             <svg className="dpc-svg-icon"><use xlinkHref="#linked-in"/></svg>
    //                         </a>
    //                     )}
    //                 </div>
    //             </div>

    //         </div>
    //     </>
    // );
};

export default BecomeASponsorComponent;