import { useNavigate } from 'react-router-dom';
import { getProgram, getProgramEvents, getProgramPanels, getValueFromArrayElement, getValueFromArrayElementRichText } from '../dataManager/findAliasValue';

const ProgramComponent = ({year}) => {

          var activeConference = JSON.parse(localStorage.getItem("activePageCampaignAndYear"));
          var activeProgram = getProgram(activeConference.campaign[0], activeConference.year)

          let navigate = useNavigate(); 
          
          var listOfPeople = [];
          var names = [];
          var data = JSON.parse(localStorage.getItem("pagesData"))
          var talkers = data.nodes.find(page => page.contentType.alias == "talkersList");
          var commission = data.nodes.find(page => page.contentType.alias == "commissionList");
          

          talkers.children.map(talker => {listOfPeople.push({name: talker.name, type: 'govornik'}); names.push(talker.name)});
          commission.children.map(c => {listOfPeople.push({name: c.name, type: 'clan-komisije'}); names.push(c.name)});

          if(activeProgram.length > 0){
            //get program events
              getProgramEvents();
              getProgramPanels();


              var programEvents = JSON.parse(localStorage.getItem("programEvents"));
              var openingSpeechList = [];
              var greetingSpeechList = [];
              var otherSpeeches = [];
              if(programEvents) {
                programEvents.forEach(event => {
                  var speech = getValueFromArrayElement(event, "type");
                  if(speech === '-') {
                    otherSpeeches.push(event);
                  }
                  else if(speech === 'greeting speech') {
                    greetingSpeechList.push(event);

                  } else if (speech === 'opening speech') {
                    openingSpeechList.push(event);
                  }
                
              });
              }
                var programPanels = JSON.parse(localStorage.getItem("programPanels"));
          }
          
          const replaceNamesWithLinks = (originalText, names) => {

            var text = originalText.replace(/\n/g, "<br>");
            
            // Create a regular expression with all names joined by the "|" (OR) operator
            const regex = new RegExp((names).join('|'), 'g');
        
            // Replace each occurrence of a name with a link
            const updatedText = text.replace(regex, (match) => {

              var foundObject = talkers.children.find(function(obj) {
                return obj.name === match;
            });

            if(!foundObject) {
              var foundObject = commission.children.find(function(obj) {
                  return obj.name === match;
              });
            }

            var urlName = foundObject.url.toString();

            const url = `${urlName}`;
            return `<a href=${url} class="talker-name-link">${match}</a>`;
            });
        
            return updatedText;
          };

            return (
                <>
                    {/* <!-- Section Program --> */}
                    {activeProgram && programEvents && (
                    <>
                      <section id="program" className="dpc-section dpc-section-program">
                          <div className="container-fluid">
                            {(new Date().getFullYear().toString()  === year) ? <h2 className="mb-35 dpc-brd-b-1-primary-variant">Program</h2> : <h2 className="mb-35 dpc-brd-b-1-primary-variant">O cem sva govorila</h2>}
                          </div>
                          
                          { (new Date().getFullYear().toString()  === year) &&
                            <>
                              <div className="container-fluid dpc-program-grid-section">
                                  {otherSpeeches && otherSpeeches.map((event) => (
                                      <div className="row dpc-program-row">
                                        <div className="col dpc-col-first">
                                          {getValueFromArrayElement(event, "eventTime")}
                                        </div>
                                        <div className="col dpc-col-second" dangerouslySetInnerHTML={{ __html: replaceNamesWithLinks(getValueFromArrayElementRichText(event, "eventDescription"), names)} } />
                                      </div>
                                  ))}
                                  {greetingSpeechList.length > 0 &&
                                    <span className="dpc-program-subtitle">POZDRAVNI GOVORI</span> 
                                  }
                                    {greetingSpeechList && greetingSpeechList.length > 0 && greetingSpeechList.map((event) => (
                                        <div className="row dpc-program-row">
                                          <div className="col dpc-col-first">
                                            {getValueFromArrayElement(event, "eventTime")}
                                          </div>
                                          <div className="col dpc-col-second" dangerouslySetInnerHTML={{ __html: replaceNamesWithLinks(getValueFromArrayElementRichText(event, "eventDescription"), names)} } /> 
                                        </div>
                                    ))}
                                    {openingSpeechList.length > 0 &&
                                      <span className="dpc-program-subtitle">UVODNI GOVORI</span>
                                    }
                                      {openingSpeechList && openingSpeechList.length > 0 && openingSpeechList.map((event) => (
                                        <div className="row dpc-program-row">
                                          <div className="col dpc-col-first">
                                            {getValueFromArrayElement(event, "eventTime")}
                                          </div>
                                          <div className="col dpc-col-second" dangerouslySetInnerHTML={{ __html: replaceNamesWithLinks(getValueFromArrayElementRichText(event, "eventDescription"), names)} } />
                                        </div>
                                    ))}
                                </div>
                                </>
                            }

                            {programPanels && programPanels.length > 0 && programPanels.map((panel) => (
                                <>
                                <div className="container-fluid dpc-program-grid-section">
                                  <div className="row dpc-program-row-big pt-0">
                                    <div className="dpc-subtitle-holder"><span className="dpc-program-accent-subtitle">{panel.name}</span></div>
                                    <div className="col dpc-col-first">
                                      {getValueFromArrayElement(panel, "panelMainEventTime")}
                                    </div>
                                    <div className="col dpc-col-second">
                                      <h4>{getValueFromArrayElement(panel, "panelMainEventTitle")}</h4>
                                        <div dangerouslySetInnerHTML={{ __html: replaceNamesWithLinks(getValueFromArrayElementRichText(panel, "panelMainEventDescription"), names)} } />
                                    </div>
                                  </div>
                                    {panel.children && panel.children.map((event) => (
                                      <>
                                      {getValueFromArrayElement(event, "type") === 'independent speech' && (
                                        <span className="dpc-program-subtitle">SAMOSTOJEN NASTUP</span>
                                      )}
                                      <div className="row dpc-program-row">
                                        <div className="col dpc-col-first">
                                          {getValueFromArrayElement(event, "eventTime")}
                                        </div>
                                        <div className="col dpc-col-second" dangerouslySetInnerHTML={{ __html: replaceNamesWithLinks(getValueFromArrayElementRichText(event, "eventDescription"), names)} } />
                                      </div>
                                      </>
                                  ))}
                              </div>
                              </>
                            ))}
                                                
                            </section>
                          </> 
                          )} 
                </>
            );
        }
        
        export default ProgramComponent;