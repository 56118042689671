const $ = window.$;

export function openContactMenu() {
    $('.dpc-contact-submenu').removeClass('open');
    $('.offcanvas-collapse').removeClass('open');
    // $('body').toggleClass('opened-menu');
    // $('html').toggleClass('menu-opened');
    $('#contactSideOpen').removeClass('active');

    $('.dpc-conference').removeClass('open');
    $('.dpc-contact-submenu').toggleClass('open');
    // $('body').toggleClass('opened-submenu');
    $('#submenuSideOpen').removeClass('active');
    $('#contactSideOpen').toggleClass('active');

    $('#nav-contact-tab').removeClass('active');
    $('#nav-become-sponsor-tab').addClass('active');
    $('#nav-conference-tab').removeClass('active');
    
    $('#nav-contact-tab').attr('aria-selected', false);
    $('#nav-become-sponsor-tab').attr('aria-selected', true);
    $('#nav-conference-tab').attr('aria-selected', false);

    $('#nav-contact-tab').attr('tabindex', -1);
    $('#nav-become-sponsor-tab').removeAttr('tabindex');
    $('#nav-conference-tab').attr('tabindex', -1);


    $('.nav-conference-tab').removeClass('active show');
    $('.nav-become-sponsor-tab').addClass('active show');
    $('.nav-contact-tab').removeClass('active show');
}

export function openRegisterForConferenceMenu() {
    $('.dpc-contact-submenu').removeClass('open');
    $('.offcanvas-collapse').removeClass('open');
    // $('body').toggleClass('opened-menu');
    // $('html').toggleClass('menu-opened');
    $('#contactSideOpen').removeClass('active');

    $('.dpc-conference').removeClass('open');
    $('.dpc-contact-submenu').toggleClass('open');
    // $('body').toggleClass('opened-submenu');
    $('#submenuSideOpen').removeClass('active');
    $('#contactSideOpen').toggleClass('active');

    $('#nav-contact-tab').removeClass('active');
    $('#nav-become-sponsor-tab').removeClass('active');
    $('#nav-conference-tab').addClass('active');
    
    $('#nav-contact-tab').attr('aria-selected', false);
    $('#nav-become-sponsor-tab').attr('aria-selected', false);
    $('#nav-conference-tab').attr('aria-selected', true);

    $('#nav-contact-tab').attr('tabindex', -1);
    $('#nav-become-sponsor-tab').attr('tabindex', -1);
    $('#nav-conference-tab').removeAttr('tabindex');


    $('.nav-conference-tab').addClass('active show');
    $('.nav-become-sponsor-tab').removeClass('active show');
    $('.nav-contact-tab').removeClass('active show');
}

export function openGeneralQuestionsMenu() {
    $('.dpc-contact-submenu').removeClass('open');
    $('.offcanvas-collapse').removeClass('open');
    // $('body').toggleClass('opened-menu');
    // $('html').toggleClass('menu-opened');
    $('#contactSideOpen').removeClass('active');

    $('.dpc-conference').removeClass('open');
    $('.dpc-contact-submenu').toggleClass('open');
    // $('body').toggleClass('opened-submenu');
    $('#submenuSideOpen').removeClass('active');
    $('#contactSideOpen').toggleClass('active');

    $('#nav-contact-tab').addClass('active');
    $('#nav-become-sponsor-tab').removeClass('active');
    $('#nav-conference-tab').removeClass('active');
    
    $('#nav-contact-tab').attr('aria-selected', false);
    $('#nav-become-sponsor-tab').attr('aria-selected', false);
    $('#nav-conference-tab').attr('aria-selected', true);

    $('#nav-contact-tab').removeAttr('tabindex', -1);
    $('#nav-become-sponsor-tab').attr('tabindex', -1);
    $('#nav-conference-tab').attr('tabindex');

    $('.nav-conference-tab').removeClass('active show');
    $('.nav-become-sponsor-tab').removeClass('active show');
    $('.nav-contact-tab').addClass('active show');
}

