import configData from '../conf.json';
import { getValueFromArrayElement } from '../dataManager/findAliasValue';

const DownloadDocsComponent = ({page}) => {

    if(page === "Stars") {
        var activePageEntrepreneurStars = JSON.parse(localStorage.getItem("activePageEntrepreneurStars"));
        var starsYearList = JSON.parse(localStorage.getItem("entrepreneurStars"));
    }
    else {
        var activePageCampaign = JSON.parse(localStorage.getItem("activePageCampaignAndYear"));
        var activeConf = activePageCampaign.campaign;
        var activeConfYear = activePageCampaign.year;
        var conferenceYearsList = activeConf[0].children;
    }

    return (
        <>
        {page !== "Stars" ? (
            <>
                {/* <!-- Section Download Doc --> */}
                <section id="downloadDoc" className="dpc-section-alt dpc-section-download-doc">
                <div className="container-fluid">
                    <div className="dpc-download-doc-grid">
                        {/* <!-- <button type="button" className="btn btn-primary">Primary Button</button>
                        <p></p>
                        <button type="button" className="btn btn-outline-primary">Secondary Button</button>
                        <p></p>
                        <button type="button" className="btn btn-tertiary dpc-ico-r">Tertiary Button <svg className="dpc-svg-icon"><use xlinkHref="#arrow-right"/></svg></button>
                        <p></p>
                        <button type="button" className="btn dpc-btn-download"><span>Download Button<span><svg className="dpc-svg-icon"><use xlinkHref="#arrow-right"/></svg></button> --> */}

                        {conferenceYearsList && conferenceYearsList.map((confYear) => (
                            getValueFromArrayElement(confYear, "pdfDownloadDocument") !== "" && (
                                <>
                                    <a href={configData.UMBRACO_SERVER_URL + getValueFromArrayElement(confYear, "pdfDownloadDocument")} target="_blank" download>
                                        <button type="button" className="btn dpc-btn-download"><span>{getValueFromArrayElement(confYear, "pdfDocumentButtonTitle")}</span><svg className="dpc-svg-icon"><use xlinkHref="#arrow-download"/></svg></button>
                                    </a>
                                </>
                            )
                        ))}
                    </div>
                </div>
                </section>
            </>
            ) : ( 
            <>
                <section id="downloadDoc" className="dpc-section-alt dpc-section-download-doc">
                <div className="container-fluid">
                    <div className="dpc-download-doc-grid">

                        {starsYearList && starsYearList.map((year) => (
                            getValueFromArrayElement(year, "pdfDownloadDocumentStars") !== "" && (
                                <>
                                    <a href={configData.UMBRACO_SERVER_URL + getValueFromArrayElement(year, "pdfDownloadDocumentStars")} target="_blank" download>
                                        <button type="button" className="btn dpc-btn-download"><span>{getValueFromArrayElement(year, "pdfDocumentButtonStars")}</span><svg className="dpc-svg-icon"><use xlinkHref="#arrow-download"/></svg></button>
                                    </a>
                                </>
                            )
                        ))}
                    </div>
                </div>
                </section>
            </>)
        }
        </>
    );
};

export default DownloadDocsComponent;