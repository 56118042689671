import { useNavigate } from 'react-router-dom';
import { getImagePath, getValueFromArrayElement } from '../dataManager/findAliasValue';
import { getCampaignGallery } from '../dataManager/getGalleryImages';

const LastComferencesComponent = ({setActivePageCampaignAndYear}) => {

    var activePageCampaign = JSON.parse(localStorage.getItem("activePageCampaignAndYear"));
    var activeConf = activePageCampaign.campaign;
    var activeConfYear = activePageCampaign.year;
    var conferenceYearsList = activeConf[0].children;

    var filteredListWithoutCurrentYear = conferenceYearsList.filter(obj => !obj.properties.some(prop => prop.alias === "conferenceYear" && prop.value.value === new Date().getFullYear().toString()));

    const changeActivePageCampaignYear = (year) => {

        localStorage.setItem("activePageCampaignAndYear", JSON.stringify({campaign: activeConf, year: year}));
        setActivePageCampaignAndYear({campaign: activeConf, year: year});
    };

    let navigate = useNavigate(); 
    const navigateTo = (path) =>{ 
      navigate(path);
    }

    const navigateToGalerija = (path, year) =>{ 
        var activeConference = JSON.parse(localStorage.getItem("activePageCampaignAndYear"));
        var activeGallery = getCampaignGallery(activeConference.campaign[0], year)
        
        if(activeGallery.length > 0) {
            localStorage.setItem("linkGallery", JSON.stringify(true));
        }
        
        navigate(path);
      }

      var canShow  = filteredListWithoutCurrentYear.length === 1 && filteredListWithoutCurrentYear[0].properties.find(elem => elem.alias === "conferenceYear").value.value === activeConfYear;
    return (
        <>{ filteredListWithoutCurrentYear && filteredListWithoutCurrentYear.length > 0 && !canShow &&
        (
        <>
            {/* <!-- Section Past Events --> */}
            <section id="pastEvents" className="dpc-section dpc-section-past-events">
            <div className="container-fluid">
                {filteredListWithoutCurrentYear && filteredListWithoutCurrentYear.length > 0 && <h2 className="mb-lg-4 dpc-brd-b-1-primary-variant">Pretekle konference</h2>}
                <div className="dpc-past-events-grid">
                    {filteredListWithoutCurrentYear && filteredListWithoutCurrentYear.map((confYear) => (
                                !confYear.name.includes(activeConfYear) && (
                                    <div className="card">
                                        <div className="dpc-bg-image-holder cursor-pointer" style={{"backgroundImage": `url(${getImagePath(getValueFromArrayElement(confYear, "conferenceImage"))})`}} title="...">
                                            <img src={getImagePath(getValueFromArrayElement(confYear, "conferenceImage"))} className="img-fluid" alt="..." onClick={() => {var newYear = getValueFromArrayElement(confYear, "conferenceYear");
                                                                                                                                                                            changeActivePageCampaignYear(newYear);
                                                                                                                                                                            navigateTo(activeConf[0].url.toString() + newYear); 
                                                                                                                                                                            }}
                                            />
                                        </div>
                                        <div className="card-body">
                                            <h4 className="dpc-pointer dpc-c-accent-hover mb-2" onClick={() => {var newYear = getValueFromArrayElement(confYear, "conferenceYear");
                                                                changeActivePageCampaignYear(newYear);
                                                                navigateTo(activeConf[0].url.toString() + newYear); 
                                                                }}
                                            >
                                                {activeConf.name} {getValueFromArrayElement(confYear, "conferenceYear")}
                                            </h4>
                                            <h4 className="dpc-pointer dpc-c-accent-hover" onClick={() => {var newYear = getValueFromArrayElement(confYear, "conferenceYear");
                                                                changeActivePageCampaignYear(newYear);
                                                                navigateToGalerija(activeConf[0].url.toString() + newYear, newYear); 
                                                                }}
                                            >
                                                Galerija
                                            </h4>
                                            <a target="_blank" rel="noreferrer" href={getValueFromArrayElement(confYear, "conferenceYouTubeLink")} className="btn btn-tertiary dpc-ico-r ms-n175">YouTube <svg className="dpc-svg-icon"><use xlinkHref="#arrow-right"/></svg></a>
                                        </div>
                                    </div>
                                )
                            ))}
                    

                </div>
            </div>
            </section>
        </>
        )}</>
    );
};

export default LastComferencesComponent;