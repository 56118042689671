import { getAliasValue, getImagePath, getValueFromArrayElement } from '../dataManager/findAliasValue';

// import Swiper core and required modules
import { A11y, Autoplay, Navigation, Pagination, Scrollbar } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import { useNavigate } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const TestimonialsComponent = ({page}) => {
  
  var testimonials = JSON.parse(localStorage.getItem("testimonialsList"));
  var testimonialsList;
  if(page === "AboutUs") {
    testimonialsList = testimonials.filter(test => test.contentType.alias === "testimonialsFounders");
  } else {
    testimonialsList = testimonials.filter(test => test.contentType.alias === "testimonialsSponsors");
  }

  let navigate = useNavigate(); 
  const navigateTo = (path) =>{ 
    navigate(path);
  }

    return (
      <>
      {testimonialsList.length > 0 && (
        <>
            {/* <!-- Section Testimonials --> */}
          <section id="testimonials" className="dpc-section dpc-section-testimonials">
          <div className="container-fluid">
              <h2 className="dpc-brd-b-gray mb-lg-49">{testimonialsList[0].properties[0].value.value}</h2>
                <div className="row dpc-col-lg-auto-30-fl-p0">
                  <div className="col dpc-col-lg-30 dpc-brd-lg-r mb-4 mb-lg-0">
                    {/* <button type="button" className="btn btn-outline-primary" onClick={() => {openContactMenu();}}>{getAliasValue("homePage", "becomeASponsor")}</button> */}
                    <button type="button" className="btn btn-outline-primary" onClick={() => {navigateTo('/kontakt/postanite-sponzor');}}>{getAliasValue("homePage", "becomeASponsor")}</button>
                  </div>
                  <div className="col ps-lg-43 dpc-col-lg-70">

                  <Swiper
                      modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
                      slidesPerView={1}
                      spaceBetween={24}
                      className="dpc-testimonials-slider dpc-min-h-380px"
                      pagination={{
                        clickable: true,
                        disableOnInteraction: false,
                      }}
                      autoplay={{
                        delay: 10000
                      }}
                      breakpoints={{
                          1: {
                            slidesPerView: 1.048,
                            spaceBetween: 16,
                          },
                          576: {
                            slidesPerView: 1.048,
                            spaceBetween: 16,
                          },
                          768: {
                            slidesPerView: 1.034,
                            spaceBetween: 24,
                          },
                          992: {
                            slidesPerView: 1,
                          },
                      }}
                      >
                      {testimonialsList[0] && testimonialsList[0].children.map((testimonial) => (
                      <SwiperSlide key={testimonial.id}>
                              
                          <div className="container-fluid">
                              <div className="row dpc-testimonial-content">
                                  <div className="col-12 dpc-testimonial-message">
                                      <em>{(testimonial.properties.find(({ alias }) => alias === "testimonialText")).value.value}</em>
                                  </div>
                                  <div className="col-6 mt-3 mb-45 px-0 dpc-info">
                                      <h4 className="dpc-sponsor-name">{getValueFromArrayElement(testimonial, "testimonialAuthor")}</h4>
                                      <p className="dpc-sponsor-title">{getValueFromArrayElement(testimonial, "testimonialAuthorRole")}</p>
                                      <div className="dpc-sponsor-logo"><img src={getImagePath(getValueFromArrayElement(testimonial, "testimonialAuthorLogo"))} alt="..." loading="lazy" /></div>
                                  </div>
                                  <div className="col-6 dpc-testimonial-date mt-3 mb-45 px-0">
                                      {getValueFromArrayElement(testimonial, "testimonialDate")}
                                  </div>
                              </div>
                          </div>

                      </SwiperSlide>
                  ))} 
                  </Swiper>
                    </div>
                  </div>

                  {/* <div className="row">
                    <div className="col-4"></div>
                    <div className="col-8"></div>
                  </div> */}
              </div>

          </section>
    </>
      )}
    </>
    );
}

export default TestimonialsComponent;