import axios from 'axios';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import configData from '../conf.json';
import { getAliasValue, getImagePath, getImagePathDeliveryApi } from '../dataManager/findAliasValue';
import HeaderNavComponent from './HeaderNavComponent';

const HeaderComponent = ({page}) => {

  const [description, setDescription] = useState();
  const [descriptionAboutUs, setDescriptionAboutUs] = useState();
  const [headerData, setHeaderData] = useState();

  useEffect(() => {
  var getDescriptionURL = configData.REACT_URL + "/umbraco/delivery/api/v1/content/item/";
  var getDescriptionAboutUsUrl = configData.REACT_URL + "/umbraco/delivery/api/v1/content/item/about-us-page/";
              
    axios.get(getDescriptionURL, {
        headers: {
        accept: 'application/json'
        }
    }).then((response) => { 
        setDescription(response.data.properties.infoFirstSection.markup);
        setHeaderData(response.data.properties)
    });

    axios.get(getDescriptionAboutUsUrl, {
        headers: {
        accept: 'application/json'
        }
    }).then((response) => { 
      setDescriptionAboutUs(response.data.properties.aboutUsMainDescription.markup);
    });
  }, []);

    var classNameToAdd = 'dpc-text-22-sm-19';

    var htmlString;
    if(page !== "AboutUs") {
      htmlString = description;
    }
    else {
      htmlString = descriptionAboutUs;
    }
    
    var renderedHtml;
    if(htmlString) {

      var tempElement = document.createElement('div');
      tempElement.innerHTML = htmlString;

      if (tempElement.firstChild.classList.length > 0) {
          tempElement.firstChild.classList.remove(tempElement.firstChild.classList.item(0));
      }

      tempElement.firstChild.classList.add(classNameToAdd);
      var modifiedHtmlString = tempElement.innerHTML;
      renderedHtml = <div dangerouslySetInnerHTML={{ __html: modifiedHtmlString }} />;
    }
    return (
      <>
      {((page !== "AboutUs" && description) || (page === "AboutUs" && descriptionAboutUs)) && (
        <>
        <header id="home" className="dpc-header">
          <HeaderNavComponent page={"Home"}/>

          <Helmet>
              <meta name="description" content={page === "AboutUs" ? descriptionAboutUs.replace(/<[^>]*>/g, '') : description.replace(/<[^>]*>/g, '')} />
              {/* <meta property="og:title" content={page === "AboutUs" ? getAliasValue("aboutUsPage", "aboutUsMainTitle1") : getAliasValue("homePage", "titleFirstSection")} /> */}
              <meta property="og:title" content={page === "AboutUs" ? getAliasValue("aboutUsPage", "aboutUsMainTitle1") : headerData.titleFirstSection} />
              <meta property="og:description" content={page === "AboutUs" ? descriptionAboutUs.replace(/<[^>]*>/g, '') : description.replace(/<[^>]*>/g, '')} />
          </Helmet>

          <section className="dpc-section dpc-hero">
              <div className="container-fluid">
                  <div className="row my-4 pb-4 dpc-brd-b-1-primary-variant">
                    <div className="col dpc-title-holder">

                      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 952 524" style={{"enableBackground":"new 0 0 952 524"}} xmlSpace="preserve" className="dpc-svg-h1-title">
                      <style type="text/css">
                      {`.font-style {font-family:"Degular", Arial, Helvetica, sans-serif; font-size:161px; letter-spacing: -3px; line-height: 1; fill: #333333;} `}
                      </style>
                      <text transform="matrix(1 0 0 1 15.908 136.9998)" className="font-style">{page === "AboutUs" ? getAliasValue("aboutUsPage", "aboutUsMainTitle1") : headerData.titleFirstSection}</text>
                      <text transform="matrix(1 0 0 1 10.7561 307.1259)" className="font-style">{page === "AboutUs" ? getAliasValue("aboutUsPage", "aboutUsMainTitle2") : headerData.homePageMainTitle2}</text>
                      <text transform="matrix(1 0 0 1 4.3173 477.2519)" className="font-style">{page === "AboutUs" ? getAliasValue("aboutUsPage", "aboutUsMainTitle3") : headerData.homePageMainTitle3}</text>
                      </svg>

                    </div>
                    <div className="col dpc-text-holder col-sm-8 offset-lg-0 offset-sm-4 d-flex align-items-center ps-sm-4 ps-md-42 pb-0 py-sm-4">
                    {renderedHtml}
                    </div>
                  </div>
                  <div className="row pt-lg-3">
                      <div className="col px-0 dpc-fit-crop-image" title="DPC Hero Image">
                        <img src={`${page === "AboutUs" ? getImagePath(getAliasValue("aboutUsPage", "abouUsImage")) : getImagePathDeliveryApi(headerData.imageFirstSection.url)}`} className="img-fluid" alt="..." fetchpriority="high" />
                      </div>
                  </div>
              </div>
          </section>
        </header>
        </>
        )}
        </>
    );   
}

export default HeaderComponent;