
import { useEffect } from "react";
import AchievementsComponent from "./AchievementsComponent";
import FooterComponent from "./FooterComponent";
import HeaderComponent from "./HeaderComponent";
import OurTeamComponent from "./OurTeamComponent";
import OurValuesComponent from "./OurValuesComponent";
import SvgIconsComponent from "./SvgIconsComponent";

const AboutUsComponent = ({shouldLoad}) => {

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "O nas";
    }, []);

    return (

        <> 
            {shouldLoad && (
                <>
                    <SvgIconsComponent/>
                    
                    <HeaderComponent page={"AboutUs"}/>

                    <main id="main" className="dpc-main fade-in-1s">

                        <h1 className="dpc-visually-sleep">DPC</h1>

                        {/* <AboutUs/> */}

                        <AchievementsComponent/>

                        <OurTeamComponent/>

                        <OurValuesComponent/>

                        {/* <TestimonialsComponent page={"AboutUs"}/> */}

                    </main>

                    <FooterComponent/>
                </>
             )}
        </>
    
    );
};

export default AboutUsComponent;