import Spinner from 'react-bootstrap/Spinner';
import { getAliasValue, getImagePath, getValueFromArrayElement } from '../dataManager/findAliasValue';

const $ = window.$;

const EntrepreneurStarsActualComponent = (newsList, getRssFeedDataDone) => {

  var activePageEntrepreneurStars = JSON.parse(localStorage.getItem("activePageEntrepreneurStars"));
  var starsYear = activePageEntrepreneurStars.year;
  var year = activePageEntrepreneurStars.entrepreneurStars;
  var newsList = JSON.parse(localStorage.getItem("activeConfNews"));

const openAdditionalInfo = () => { 
    $('.dpc-bg-image-holder').toggleClass('dpc-show');
};

const handleScroll = (event) => {
    if(event.target.scrollTop !== 0) {
      $('.dpc-thin-scroll').addClass('dpc-tb-soft-shad');
    }
    else {
      $('.dpc-thin-scroll').removeClass('dpc-tb-soft-shad');
    }
  };

    return (
        <>
        { getValueFromArrayElement(year, "isActual") === true && 
          getAliasValue("entrepreneurStars", "rssFeedUrl") !== '' && 
          starsYear >= new Date().getFullYear() && ( 
          <>
            <section id="entrepreneurActual" className="dpc-section dpc-section-entrepreneur-actual">
              <div className="container-fluid">
                <h2 className="dpc-brd-b-gray mb-lg-4">{getAliasValue("homePage", "actualTitle")}</h2>
                  <div className="row dpc-col-lg-auto-46-fl-p0">
                    <div className="col pb-4 pb-lg-0 ps-lg-0 pe-lg-45 order-2 order-lg-0">
                      <div className="dpc-bg-image-holder" style={{"backgroundImage": `url(${getImagePath(getValueFromArrayElement(year, "actualImage"))})`}} title="DPC Actual Image">
                        <div className="dpc-img-hover-info">
                          <div className="dpc-info">
                            {getValueFromArrayElement(year, "actualDescription")}
                          </div>
                          <button type="button" className="btn btn-transparent-white dpc-btn-info-back" onClick={() => {openAdditionalInfo();}}>Nazaj</button>
                        </div>
                        <button type="button" className="btn btn-transparent-white dpc-btn-info-see-more" onClick={() => {openAdditionalInfo();}}>Poglej več</button>
                      </div>
                    </div>
                    <div className="col dpc-brd-lg-l dpc-col-lg-46 order-4 ps-lg-45 order-lg-0 dpc-thin-scroll" onScroll={handleScroll}>
                      {getRssFeedDataDone ? (
                        <ul className="nav flex-column dpc-v-links dpc-hov-underline">
                            { newsList && newsList.map((news) => (
                                  <li className="nav-item">
                                  <a className="nav-link" href={news.link} target="_blank">
                                    {/* <span className="dpc-bg-image-holder" style={{"backgroundImage": `url(${news.imageUrl})`}} title="DPC Actual Image"></span> */}
                                    <span className="dpc-bg-image-holder dpc-item-image-holder" title="...">
                                              <img src={news.imageUrl} alt="..." loading="lazy" />
                                    </span>
                                  <span className="dpc-text">{news.title}</span> <svg className="dpc-svg-icon"><use xlinkHref="#arrow-right"/></svg></a>
                                </li>
                          ))} 
                      </ul>
                      ): (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
                          <Spinner animation="border" />
                        </div>
                      )}
                  </div>
                  </div>
              </div>
            </section>

        </>)}
        </>
    );
};

export default EntrepreneurStarsActualComponent;