import axios from "axios";
import { useEffect, useRef, useState } from 'react';
import { getAliasValue, getConferenceValue, getImagePath, getValueFromArrayElement } from '../dataManager/findAliasValue';

import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import configData from '../conf.json';
import { getRssFeedNews } from '../dataManager/rssFeedNewsDataManager';

const $ = window.$;

const ActualComponentHomePage = () => {

  let navigate = useNavigate(); 
  const navigateTo = (path) =>{ 
    navigate(path);
  }

  const [activeConference, setActiveConference] = useState();
  const [activeStars, setActiveStars] = useState(false);
  const [done, setDone] = useState(false);
  const [activeNewsList, setActiveNewsList] = useState();
  const [getRssFeedDataDone, setGetRssFeedDataDone] = useState(false);

  var entrepreneurStars = JSON.parse(localStorage.getItem("entrepreneurStars"));
  var currentYearEntrepreneurStars = entrepreneurStars.find(obj => obj.properties.some(prop => prop.alias === 'year' && prop.value.value === new Date().getFullYear().toString()));
  var isActiveEntrepreneurStars;
  if (currentYearEntrepreneurStars) {
    isActiveEntrepreneurStars = currentYearEntrepreneurStars.properties.some(prop => prop.alias === 'isActual' && prop.value.value === true);
  }

  // Change class conference actual navbar
  useEffect(() => {
    setClassName(activeConference);
    fetchNews(activeConference);
  }, [activeConference]);

  useEffect(() => {
      setActiveNewsList(activeNewsList);
      setGetRssFeedDataDone(true);
  }, [activeNewsList]);

  useEffect(() => {
    setGetRssFeedDataDone(false);
}, []);

  var conferenceList = JSON.parse(localStorage.getItem("conferences"));

  var activeconferenceList = [];
  var activeConferenceAndYearList = [];
  useEffect(() => {
    var result;
    if(conferenceList.length !== 0) {
        conferenceList.map(conf => {
            conf.children.map(confYear => {
                var isActive = confYear.properties.find(({ alias }) => alias === "conferenceIsActual");
                if(isActive.value.value === true) {
                    result = confYear;
                    if(result != null) {
                      if(!activeconferenceList.includes(conf)) {
                        // activeconferenceList.push(conf);
                        const url = configData.REACT_URL + "/umbraco/delivery/api/v1/content/item/" + result.url;
                        return axios.get(url, {
                          headers: {
                            accept: 'application/json'
                          }
                        }).then((response) => {

                          if(response.data.properties.startEndDate !== null) {

                            // Current date
                            let newDate = new Date().toISOString().slice(0, 10);

                            // Check if conference start date is in the past or today
                            if(newDate >= response.data.properties.startEndDate.slice(0, 10)) {

                              if(response.data.properties.endDate !== null) {

                                // Current date
                                let newDate = new Date().toISOString().slice(0, 10);
    
                                // Check if conference and date is not in the past
                                if(newDate <= response.data.properties.endDate.slice(0, 10)) {
                                  activeconferenceList.push(conf);
                                  activeConferenceAndYearList.push({conference: conf, year: confYear});
                                  localStorage.setItem("actualConferenceList", JSON.stringify(activeconferenceList));
                                  localStorage.setItem("activeConferenceAndYearList", JSON.stringify(activeConferenceAndYearList));
                                  localStorage.setItem("activeConference", JSON.stringify(activeconferenceList[0]));
                                  setActiveConference(activeconferenceList[0]);
                                  setDone(true);
                                }
                              }
                              else {
                                activeconferenceList.push(conf);
                                activeConferenceAndYearList.push({conference: conf, year: confYear});
                                localStorage.setItem("actualConferenceList", JSON.stringify(activeconferenceList));
                                localStorage.setItem("activeConferenceAndYearList", JSON.stringify(activeConferenceAndYearList));
                                localStorage.setItem("activeConference", JSON.stringify(activeconferenceList[0]));
                                setActiveConference(activeconferenceList[0]);
                                setDone(true);
                              }
                            }
                            
                          } else if(response.data.properties.endDate !== null) {

                            // Current date
                            let newDate = new Date().toISOString().slice(0, 10);

                            // Check if conference and date is not in the past
                            if(newDate <= response.data.properties.endDate.slice(0, 10)) {
                              activeconferenceList.push(conf);
                              activeConferenceAndYearList.push({conference: conf, year: confYear});
                              localStorage.setItem("actualConferenceList", JSON.stringify(activeconferenceList));
                              localStorage.setItem("activeConferenceAndYearList", JSON.stringify(activeConferenceAndYearList));
                              localStorage.setItem("activeConference", JSON.stringify(activeconferenceList[0]));
                              setActiveConference(activeconferenceList[0]);
                              setDone(true);
                            }
                          }
                          else {

                            let newDate = new Date().getFullYear();
                            var year = confYear.properties.find(({ alias }) => alias === "conferenceYear").value.value;

                            if(year >= newDate) {
                              activeconferenceList.push(conf);
                              activeConferenceAndYearList.push({conference: conf, year: confYear});
                              localStorage.setItem("actualConferenceList", JSON.stringify(activeconferenceList));
                              localStorage.setItem("activeConferenceAndYearList", JSON.stringify(activeConferenceAndYearList));
                              localStorage.setItem("activeConference", JSON.stringify(activeconferenceList[0]));
                              setActiveConference(activeconferenceList[0]);
                              setDone(true);
                            }
                          }
                        });
                      }
                    }
                }
                
            });
        });
    }
    
  }, []);

  const conferenceYear = new Date().getFullYear().toString();

  const changeActiveConference = (conference) => {

      setActiveStars(false);
      localStorage.setItem("activeConference", JSON.stringify(conference));
      setActiveConference(conference);
      fetchNews(conference);
   
      };

      const changeActiveConferenceStars = (conference) => {

        setActiveStars(true);
        fetchNewsStars();
              
      };

  const setClassName = (conference) => {

    var activeConf = JSON.parse(localStorage.getItem("activeConference"));

    if(activeConf.name === conference) {
      return "nav-link active";
    }
    else if(activeStars) {
      return "nav-link active";
    }
    else {
      return "nav-link";
    }
  };

  const fetchNews = async (conf) => {

    setGetRssFeedDataDone(false);
          var newsList = await getRssFeedNews(getValueFromArrayElement(conf, "rssFeedUrl"));
        setActiveNewsList(JSON.parse(localStorage.getItem("activeConfNews")));
  }

  const fetchNewsStars = async () => {

    setGetRssFeedDataDone(false);
    var newsList = await getRssFeedNews(getAliasValue("entrepreneurStars", "rssFeedUrl"));
    setActiveNewsList(JSON.parse(localStorage.getItem("activeConfNews")));
  }

  const openAdditionalInfo = () =>{ 
      $('.dpc-bg-image-holder').toggleClass('dpc-show');
  };

  const handleScroll = (event) => {
    if(event.target.scrollTop !== 0) {
      $('#addScrollClass').addClass('dpc-tb-soft-shad');
    }
    else {
      $('#addScrollClass').removeClass('dpc-tb-soft-shad');
    }
  };

  const tabRef = useRef(null);

  const horizontalScroll = (index) =>{ 
    
    const tabWidth = tabRef.current.offsetWidth;
    if(index === 100) {
      $('.navhorizontal2').scrollLeft(10000000);
    }
    else {
      $('.navhorizontal2').scrollLeft(tabWidth - (tabWidth /index));
    }

};
  
    return (
      <>
      {/* If there are active conferences */}
      {done && activeConference && (
          <>
            <section id="actual" className="dpc-section dpc-section-actual">
            <div className="container-fluid">
              <h2 className="dpc-brd-b-gray mb-lg-4">{getAliasValue("homePage", "actualTitle")}</h2>
                <div className="row dpc-col-lg-auto-46-fl-p0">

                  {!activeStars ? (
                    // If entrepreneur stars are not active
                    <>

                  <div className="col-12 pt-lg-2 pb-lg-4 pos-rel-down-lg mt-n3 mb-2 mb-lg-3" ref={tabRef}>
                  <div className="nav-scroller">
                        <div className="">
                          <menu id="navhorizontal" className="nav navhorizontal2" aria-label="Secondary navigation">
                          
                          {JSON.parse(localStorage.getItem("actualConferenceList")) &&
                          JSON.parse(localStorage.getItem("actualConferenceList")).length > 1 &&
                           (JSON.parse(localStorage.getItem("actualConferenceList"))).map((conf, index) => (
                              <a className={setClassName(conf.name)} onClick={() => { changeActiveConference(conf);  horizontalScroll(index);}}><svg className="dpc-svg-icon"><use xlinkHref="#arrow-right"/></svg>{conf.name}</a>
                          ))}
                          {JSON.parse(localStorage.getItem("actualConferenceList")) &&
                          JSON.parse(localStorage.getItem("actualConferenceList")).length === 1 &&
                           (JSON.parse(localStorage.getItem("actualConferenceList"))).map((conf, index) => (
                              <a className={setClassName(conf.name)} onClick={() => { navigateTo(conf.url.toString() + new Date().getFullYear().toString()); changeActiveConference(conf)}}><svg className="dpc-svg-icon"><use xlinkHref="#arrow-right"/></svg>{conf.name}</a>
                          ))} 
                          {isActiveEntrepreneurStars && 
                            <a className={setClassName("entrepreneurStars")} onClick={() => {changeActiveConferenceStars(); horizontalScroll(100);}}><svg className="dpc-svg-icon"><use xlinkHref="#arrow-right"/></svg>{getAliasValue("entrepreneurStars", "starsTitle")}</a>
                          }
                          </menu>
                    </div>
                  </div>
                  </div>
                  <div className="col-lg-6 pb-5 pos-rel-down-lg mt-n3 mb-2 mb-lg-3">
                  </div>
                  <div className="col-lg-6 order-3 order-lg-0 mb-3 px-0">
                    <div className="d-flex justify-content-start justify-content-sm-end">
                      <button type="button" onClick={() => {navigateTo(activeConference.url.toString() + new Date().getFullYear().toString()); changeActiveConference(activeConference)}} className="btn btn-primary me-2 mt-3 mt-sm-0">{getAliasValue("homePage", "actualAboutTheCampaign")}</button>
                      {/* <button type="button" onClick={() => {openContactMenu();}} className="btn btn-outline-primary mt-3 mt-sm-0">{getAliasValue("homePage", "actualBecomeASponsor")}</button> */}
                      <button type="button" onClick={() => {navigateTo('/kontakt/postanite-sponzor')}} className="btn btn-outline-primary mt-3 mt-sm-0">{getAliasValue("homePage", "actualBecomeASponsor")}</button>
                    </div>
                    <span className="d-block mt-4 text-md-end">{getAliasValue("homePage", "actualRssTitle")}</span>
                  </div>
                  <div className="col pb-4 pb-lg-0 ps-lg-0 pe-lg-41 order-2 order-lg-0">
                    <div className="dpc-bg-image-holder dpc-fit-crop-image" title="DPC Actual Image">
                      <img src={getImagePath(getConferenceValue(activeConference.name, conferenceYear, "actualConferenceImage"))} alt="..." loading="lazy" />
                      <div className="dpc-img-hover-info">
                        <div className="dpc-info">
                          {getConferenceValue(activeConference.name, conferenceYear, "actualConferenceText")}
                        </div>
                        <button type="button" className="btn btn-transparent-white dpc-btn-info-back" onClick={() => {openAdditionalInfo();}}>Nazaj</button>
                      </div>
                        <button type="button" className="btn btn-transparent-white dpc-btn-info-see-more" onClick={() => {openAdditionalInfo();}}>Poglej več</button>
                    </div>
                  </div>
                  </>
                  )
                  : 
                  (
                    // If Entrepreneur stars are active
                    <>
                    	  <div className="col-12 pt-lg-2 pb-lg-4 pos-rel-down-lg mt-n3 mb-2 mb-lg-3" ref={tabRef}>
                          <div className="nav-scroller">
                        <div className="">
                          <menu id="navhorizontal" className="nav navhorizontal2" aria-label="Secondary navigation">
                          {JSON.parse(localStorage.getItem("actualConferenceList")) && JSON.parse(localStorage.getItem("actualConferenceList")).map((conf, index) => (
                              <a className="nav-link" onClick={() => { changeActiveConference(conf); horizontalScroll(index);}}><svg className="dpc-svg-icon"><use xlinkHref="#arrow-right"/></svg>{conf.name}</a>
                          ))} 
                          {isActiveEntrepreneurStars && 
                           JSON.parse(localStorage.getItem("actualConferenceList")).length > 0 &&
                            <a className="nav-link active" onClick={() => {changeActiveConferenceStars(); horizontalScroll(100);}}><svg className="dpc-svg-icon"><use xlinkHref="#arrow-right"/></svg>{getAliasValue("entrepreneurStars", "starsTitle")}</a>
                          }
                          </menu>
                    </div>
                  </div>
                 </div>
                    <div className="col-lg-6 pb-5 pos-rel-down-lg mt-n3 mb-2 mb-lg-3">
                   </div>
                  <div className="col-lg-6 order-3 order-lg-0 mb-3 px-0">
                    <div className="d-flex justify-content-start justify-content-sm-end">
                      <button type="button" onClick={() => {navigateTo('/podjetniske-zvezde/' + new Date().getFullYear().toString()); changeActiveConferenceStars()}} className="btn btn-primary me-2 mt-3 mt-sm-0">{getAliasValue("homePage", "actualAboutTheCampaign")}</button>
                      {/* <button type="button" onClick={() => {openContactMenu();}} className="btn btn-outline-primary mt-3 mt-sm-0">{getAliasValue("homePage", "actualBecomeASponsor")}</button> */}
                      <button type="button" onClick={() => {navigateTo('/kontakt/postanite-sponzor')}} className="btn btn-outline-primary mt-3 mt-sm-0">{getAliasValue("homePage", "actualBecomeASponsor")}</button>
                    </div>
                    <span className="d-block mt-4 text-md-end">{getAliasValue("homePage", "actualRssTitle")}</span>
                  </div>
                  <div className="col pb-4 pb-lg-0 ps-lg-0 pe-lg-41 order-2 order-lg-0">
                    <div className="dpc-bg-image-holder" style={{"backgroundImage": `url(${getImagePath(getValueFromArrayElement(currentYearEntrepreneurStars, "actualImage"))})`}}  title="DPC Actual Image">
                      <img src={getImagePath(getValueFromArrayElement(currentYearEntrepreneurStars, "actualImage"))} className="img-fluid" alt="..."/>
                      <div className="dpc-img-hover-info">
                        <div className="dpc-info">
                          {getValueFromArrayElement(currentYearEntrepreneurStars, "actualDescription")}
                        </div>
                        <button type="button" className="btn btn-transparent-white dpc-btn-info-back" onClick={() => {openAdditionalInfo();}}>Nazaj</button>
                      </div>
                        <button type="button" className="btn btn-transparent-white dpc-btn-info-see-more" onClick={() => {openAdditionalInfo();}}>Poglej več</button>
                    </div>
                  </div>
                  </>
                  )}

                  <div id="addScrollClass" className="col dpc-brd-lg-l dpc-col-lg-46 order-4 ps-lg-45 order-lg-0 dpc-thin-scroll" onScroll={handleScroll}> 
                  { getRssFeedDataDone ? (
                  <> 
                    
                      <ul className="nav flex-column dpc-v-links dpc-hov-underline">
                      {/* {getActiveConferenceNews(activeConference, conferenceYear) && getActiveConferenceNews(activeConference, conferenceYear).map((news) => ( */}
                        {activeNewsList && activeNewsList.map((news) => (
                                <li className="nav-item">
                                  <a className="nav-link" href={news.link} target="_blank">
                                    {/* <span className="dpc-bg-image-holder" style={{"backgroundImage": `url(${news.imageUrl})`}} title="DPC Actual Image"></span> */}
                                    <span className="dpc-bg-image-holder dpc-item-image-holder" title="...">
                                              <img src={news.imageUrl} alt="..." loading="lazy" />
                                    </span>
                                    <span className="dpc-text">{news.title}</span> <svg className="dpc-svg-icon"><use xlinkHref="#arrow-right"/></svg>
                                  </a>
                                </li>
                        ))} 
                    </ul>      
                  
                  </>
                  ) :
                  (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
                    <Spinner animation="border" />
                    </div>
                  )}
                  </div>
                </div>
            </div>
          </section> 
        </>
        )}

      {/* If there are no active conference, just entrepreneur stars */}
      {!activeConference && currentYearEntrepreneurStars && isActiveEntrepreneurStars && (
                <>
                  <section id="actual" className="dpc-section dpc-section-actual">
                  <div className="container-fluid">
                    <h2 className="dpc-brd-b-gray mb-lg-4">{getAliasValue("homePage", "actualTitle")}</h2>
                      <div className="row dpc-col-lg-auto-46-fl-p0">

                        {
                        (<>
                        	    <div className="col-12 pt-lg-2 pb-lg-4 pos-rel-down-lg mt-n3 mb-2 mb-lg-3" ref={tabRef}>
                              <div className="nav-scroller">
                              <div className="">
                                <menu className="nav" aria-label="Secondary navigation">
                                {isActiveEntrepreneurStars && 
                                  // <a className="nav-link active" onClick={() => {changeActiveConferenceStars();}}><svg className="dpc-svg-icon"><use xlinkHref="#arrow-right"/></svg>{getAliasValue("entrepreneurStars", "starsTitle")}</a>
                                  <a className="nav-link active" onClick={() => {navigateTo('/podjetniske-zvezde/' + new Date().getFullYear().toString()); changeActiveConferenceStars()}}>
                                    <svg className="dpc-svg-icon"><use xlinkHref="#arrow-right"/></svg>
                                    {getAliasValue("entrepreneurStars", "starsTitle")}
                                  </a>
                                  
                                }
                                </menu>
                              </div>
                            </div>
                            </div>
                           <div className="col-lg-6 pb-5 pos-rel-down-lg mt-n3 mb-2 mb-lg-3">
                           </div>
                        <div className="col-lg-6 order-3 order-lg-0 mb-3 px-0">
                          <div className="d-flex justify-content-start justify-content-sm-end">
                            <button type="button" onClick={() => {navigateTo('/podjetniske-zvezde/' + new Date().getFullYear().toString()); changeActiveConferenceStars()}} className="btn btn-primary me-2 mt-3 mt-sm-0">{getAliasValue("homePage", "actualAboutTheCampaign")}</button>
                            {/* <button type="button" onClick={() => {openContactMenu();}} className="btn btn-outline-primary mt-3 mt-sm-0">{getAliasValue("homePage", "actualBecomeASponsor")}</button> */}
                            <button type="button" onClick={() => {navigateTo('/kontakt/postanite-sponzor')}} className="btn btn-outline-primary mt-3 mt-sm-0">{getAliasValue("homePage", "actualBecomeASponsor")}</button>
                          </div>
                          <span className="d-block mt-4 text-md-end">{getAliasValue("homePage", "actualRssTitle")}</span>
                        </div>
                        <div className="col pb-4 pb-lg-0 ps-lg-0 pe-lg-41 order-2 order-lg-0">
                          <div className="dpc-bg-image-holder" style={{"backgroundImage": `url(${getImagePath(getValueFromArrayElement(currentYearEntrepreneurStars, "actualImage"))})`}}  title="DPC Actual Image">
                            <img src={getImagePath(getValueFromArrayElement(currentYearEntrepreneurStars, "actualImage"))} className="img-fluid" alt="..."/>
                            <div className="dpc-img-hover-info">
                              <div className="dpc-info">
                                {getValueFromArrayElement(currentYearEntrepreneurStars, "actualDescription")}
                              </div>
                              <button type="button" className="btn btn-transparent-white dpc-btn-info-back" onClick={() => {openAdditionalInfo();}}>Nazaj</button>
                            </div>
                              <button type="button" className="btn btn-transparent-white dpc-btn-info-see-more" onClick={() => {openAdditionalInfo();}}>Poglej več</button>
                          </div>
                        </div>
                        </>
                        )}

                        <div id="addScrollClass" className="col dpc-brd-lg-l dpc-col-lg-46 order-4 ps-lg-45 order-lg-0 dpc-thin-scroll" onScroll={handleScroll}> 
                        { getRssFeedDataDone ? (
                        <> 
                          
                            <ul className="nav flex-column dpc-v-links dpc-hov-underline">
                            {/* {getActiveConferenceNews(activeConference, conferenceYear) && getActiveConferenceNews(activeConference, conferenceYear).map((news) => ( */}
                              {activeNewsList && activeNewsList.map((news) => (
                                      <li className="nav-item">
                                        <a className="nav-link" href={news.link} target="_blank">
                                          {/* <span className="dpc-bg-image-holder" style={{"backgroundImage": `url(${news.imageUrl})`}} title="DPC Actual Image"></span> */}
                                          <span className="dpc-bg-image-holder dpc-item-image-holder" title="...">
                                              <img src={news.imageUrl} alt="..." loading="lazy" />
                                          </span>
                                          <span className="dpc-text">{news.title}</span> <svg className="dpc-svg-icon"><use xlinkHref="#arrow-right"/></svg>
                                        </a>
                                      </li>
                              ))} 
                          </ul>      
                        
                        </>
                        ) :
                        (
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
                            <Spinner animation="border" />
                          </div>
                        )}
                        </div>
                      </div>
                  </div>
                </section> 
              </>
              )}
        </>
    );
}

export default ActualComponentHomePage;