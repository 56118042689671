import { useEffect } from "react";
import ActualComponentHomePage from "./ActualComponentHomePage";
import FooterComponent from "./FooterComponent";
import HeaderComponent from "./HeaderComponent";
import IncomingConferenceComponent from "./IncomingConferenceComponent";
import OurWorkComponent from "./OurWorkComponent";
import SvgIconsComponent from "./SvgIconsComponent";
import TestimonialsComponent from "./TestimonialsComponent";

// import { lazy } from 'react';

// const TestimonialsComponent = lazy(() => import('./TestimonialsComponent.js'));

const FirstPageComponent = ({pagesData, shouldLoad}) => {

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "DPC";
    }, []);

    var incomingConference = localStorage.getItem("incomingConference");
    var incomingConferenceWithConferenceNameAndIncomingYear = localStorage.getItem("incomingConferenceWithConfNameAndObject");

    // var dataPages = JSON.parse(localStorage.getItem("pagesData"));
    // if(!pagesData){
    //     return (<div id="preloader"></div>);
    // }
    
    return (
        <>
        {/* {shouldLoad && ( */}
        <>
            <SvgIconsComponent/>

            <HeaderComponent page={"Home"}/>
            {shouldLoad &&
            <>
                <main id="main" className="dpc-main fade-in-1s">

                    <h1 className="dpc-visually-sleep">DPC</h1>

                    <OurWorkComponent/>
                    <ActualComponentHomePage/>
                    {/* Conditionally render IncomingConferenceComponent */}
                    {incomingConference !== 'undefined' && incomingConferenceWithConferenceNameAndIncomingYear !== 'undefined' && (
                        <IncomingConferenceComponent />
                    )}
                    <TestimonialsComponent page={"Home"}/>    
                </main>
            

                <FooterComponent/>
            </>
            }
        </>
    {/* )} */}
    </>
    );

}

export default FirstPageComponent;