export function getCorrectUrl(urlString) {

    var resultString = urlString.toLowerCase()
                                .replace(/[šćčđž]/g, match => ({
                                    'š': 's',
                                    'ć': 'c',
                                    'č': 'c',
                                    'đ': 'd',
                                    'ž': 'z',
                                })[match])
                                .replace(/[^\w\s-]|_/g, '')
                                .replace(/\s+/g, ' ')      
                                .replace(/\s/g, '-')
                                .replace(/-+/g, '-');

    return resultString;
}