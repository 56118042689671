import { getConferenceValue, getImagePath } from '../dataManager/findAliasValue';

const LastYearsCampaignReview = ({activeConference, conferenceYear}) => {

    return (
        <>
        {/* Section Last Year's Review */}
          <section id="last-year-review" className="dpc-section dpc-last-year-review">
            <div className="container-fluid">
              <h2 className="mb-4 mb-sm-45 dpc-brd-b-1-primary-variant">{getConferenceValue(activeConference.name, conferenceYear, "lastYearReviewTitle")}</h2>

              <div className="row dpc-col-md-auto-57-fl-p0">
                <div className="col dpc-col-md-42 d-md-flex flex-md-column justify-content-md-end">
                  <h4>{getConferenceValue(activeConference.name, conferenceYear, "firstParagraphTitle")}</h4>
                      <p className="dpc-text-25-lg-19-sm-17 mb-md-0">{getConferenceValue(activeConference.name, conferenceYear, "fistParagraph")}</p>
                </div>
                <div className="col dpc-col-md-57 px-0 order-first order-md-0 mb-3 mb-md-0">
                  <div className="dpc-bg-image-holder dpc-img-01" style={{"backgroundImage": `url(${getImagePath(getConferenceValue(activeConference.name, conferenceYear, "firstParagraphImage"))})`}} title="DPC Last Year's Review Image">
                    <img src="assets/img/pregled-lanskega-leta-01.png" className="img-fluid" alt="..."/>
                  </div>
                </div>
              </div>

              <div className="row mt-3 mt-md-4 mt-lg-5">
                <div className="dpc-bg-image-holder dpc-img-02" style={{"backgroundImage": `url(${getImagePath(getConferenceValue(activeConference.name, conferenceYear, "secondParagraphImage"))})`}} title="DPC Last Year's Review Image">
                  <img src="assets/img/pregled-lanskega-leta-02.png" className="img-fluid" alt="..."/>
                </div>
              </div>
              <div className="row mt-3 mt-lg-4">
                <div className="col col-md-10 col-lg-8 col-xl-5 px-0">
                  <h4>{getConferenceValue(activeConference.name, conferenceYear, "secondParagraphTitle")}</h4>
                  <p className="dpc-text-25-lg-19-sm-17 ps-0">{getConferenceValue(activeConference.name, conferenceYear, "secondParagraph")}</p> 
                </div>
              </div>

            </div>
          </section>
        </>
    );

};

export default LastYearsCampaignReview;