import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import { getAliasValue, getConferenceValue, getImagePath, getList, getValueFromArrayElement } from "../dataManager/findAliasValue";
import FooterComponent from "./FooterComponent";
import HeaderNavComponent from "./HeaderNavComponent";
import SvgIconsComponent from "./SvgIconsComponent";

const $ = window.$;

const ConferenceListComponent = ({shouldLoad}) => {

  const [activeName, setActiveName] = useState(null);
  const [activeStars, setActiveStars] = useState(false);
  const [actualConferences, setActualConferences] = useState();
  const [conferenceList, setConferenceList] = useState([]);
  const [entrepreneurStars, setEntrepreneurStars] = useState([]);
  const [activePageEntrepreneurStars, setActivePageEntrepreneurStars] = useState([]);
  var location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Konference";
  }, []);

  useEffect(() => {
    const pathname = location.pathname;

    if(pathname.includes('podjetniske-zvezde')){
      setActiveName(getValueFromArrayElement(activePageEntrepreneurStars.entrepreneurStars, "title"));
    }
    else {
      const parts = pathname.split('/');
      const nameFromUrl = parts[parts.indexOf('poslovna-kampanja') + 1];
      setActiveName(decodeURIComponent(nameFromUrl));
    }
    
  }, [location.pathname]);

  useEffect(() => {
    setActualConferences(actualConferences);
    
  }, [actualConferences]);

  useEffect(() => {
    setActualConferences(JSON.parse(localStorage.getItem("conferencesActiveConferencesPage")));
    
  }, []);

  useEffect(() => {
      if(shouldLoad) {

        var entrepreneurStarsTemp = getList("entrepreneurStars");
        setEntrepreneurStars(entrepreneurStarsTemp)
        localStorage.setItem("activePageEntrepreneurStars", JSON.stringify({entrepreneurStars: entrepreneurStarsTemp[0].children[0], year: new Date().getFullYear()}));
        
        setConferenceList(JSON.parse(localStorage.getItem("conferences")));

        setActivePageEntrepreneurStars(JSON.parse(localStorage.getItem("activePageEntrepreneurStars")))

        var currentYearEntrepreneurStars = entrepreneurStars.find(obj => obj.properties.some(prop => prop.alias === 'year' && prop.value.value === new Date().getFullYear().toString()));
        var isActiveEntrepreneurStars;
        if (currentYearEntrepreneurStars) {
          isActiveEntrepreneurStars = currentYearEntrepreneurStars.properties.some(prop => prop.alias === 'isActual' && prop.value.value === true);
        }

        if(isActiveEntrepreneurStars) {
          setActiveStars(true);
        }
    }
    }, [shouldLoad]);

  let navigate = useNavigate(); 
    const navigateTo = (path) =>{ 
      navigate(path);
    }
  
  // var activeConf = JSON.parse(localStorage.getItem("activePageCampaignAndYear")).campaign[0].name;

  const customMethod = (name) => {
    return name === activeName ? 'active' : '';
  };

  const setClassName = (conf) => {
    var classResult = "";
    var found;
    var confList = JSON.parse(localStorage.getItem("conferencesActiveConferencesPage"))
    if(confList){
      found = confList.find(c => c.name === conf);
    }

    var classResult = "";
    var hasImage = getConferenceValue(conf, new Date().getFullYear().toString(), "conferenceLogo");

    if(found)
      classResult += " dpc-active-badge";
    
    if(hasImage && hasImage !== "Can't find conference year...") {
      classResult += " dpc-has-image"
    }
  
      return classResult;
  };

  const setClassNameStars = (entrStar) => {

    var classResult = "";
    var hasImage = getValueFromArrayElement(entrStar, "logo");

    if(activeStars)
      classResult += " dpc-active-badge";

    if(hasImage ) {
      classResult += " dpc-has-image"
    }
      return classResult;
  };

    return (
      <> {shouldLoad && (
          <>

            <SvgIconsComponent/>

            <HeaderNavComponent page="ConferencePage"/>

            <Helmet>
                <meta name="description" content="Konference" />
                <meta property="og:title" content="Konference" />
                <meta property="og:description" content="Konference" />
            </Helmet>

            <main id="main" className="dpc-main">

              <section id="" className="dpc-section dpc-section-conference-list dpc-pt-nav-distance min-h-85-screen-h">
                <div className="container-fluid">
                
                  <h2 className="mb-lg-4 text-center dpc-h2-smaller">Konference</h2>

                    <div className="dpc-conference-list-items-container">

                      {conferenceList && conferenceList.map((conf, index) => (
                          <div key={index} className={`dpc-conference-list-item ${setClassName(conf.name)}`} onClick={() => {navigateTo(conf.url.toString() + new Date().getFullYear().toString()); }}>
                            <div className="dpc-item-image"><img src={
                                (getConferenceValue(conf.name, new Date().getFullYear().toString(), "conferenceLogo") !== null 
                                && getConferenceValue(conf.name, new Date().getFullYear().toString(), "conferenceLogo") !== "Can't find conference year...")
                                ? getImagePath(getConferenceValue(conf.name, new Date().getFullYear().toString(), "conferenceLogo"))
                                : ''
                              } ></img>
                            </div>
                            <div className="dpc-item-text">
                              <span className="dpc-item-title" >{conf.name}</span>
                              <span className="dpc-item-subtitle" >
                                {(getConferenceValue(conf.name, new Date().getFullYear().toString(), "conferenceDate") !== "Can't find conference year..." ? 
                                getConferenceValue(conf.name, new Date().getFullYear().toString(), "conferenceDate")
                                : '')} 
                                {((getConferenceValue(conf.name, new Date().getFullYear().toString(), "conferencePlace") !== "Can't find conference year...") ? 
                                ', ' + getConferenceValue(conf.name, new Date().getFullYear().toString(), "conferencePlace")
                                : '')} 
                              </span>
                            </div>
                          </div>
                        ))}
                        {
                          <><div> {localStorage.getItem("activePageEntrepreneurStars").entrepreneurStars}</div>
                          <div className={`dpc-conference-list-item ${setClassNameStars(JSON.parse(localStorage.getItem("activePageEntrepreneurStars")).entrepreneurStars)}`} onClick={() => {navigateTo('/podjetniske-zvezde/' + getValueFromArrayElement(activePageEntrepreneurStars.entrepreneurStars, "year"))}}>
                            <div className="dpc-item-image"><img src={
                              getImagePath(getValueFromArrayElement(JSON.parse(localStorage.getItem("activePageEntrepreneurStars")).entrepreneurStars, "logo"))
                              ? getImagePath(getValueFromArrayElement(JSON.parse(localStorage.getItem("activePageEntrepreneurStars")).entrepreneurStars, "logo"))
                              : ''
                            }>
                            </img></div>
                            <div className="dpc-item-text">
                              <span className="dpc-item-title">{getAliasValue("entrepreneurStars", "starsTitle")}</span>
                              <span className="dpc-item-subtitle">{getValueFromArrayElement(JSON.parse(localStorage.getItem("activePageEntrepreneurStars")).entrepreneurStars, "date")}, {getValueFromArrayElement(JSON.parse(localStorage.getItem("activePageEntrepreneurStars")).entrepreneurStars, "place")}</span>
                            </div>
                          </div>
                          </>
                        }

                    </div>

                </div>
              </section>

            </main>
              
            <FooterComponent/>
          </>
      )}
      </>
    );
};

export default ConferenceListComponent;