import { useLocation, useNavigate } from 'react-router-dom';
import { getAliasValue,getConferenceValue,getImagePath, getImagePathDeliveryApi } from '../dataManager/findAliasValue';
import { useEffect, useState } from 'react';
import axios from 'axios';
import configData from '../conf.json';

const $ = window.$;



const HeaderNavComponent = ({page}) => {


  const [navBarData, setNavbarData] = useState();

  var location = useLocation();
  var openedTab = 0;

  useEffect(() => {
    var getDataUrl = configData.REACT_URL + "/umbraco/delivery/api/v1/content/item/header";
    axios.get(getDataUrl, {
        headers: {
        accept: 'application/json'
        }
    }).then((response) => {
      setNavbarData(response.data.properties);
    });
  }, []);
  

  useEffect(() => {
    setNavbarData(navBarData);
  }, [navBarData]);

  

  useEffect(() => {

        $('.d-overlay').on('click', function () {
            $('.offcanvas-collapse').toggleClass('open');
            $('body').toggleClass('opened-menu');
            $('html').toggleClass('menu-opened');
        })

        // $('#navbarSideCollapse').on('click', function (e) {
        //   $('.dpc-contact-submenu').removeClass('open');
        //     $('.offcanvas-collapse').toggleClass('open');
        //     $('body').toggleClass('opened-menu');
        //     $('body').removeClass('opened-submenu');
        //     $('html').toggleClass('menu-opened');
        //     $('#contactSideOpen').removeClass('active');
        // })

        $('.closeSubmenu').on('click', function (e) {
          $('.dpc-contact-submenu').removeClass('open');
            $('.offcanvas-collapse').removeClass('open');
            $('body').removeClass('opened-menu');
            $('html').removeClass('menu-opened');
            $('.dpc-conference').removeClass('open');
            $('#submenuSideOpen').removeClass('active');
            $('#contactSideOpen').removeClass('active');
        })

        $('#submenuSideOpen').on('click', function () {
          $('.dpc-contact-submenu').removeClass('open');
          $('.dpc-conference').toggleClass('open');
          $('#submenuSideOpen').toggleClass('active');

          if( window.screen.width >= 991.98) {

            if(openedTab === 0){
              openedTab = 1;
              // $('html').addClass('menu-opened');
              // $('body').addClass('opened-submenu');
            }
            else if(openedTab === 1) {
              openedTab = 0;
              // $('html').removeClass('menu-opened');
              $('body').removeClass('opened-submenu');
            }
            else {
              openedTab = 1;
            }
          }
          else {
            // $('body').toggleClass('opened-submenu');
          }
          
          $('#contactSideOpen').removeClass('active');
        })

          $('#submenuSideCollapse').on('click', function () {
          $('.dpc-conference').removeClass('open');
          $('body').removeClass('opened-submenu');
          $('#submenuSideOpen').removeClass('active');
        })

        $('#contactSideOpen').on('click', function () {
          $('.dpc-conference').removeClass('open');
          $('.dpc-contact-submenu').toggleClass('open');

          if( window.screen.width >= 991.98) {

            if(openedTab === 0){
              openedTab = 2;
              // $('html').addClass('menu-opened');
              // $('body').addClass('opened-submenu');
            }
            else if(openedTab === 2) {
              openedTab = 0;
              // $('html').removeClass('menu-opened');
              $('body').removeClass('opened-submenu');
            }
            else {
              openedTab = 2;
            }
            
          }
          else {
            $('body').toggleClass('opened-submenu');
          }

          $('#submenuSideOpen').removeClass('active');
          $('#contactSideOpen').toggleClass('active');

          $('#nav-contact-tab').attr('aria-selected', true);
          $('#nav-become-sponsor-tab').attr('aria-selected', false);
          $('#nav-conference-tab').attr('aria-selected', false);

          $('#nav-conference-tab').removeAttr('tabindex');
          $('#nav-contact-tab').attr('tabindex', -1);
          $('#nav-become-sponsor-tab').attr('tabindex', -1);
          
        })

        $('#contactSideCollapse').on('click', function () {
          $('.dpc-contact-submenu').toggleClass('open');
          // $('body').toggleClass('opened-submenu');
          $('#contactSideOpen').toggleClass('active');

        })

    }, []);

    let navigate = useNavigate(); 
    const navigateTo = (path) =>{ 
        navigate(path);
    }

    const navbarCollape = () =>{ 
      $('.dpc-contact-submenu').removeClass('open');
        $('.offcanvas-collapse').toggleClass('open');
        $('body').toggleClass('opened-menu');
        $('body').removeClass('opened-submenu');
        $('html').toggleClass('menu-opened');
        $('#contactSideOpen').removeClass('active');
  }
  
    const setClassName = (page) => {
  
      $('body').removeClass('opened-submenu');
      $('body').removeClass('opened-menu');
      $('html').removeClass('menu-opened');

      if(location.pathname.includes('o-nas') && page === "o-nas") {
        return "nav-link closeSubmenu active";
      }
      else if(location.pathname.includes('konference') && page === "konference") {
        return "nav-link closeSubmenu active";
      }
      else if(location.pathname.includes('kontakt') && page === "kontakt") {
        return "nav-link closeSubmenu active";
      }
      else if(location.pathname === '/' && page === "home") {
        return "nav-link closeSubmenu active";
      }
      else {
        return "nav-link closeSubmenu";
      }
    };

    return (
      <>
      {navBarData &&
        <>
        <nav id="header" className="navbar navbar-expand-lg fixed-top" aria-label="Main navigation">
            <div className="container-fluid d-flex align-items-center justify-content-between dpc-brd-b-1-primary-variant">

              <span className="navbar-brand closeSubmenu" onClick={() => {navigateTo('/');}}><img src={getImagePathDeliveryApi(navBarData.logoHeader.url)} className="dpc-logo-header" alt="DPC Logo" fetchpriority="high"/></span>

                { page !== "Stars" && 
                  page !== "Bio" && 
                  page !== "Home" &&
                  page !== "ConferencePage" &&
                  page !== "ContactPage" &&
                  getConferenceValue(page.campaign[0].name, page.year, "conferenceLogo") && 
                  <span className="navbar-conference-logo closeSubmenu">
                    <img src={getImagePath(getConferenceValue(page.campaign[0].name, page.year, "conferenceLogo"))} alt="Conference Logo"/>
                  </span>
                }
                {page === "Bio" && 
                  <button onClick={() => navigate(-1)} className="back-btn-mobile p-0 border-0" type="button" id="" aria-label="Back navigation" >
                
                    <svg className="dpc-svg-icon"><use xlinkHref="#arrow-right"/></svg>
                  </button>
                }

                <button className="navbar-toggler p-0 border-0" type="button" id="navbarSideCollapse" aria-label="Toggle navigation" onClick={() => {navbarCollape()}}>
                    <span className="icon-bar top-bar"></span>
                    {/* <!-- <span className="icon-bar middle-bar"></span> --> */}
                    <span className="icon-bar bottom-bar"></span>
                </button>

             {/* <!-- Side Menu OffCanvas --> */}
              <div className="navbar-collapse offcanvas-collapse" id="navbarOffCanvas">
                <ul className="navbar-nav d-top-navbar">
                {page === "Bio" && 
                  <li className="nav-item d-sidelinks">
                    <span onClick={() => navigate(-1)} className="nav-link back-link" id="backLink" ><svg className="dpc-svg-icon"><use xlinkHref="#arrow-right"/></svg> Nazaj</span>
                  </li>
                }
                  <li className="nav-item d-sidelinks">
                    <span className={setClassName("home")} style={{"cursor": "pointer"}} onClick={() => {navigateTo('/');}}><svg className="dpc-svg-icon"><use xlinkHref="#arrow-right"/></svg> {navBarData.homePageLink}</span>
                  </li>
                  <li className="nav-item d-sidelinks">
                    <span className={setClassName("konference")} style={{"cursor": "pointer"}} onClick={() => {navigateTo('/konference');}}><svg className="dpc-svg-icon"><use xlinkHref="#arrow-right"/></svg> {navBarData.conferenceHeaderLink}</span>
                  </li>
                  <li className="nav-item d-sidelinks">
                    <span className={setClassName("o-nas")} style={{"cursor": "pointer"}} onClick={() => {navigateTo('/o-nas');}}><svg className="dpc-svg-icon"><use xlinkHref="#arrow-right"/></svg> {navBarData.aboutUsHeaderLink}</span>
                  </li>
                  <li className="nav-item d-sidelinks">
                    <span className={setClassName("kontakt")} style={{"cursor": "pointer"}} onClick={() => {navigateTo('/kontakt/prijava');}}><svg className="dpc-svg-icon"><use xlinkHref="#arrow-right"/></svg> {navBarData.contactHeaderLink}</span>
                  </li>
                  {/* <li className="nav-item d-sidelinks">
                    <span className="nav-link" id="submenuSideOpen" ><svg className="dpc-svg-icon"><use xlinkHref="#arrow-right"/></svg> {getAliasValue("header", "conferenceHeaderLink")}</span>
                  </li>
                  <li className="nav-item d-sidelinks">
                    <span className="nav-link" id="contactSideOpen"><svg className="dpc-svg-icon"><use xlinkHref="#arrow-right"/></svg> {getAliasValue("header", "contactHeaderLink")}</span>
                  </li> */}
                </ul>
              </div>

              {/* <SubmenuConferenceComponent/> */}

              {/* <SubmenuContactComponent/> */}


            </div>
          </nav>
        </>
      }
        </>
    );
};

export default HeaderNavComponent;