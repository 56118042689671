
import './App.css';
import { Route, Routes, Navigate } from 'react-router-dom';
import FirstPageComponent from './components/FirstPageComponent';
import { useQuery, gql } from "@apollo/client";
import { useEffect, useState } from 'react';

import { getIncomingConference } from './dataManager/findAliasValue';
import { getList, getOurTeam } from './dataManager/findAliasValue';

import AboutUsComponent from './components/AboutUsComponent';
import CampaignPageComponent from './components/CampaignPageComponent';
import EntrepreneurStarsComponent from './components/EntrepreneurStarsComponent';
import BioComponent from './components/BioComponent';
import ConferenceListComponent from './components/ConferenceListComponent';
import ContactPageComponent from './components/ContactPageComponent';
import { getConferencesActive } from './helperFunctions/getActiveConferences';
import RedirectionComponent from './components/RedirectionComponent';

const FILMS_QUERY = gql`
{
  contentAtRoot {
      nodes {
          id,
          name,
          createDate,
          url
          contentType {
              alias
          }
          properties {
              alias
              editorAlias
              value {
                  ...on BasicPropertyValue {
                  value: value
              }
              ...on BasicRichText {
                value2: value
            }
              }
          }
          children {
              id,
                name,
                createDate,
                url
                contentType {
                  alias
                }
                properties {
                    alias
                    editorAlias
                    value {
                        ...on BasicPropertyValue {
                        value: value
                    }
                    ...on BasicRichText {
                      value2: value
                  }
                    }
                }
                children {
                id,
                name,
                createDate,
                url
                contentType {
                  alias
                }
                properties {
                    alias
                    editorAlias
                    value {
                        ...on BasicPropertyValue {
                        value: value
                    }
                    ...on BasicRichText {
                      value2: value
                  }
                    }
                }
                children {
                    id,
                    name,
                    createDate,
                    url
                    contentType {
                      alias
                    }
                    properties {
                        alias
                        editorAlias
                        value {
                            ...on BasicPropertyValue {
                            value: value
                        }
                        ...on BasicRichText {
                          value2: value
                      }
                        }
                    }
                    children {
                      id,
                      name,
                      createDate,
                      url
                      contentType {
                        alias
                      }
                      properties {
                          alias
                          editorAlias
                          value {
                              ...on BasicPropertyValue {
                              value: value
                          }
                          ...on BasicRichText {
                            value2: value
                        }
                          }
                      }
                      children {
                        id,
                        name,
                        createDate,
                        url
                        contentType {
                          alias
                        }
                        properties {
                            alias
                            editorAlias
                            value {
                                ...on BasicPropertyValue {
                                value: value
                            }
                            ...on BasicRichText {
                              value2: value
                          }
                            }
                        }
                        children {
                          id,
                          name,
                          createDate,
                          url
                          contentType {
                            alias
                          }
                          properties {
                              alias
                              editorAlias
                              value {
                                  ...on BasicPropertyValue {
                                  value: value
                              }
                              ...on BasicRichText {
                                value2: value
                            }
                              }
                          }
                          
                      }
                        
                    }
                  }
                }
                }
          }
      }
}
}
`;

function App() {

  const [pagesData, setPagesData] = useState();
  const [conferenceList, setConferenceList] = useState();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [reloadData, setReloadData] = useState();

  const { data, loading, error } = useQuery(FILMS_QUERY);

  useEffect(() => {
    if(data) {
      setPagesData(data.contentAtRoot);
      localStorage.setItem("pagesData", JSON.stringify(data.contentAtRoot));
      localStorage.setItem("linkGallery", JSON.stringify(false));

      var conferenceListData = data.contentAtRoot.nodes.filter(node => node.name === "Conference List")[0];
      var conferences = [];
      conferenceListData.children.forEach(conf => {
        conferences.push(conf);
      });
      setConferenceList(conferences);
      localStorage.setItem("conferences", JSON.stringify(conferences));

      // Set active conference
      localStorage.setItem("activeConference", JSON.stringify(conferences[0]));

      var doneActualConferences = getConferencesActive(conferences);

      // Get testimonials data
      getList("testimonialsList");
      
      //Get incoming conference
      getIncomingConference();

      // Get about us values data
      getList("aboutUsPage");

      //Get talkers
      getList("talkersList");

      //Get commission
      getList("commissionList");

      //Get our team
      getOurTeam();

      // Get entrepreneur stars
      var entrepreneurStars = getList("entrepreneurStars");

      // Get constellation
      var constellationList = getList("constellation");
      // Set active constellation
      if(constellationList.length > 0)
        localStorage.setItem("activeConstellation", JSON.stringify(constellationList[0].children[0]));
      else 
        localStorage.setItem("activeConstellation", JSON.stringify([]));

      // Set active page - entrepreneur stars
      localStorage.setItem("activePageEntrepreneurStars", JSON.stringify({entrepreneurStars: entrepreneurStars[0].children[0], year: new Date().getFullYear()}));

      // Get entrepreneur stars
      var entrepreneurStars = getList("entrepreneurStars");

      // Get constellation
      var constellationList = getList("constellation");
      // Set active constellation
      localStorage.setItem("activeConstellation", JSON.stringify(constellationList[0].children[0]));

      setDataLoaded(true);
    }
  }, []);

  useEffect(() => {
    if(data) {
      setPagesData(data.contentAtRoot);
      localStorage.setItem("pagesData", JSON.stringify(data.contentAtRoot));
      localStorage.setItem("linkGallery", JSON.stringify(false));

      var conferenceListData = data.contentAtRoot.nodes.filter(node => node.name === "Conference List")[0];
      var conferences = [];
      conferenceListData.children.forEach(conf => {
        conferences.push(conf);
      });
      setConferenceList(conferences);
      localStorage.setItem("conferences", JSON.stringify(conferences));

      // Set active conference
      localStorage.setItem("activeConference", JSON.stringify(conferences[0]));

      var doneActualConferences = getConferencesActive(conferences);

      // Get testimonials data
      getList("testimonialsList");
      
      //Get incoming conference
      getIncomingConference();

      // Get about us values data
      getList("aboutUsPage");

      //Get talkers
      getList("talkersList");

      //Get commission
      getList("commissionList");

      //Get our team
      getOurTeam();

      // Get entrepreneur stars
      var entrepreneurStars = getList("entrepreneurStars");

      // Get constellation
      var constellationList = getList("constellation");
      // Set active constellation
      if(constellationList.length > 0)
        localStorage.setItem("activeConstellation", JSON.stringify(constellationList[0].children[0]));
      else 
        localStorage.setItem("activeConstellation", JSON.stringify([]));

      // Set active page - entrepreneur stars
      localStorage.setItem("activePageEntrepreneurStars", JSON.stringify({entrepreneurStars: entrepreneurStars[0].children[0], year: new Date().getFullYear()}));

      // Get entrepreneur stars
      var entrepreneurStars = getList("entrepreneurStars");

      // Get constellation
      var constellationList = getList("constellation");
      // Set active constellation
      localStorage.setItem("activeConstellation", JSON.stringify(constellationList[0].children[0]));

      setDataLoaded(true);
    }
  }, [data]);

  useEffect(() => {
    setPagesData(pagesData);
  }, [pagesData]);

  useEffect(() => {
    setPagesData(conferenceList);
  }, [conferenceList]);

  // if (loading) return <div id="preloader"></div>;
  // if (error) return <pre>{error.message}</pre>

  // Toggle body .dpc-scrolled class to body when page is scrolled
  var scrollPosition = window.scrollY;
  var logoContainer = document.getElementsByTagName("body")[0];
  window.addEventListener('scroll', function () {

      scrollPosition = window.scrollY;

      if (scrollPosition >= 30) {
          logoContainer.classList.add('dpc-scrolled');
      } else {
          logoContainer.classList.remove('dpc-scrolled');
      }

  });

  return (

    <>
      <Routes>
        <Route
          path="/"
          element={< FirstPageComponent shouldLoad={dataLoaded}
                  />}
        />
        <Route
          path="/o-nas"
          element={< AboutUsComponent shouldLoad={dataLoaded} />}
        />
        <Route
          path="/konference"
          element={< ConferenceListComponent shouldLoad={dataLoaded} />}
        />
        <Route
          path="/kontakt/:activeTab"
          element={< ContactPageComponent shouldLoad={dataLoaded} />}
        />
        <Route
          path="/poslovna-kampanja/:campaign/:year"
          element={< CampaignPageComponent shouldLoad={dataLoaded} />}
        />
        <Route
          path="/podjetniske-zvezde/:year"
          element={< EntrepreneurStarsComponent shouldLoad={dataLoaded}/>}
        />
        <Route
          path="/zivljenjepis/:type/:name"
          element={< BioComponent shouldLoad={dataLoaded}/>}
        />
        <Route path="/robots.txt" element={< RedirectionComponent/>} />
        <Route path="*" element={ <Navigate to="/" /> } />
        
      </Routes>
    </>
  );
}

export default App;
