
// import Swiper core and required modules
import { A11y, Navigation, Pagination, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useState } from "react";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { getVideoListProperty, getVideos, getVideosStars, getVideoValue } from '../helperFunctions/getVideos';

const $ = window.$;

const VideoGalleryComponent = ({page}) => {

    const [activeGalleryImage, setActiveGalleryImage] = useState(0);

        //Campaign
        if(page !== 'Stars') {
            var activeCamp = JSON.parse(localStorage.getItem("activePageCampaignAndYear")).campaign[0];
            var activeY = JSON.parse(localStorage.getItem("activePageCampaignAndYear")).year;
            getVideos(activeCamp, activeY);
        }
        //Stars
        else {
            var starsActivePage = JSON.parse(localStorage.getItem("activePageEntrepreneurStars")).entrepreneurStars;
            getVideosStars(starsActivePage);
        }

        var videos = JSON.parse(localStorage.getItem("campaignVideos"));
        var videosList = null;
        if(videos && videos.length > 0) {
            videosList = videos[0].children;
        }
    
    const convertToEmbeddedUrl = (youtubeUrl) => {
        var result =  youtubeUrl.replace("watch?v=", "embed/").split("&")[0];
        result = result + "?enablejsapi=1&rel=0"

        return result;
    };

    return (
        <>
            { videos && videosList && (
                <>
                
                {/* <!-- Section Gallery --> */}
                    <section id="gallery" className="dpc-section dpc-section-gallery">
                    <div className="container-fluid">
                        <h2 className="dpc-brd-b-gray">{getVideoListProperty(videos, "videoGalleryTitle")}</h2>
                        <div className="row dpc-col-lg-auto-24-fl-p0 mb-2 mb-sm-3 mb-lg-4">
                            <div className="col col-sm-8 dpc-col-lg-24 dpc-brd-lg-r dpc-text-22-sm-19">
                            <p>{getVideoListProperty(videos, "videoGalleryDescription")}</p>
                            </div>
                            <div className="col dpc-col-lg-76 dpc-brd-down-lg-t-1-primary-variant pt-4 pt-lg-0 mt-lg-n011pc">
                            <Swiper
                                modules={[Navigation, Pagination, Scrollbar, A11y]}
                                slidesPerView={1}
                                spaceBetween={30}
                                className="dpc-nominees-slider dpc-min-h-200px dpc-yt-video-slider"
                                centeredSlides = {true}
                                roundLengths = {true}
                                loop = {true}
                                loopAdditionalSlides = {10}
                                navigation={true}
                                onSlideChange={(swiper) => {setActiveGalleryImage(swiper.realIndex + 1);
                                    $('.swiper-slide').each(function () {
                                        var youtubePlayer = $(this).find('iframe').get(0);
                                        if (youtubePlayer) {
                                          youtubePlayer.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
                                        }
                                    });
                                 }}
                                breakpoints={{
                                    1: {
                                    slidesPerView: 1.21,
                                    spaceBetween: -14,
                                    },
                                    576: {
                                    slidesPerView: 1
                                    },
                                    768: {
                                        //slidesPerView: 1.1,
                                        //spaceBetween: 40,
                                    },
                                    992: {
                                        //slidesPerView: 1,
                                        //spaceBetween: 40,
                                    },
                                }}
                                >
                                {videosList && videosList.map((video) => (
                                    <SwiperSlide key={video.id}>
                                            
                                        <div className="dpc-nominees-content dpc-yt-video-container">
                                        <iframe class="dpc-yt-video" width="560" height="315" src={convertToEmbeddedUrl(getVideoValue(video, "videoUrl"))} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="true"></iframe>

                                            {/* <img src={mainSponsorLogo} className="img-fluid d-none" alt="..."/> */}
                                        </div>  

                                    </SwiperSlide>
                                ))} 
                            </Swiper>

                            <div className="dpc-nominees-pager dpc-yt-video-pager mt-2">
                                <span className="dpc-active">{activeGalleryImage}</span> - {videosList.length}             
                            </div>
                            
                            </div>
                        </div>
                    </div>
                    </section>
                </> 
            )}
            
        </>

    );
};

export default VideoGalleryComponent;