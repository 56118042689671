import { useNavigate } from 'react-router-dom';
import { getAliasValue, getValueFromArrayElement } from '../dataManager/findAliasValue';

const OurWorkComponent = () => {

    var numberOfConferencesInOneRow = 5;

    var conferenceList = JSON.parse(localStorage.getItem("conferences"));
    var activePageEntrepreneurStars = JSON.parse(localStorage.getItem("activePageEntrepreneurStars"));
    // var activePageCampaignAndYear = JSON.parse(localStorage.getItem("activePageCampaignAndYear"));
    // localStorage.setItem("activePageCampaignAndYear", JSON.stringify({campaign: conferences[0], year: "2023"}));

    let navigate = useNavigate(); 
    const navigateTo = (path) =>{ 
      navigate(path);
    }

    const changeActiveConference = (conf) =>{ 
      localStorage.setItem("activePageCampaignAndYear", JSON.stringify({campaign: conf, year: new Date().getFullYear().toString()}));
    };

    return(
        <>
          {/* <!-- Section Our Work --> */}
          <section id="ourWork" className="dpc-section-alt dpc-section-our-work" fetchpriority="low">
              <div className="container-fluid">
                <h2 className="dpc-brd-b-gray">{getAliasValue("homePage", "titleOurWork")}</h2>
                  <div className="row dpc-col-lg-auto-30-fl-p0">
                    <div className="col dpc-col-lg-30 dpc-brd-lg-r pe-md-45 d-flex align-items-center pb-4 pt-0 py-lg-4">
                      <p className="dpc-text-22-sm-17" loading="lazy" fetchpriority="low">{getAliasValue("homePage", "infoOurWork")}</p>
                    </div>
                    <div className="col" fetchpriority="low">
                      <div className="row">
                        <div className="col-sm-6 mb-n-1px">
                        { ((conferenceList.length + 1) > (numberOfConferencesInOneRow)) ? 
                          (<ul className="nav flex-column dpc-v-links dpc-hov-bg me-md-auto mx-lg-auto my-lg-2">
                            {conferenceList && conferenceList.slice(0, (conferenceList.length/2 + 1)).map((conf) => (
                                <li className="nav-item">
                                  <span className="nav-link" style={{"cursor": "pointer"}} onClick={() => {navigateTo(conf.url.toString() + new Date().getFullYear().toString()); changeActiveConference(conf)}}>{conf.name} <svg className="dpc-svg-icon"><use xlinkHref="#arrow-right"/></svg></span>
                                </li>
                            ))}
                          </ul>)
                          :
                          (<ul className="nav flex-column dpc-v-links dpc-hov-bg me-md-auto mx-lg-auto my-lg-2">
                            {conferenceList && conferenceList.map((conf) => (
                                <li className="nav-item">
                                  <span className="nav-link" style={{"cursor": "pointer"}} onClick={() => {navigateTo(conf.url.toString() + new Date().getFullYear().toString()); changeActiveConference(conf)}}>{conf.name} <svg className="dpc-svg-icon"><use xlinkHref="#arrow-right"/></svg></span>
                                </li>
                            ))}
                            <li className="nav-item">
                              <span className="nav-link" style={{"cursor": "pointer"}} onClick={() => {navigateTo('/podjetniske-zvezde/' + activePageEntrepreneurStars.year);}}>{getValueFromArrayElement(activePageEntrepreneurStars.entrepreneurStars, "title")}<svg className="dpc-svg-icon"><use xlinkHref="#arrow-right"/></svg></span>
                            </li>
                          </ul>)
                        }
                        </div>
                        {((conferenceList.length + 1) > (numberOfConferencesInOneRow)) &&
                        (<div className="col-sm-6 ps-lg-42">
                          <ul className="nav flex-column dpc-v-links dpc-hov-bg me-md-auto mx-lg-auto my-lg-2">
                            {conferenceList && conferenceList.slice((conferenceList.length/2 + 1), conferenceList.length).map((conf) => (
                                <li className="nav-item">
                                  <span className="nav-link" style={{"cursor": "pointer"}} onClick={() => {navigateTo(conf.url.toString() + new Date().getFullYear().toString()); changeActiveConference(conf)}}>{conf.name} <svg className="dpc-svg-icon"><use xlinkHref="#arrow-right"/></svg></span>
                                </li>
                            ))}
                            <li className="nav-item">
                              <span className="nav-link" style={{"cursor": "pointer"}} onClick={() => {navigateTo('/podjetniske-zvezde/' + new Date().getFullYear().toString());}}>{getValueFromArrayElement(activePageEntrepreneurStars.entrepreneurStars, "title")}<svg className="dpc-svg-icon"><use xlinkHref="#arrow-right"/></svg></span>
                            </li>
                          </ul>
                        </div>
                        )}
                      </div>
                    </div>
                  </div>
              </div>
          </section>
        </>
    );
}

export default OurWorkComponent;